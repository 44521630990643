import React, { useState, useEffect } from 'react';
import { doc, updateDoc, addDoc, collection, getDoc, getDocs, query, where, Timestamp } from 'firebase/firestore';
import { db } from '../../../firebase';

const DepositModal = ({ masterId, currentBalance, onClose }) => {
  const [amount, setAmount] = useState('');
  const [description, setDescription] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [godPassword, setGodPassword] = useState(null);
  const [superName, setSuperName] = useState('');  // Store the super's name
  const [creditAmt, setCreditAmt] = useState(0);  // Store the super's credit amount
  const [error, setError] = useState(null);

  // Fetch god password from Firestore
  useEffect(() => {
    const fetchGodPassword = async () => {
      const q = query(collection(db, 'god'), where('username', '==', 'god'));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const godData = querySnapshot.docs[0].data();
        setGodPassword(godData.password);
      } else {
        setError('God user not found.');
      }
    };

    fetchGodPassword();
  }, []);

  // Fetch the super name and creditAmt from Firestore
  useEffect(() => {
    const fetchSuperData = async () => {
      try {
        const superDocRef = doc(db, 'super', masterId);  // Get the super document by ID
        const superDocSnap = await getDoc(superDocRef);  // Fetch the document
        if (superDocSnap.exists()) {
          const superData = superDocSnap.data();
          setSuperName(superData.username);  // Assuming 'username' is the super name field
          setCreditAmt(parseFloat(superData.creditAmt) || 0);  // Assuming 'creditAmt' field is available
        } else {
          setError('Super user not found.');
        }
      } catch (error) {
        console.error('Error fetching super data:', error);
        setError('Failed to fetch super data.');
      }
    };

    fetchSuperData();
  }, [masterId]);

  // Handle form submission
  const handleDeposit = async () => {
    if (enteredPassword !== godPassword) {
      setError('Incorrect god password.');
      return;
    }

    try {
      const depositAmount = parseFloat(amount);
      const newBalance = currentBalance + depositAmount;
      const netBalance = newBalance + creditAmt;  // Calculate net balance

      // Update the super's balance
      const superRef = doc(db, 'super', masterId);
      await updateDoc(superRef, { balance: newBalance });

      // Create a new deposit record in Firestore under /accountStmt/god/deposit
      await addDoc(collection(db, 'accountStmt', 'god', 'deposit'), {
        amount: depositAmount,
        balance: newBalance,
        netBal: netBalance,  // Store the net balance
        description: `god: ${description}`,
        superName: superName,  // Use the super name fetched from Firestore
        createdTime: Timestamp.now(),
        creatorName: 'God',  // or another creator name from context
        transactionId:Date.now().toString()
      });

      alert('Deposit successful.');
      onClose();
    } catch (error) {
      console.error('Error processing deposit:', error);
      alert('Failed to process deposit. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Deposit Amount</h2>

        {/* Input field for amount */}
        <input
          type="number"
          placeholder="Enter Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Input field for description */}
        <input
          type="text"
          placeholder="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Input field for god password */}
        <input
          type="password"
          placeholder="God Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button onClick={handleDeposit} className="bg-green-500 text-white px-4 py-2 rounded-lg">
            Submit
          </button>
          <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded-lg">
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositModal;
