import React from 'react'; // New small-screen top bar
import SmallScreenTopBar from './SmallScreenTopbar';
import TopBar from './TopBar';

const CombinedTopBar = ({ role, username }) => {
  return (
    <>
      <div className="lg:hidden">
        {/* Renders only on small and medium screens */}
        <SmallScreenTopBar role={role} username={username} />
      </div>
      <div className="hidden lg:flex">
        {/* Renders only on large screens */}
        <TopBar role={role} username={username} />
      </div>
    </>
  );
};

export default CombinedTopBar;
