import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import icon from "../images/icon.png";
import DropdownSelector from "./DropdownSelector";
import { IoPersonSharp } from "react-icons/io5";
import { db } from "../firebase";
import {
  collection,
  doc,
  getDocs,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const TopBar = ({ role, username }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown4, setShowDropdown4] = useState(false);
  const [showDropdown5, setShowDropdown5] = useState(false);
  const [logo, setLogo] = useState("");
  const [logoload, setLogoLoad] = useState(true);

  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [balance, setBalance] = useState(0);
  const [superBal, setSuperBal] = useState(0);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  useEffect(() => {
    setSuperBal("");
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        let unsubscribe;

        if (role === "master") {
          // Query to fetch master document where username equals the provided username
          const mastersCollection = collection(db, "masters");
          const masterQuery = query(
            mastersCollection,
            where("username", "==", username)
          );

          // Real-time updates for master
          unsubscribe = onSnapshot(masterQuery, (querySnapshot) => {
            if (!querySnapshot.empty) {
              const masterData = querySnapshot.docs[0].data(); // Assuming only one document matches
              setBalance(masterData.balance);
            } else {
              console.log("Master not found");
            }
          });
        } else if (role === "super") {
          // Query to fetch super document where username equals the provided username
          const superCollection = collection(db, "super");
          const superQuery = query(
            superCollection,
            where("username", "==", username)
          );

          // Real-time updates for super
          unsubscribe = onSnapshot(superQuery, (querySnapshot) => {
            if (!querySnapshot.empty) {
              const superData = querySnapshot.docs[0].data(); // Assuming only one document matches
              setSuperBal(superData.balance);
            } else {
              console.log("Super not found");
            }
          });
        }

        return () => unsubscribe && unsubscribe(); // Clean up listener on unmount
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    fetchBalance();
  }, [role, username]);

  const handleNavigate = (path) => {
    // console.log(role)
    navigate(path, { state: { role, username } }); // Always pass the role
  };

  const handleNavigateAddWhole = (path) => {
    if (role && username) {
      // console.log({ role, username })
      navigate(path, { state: { role: "god", username } }); // Pass serializable data only
    } else {
      console.error("Role or Username is missing");
    }
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const handleMouseEnter1 = () => {
    setShowDropdown1(true);
  };

  const handleMouseLeave1 = () => {
    setShowDropdown1(false);
  };

  const handleMouseEnter2 = () => {
    setShowDropdown2(true);
  };

  const handleMouseLeave2 = () => {
    setShowDropdown2(false);
  };

  const handleMouseEnter3 = () => {
    setShowDropdown3(true);
  };

  const handleMouseLeave3 = () => {
    setShowDropdown3(false);
  };

  const handleMouseEnter4 = () => {
    setShowDropdown4(true);
  };

  const handleMouseLeave4 = () => {
    setShowDropdown4(false);
  };

  const handleMouseEnter5 = () => {
    setShowDropdown5(true);
  };

  const handleMouseLeave5 = () => {
    setShowDropdown5(false);
  };

  const handleProfileClick = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const handleChangePasswordClick = () => {
    setShowChangePasswordModal(true);
    setShowProfileDropdown(false);
  };

  const handleCloseChangePasswordModal = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");

    setShowChangePasswordModal(false);
  };

  const handleUsers = () => {
    handleNavigate("/add");
  };

  const handleLogout = () => {
    navigate("/");
  };

  const fetchlogo = async () => {
    try {
      setLogoLoad(true);
      const logoRef = doc(db, "utils", "logos"); // Ensure your path is correct
      const logoSnap = await getDoc(logoRef);

      if (logoSnap.exists()) {
        setLogo(logoSnap.data().desktop); // Use data() as a function
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLogoLoad(false);
    }
  };

  useEffect(() => {
    fetchlogo();
  }, []);

  const handleSubmitChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Regex for special characters
    if (
      !newPassword ||
      newPassword.length < 6 ||
      !specialCharRegex.test(newPassword)
    ) {
      alert(
        "Password must be at least 6 characters long and contain at least one special character."
      );
      return;
    }

    try {
      // For master role (existing code)
      if (role === "master") {
        const masterCollection = collection(db, "masters");
        const querySnapshot = await getDocs(masterCollection);
        const masterDoc = querySnapshot.docs.find(
          (doc) =>
            doc.data().username === username &&
            doc.data().password === oldPassword
        );

        if (masterDoc) {
          const masterDocRef = doc(db, "masters", masterDoc.id);
          await updateDoc(masterDocRef, { password: newPassword });

          alert("Password updated successfully.");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setShowChangePasswordModal(false);
        } else {
          alert("Incorrect old password.");
        }
      }

      // For super role (new logic)
      if (role === "super") {
        const superCollection = collection(db, "super");
        const querySnapshot = await getDocs(superCollection);
        const superDoc = querySnapshot.docs.find(
          (doc) =>
            doc.data().username === username &&
            doc.data().password === oldPassword
        );

        if (superDoc) {
          const superDocRef = doc(db, "super", superDoc.id);
          await updateDoc(superDocRef, { password: newPassword });

          alert("Password updated successfully.");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setShowChangePasswordModal(false);
        } else {
          alert("Incorrect old password.");
        }
      }

      if (role === "god") {
        const godCollection = collection(db, "god");
        const querySnapshot = await getDocs(godCollection);
        const godDoc = querySnapshot.docs.find(
          (doc) =>
            doc.data().username === username &&
            doc.data().password === oldPassword
        );

        if (godDoc) {
          const godDocRef = doc(db, "god", godDoc.id);
          await updateDoc(godDocRef, { password: newPassword });

          alert("Password updated successfully.");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setShowChangePasswordModal(false);
        } else {
          alert("Incorrect old password.");
        }
      }
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Failed to update password.");
    }
  };

  return (
    <div className="bg-[#242731] w-screen flex flex-col md:flex-row md:h-16 justify-between p-2">
      <div className="flex items-center space-x-2 md:space-x-5">
        {logoload ? (
          <div className="w-16 h-16 md:w-20 md:h-8 object-contain"></div> // Show spinner while loading
        ) : (
          <img
            src={logo}
            alt="icon"
            className="w-16 h-16 md:w-20 md:h-8 object-contain"
          />
        )}
        {/* Conditionally render Assign Roles and List Of Master */}
        <div className="flex space-x-2 md:space-x-5 flex-wrap">
          {role === "god" && (
            <div className="space-x-3">
              <button
                className="text-sm font-semibold text-white rounded px-2 h-5"
                onClick={() => handleNavigate("/godHome")}
              >
                Creation
              </button>
              <button
                className="text-sm font-semibold text-white  rounded px-2 h-5"
                onClick={() => handleNavigate("/god/listSuper")}
              >
                List of all Super
              </button>
              <button
                className="text-sm font-semibold text-white  rounded px-2 h-5"
                onClick={() => handleNavigate("/godMasterList")}
              >
                List of all Masters
              </button>
              <button
                className="text-sm font-semibold text-white rounded px-2 h-5"
                onClick={() => handleNavigate("/godUserList")}
              >
                List Of all Users
              </button>
            </div>
          )}

          {role === "god" && (
            <div
              className="relative text-sm font-semibold text-white cursor-pointer px-2 rounded"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Client Request &#9660;
              {showDropdown && (
                <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10">
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() =>
                      handleNavigate("/god/DE", {
                        state: { heading: "Deposit" },
                      })
                    }
                  >
                    Deposit
                  </div>
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() =>
                      handleNavigate("/god/WE", {
                        state: { heading: "Withdraw" },
                      })
                    }
                  >
                    Withdraw
                  </div>
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() =>
                      handleNavigate("/god/totalDW", {
                        state: { heading: "TotalDW" },
                      })
                    }
                  >
                    TotalDW
                  </div>
                  {/* {role!=='master' && <div>
                    <div className="px-4 py-2 text-gray-300 hover:bg-gray-600" onClick={() => handleNavigate("/clientReq", { state: { heading: "Super Master DW" } })}>Super Master DW</div>
                    <div className="px-4 py-2 text-gray-300 hover:bg-gray-600" onClick={() => handleNavigate("/clientReq", { state: { heading: "Super DW States" } })}>Super DW States</div>  
                  </div>} */}
                </div>
              )}
            </div>
          )}

          {role === "god" && (
            <button
              className="text-sm font-semibold text-white  rounded px-2 h-5"
              onClick={() => handleNavigate("/god/Stmt")}
            >
              Account's Statement
            </button>
          )}

          {role == "god" && (
            <button
              className="text-sm font-semibold  text-white rounded px-2 h-5"
              onClick={() => handleNavigate("/addWhole")}
            >
              Add Utils
            </button>
          )}

          {role === "super" && (
            <>
              <button
                className="text-sm font-semibold text-white  rounded px-2 h-5"
                onClick={() => handleNavigate("/listUsers")}
              >
                List of Users
              </button>
              {/* <button className='text-sm font-semibold text-gray-300 ' onClick={() => handleNavigate('/home')}>List Of Master</button> */}
              <button
                className="text-sm font-semibold text-white  rounded px-2 h-5"
                onClick={() => handleNavigate("/masterLists")}
              >
                List Of Master
              </button>
            </>
          )}

          {role === "super" && (
            <div
              className="relative text-sm font-semibold text-white cursor-pointer  px-2 rounded h-5"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Client Request &#9660;
              {showDropdown && (
                <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10">
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() =>
                      handleNavigate("/clientDE", {
                        state: { heading: "Deposit" },
                      })
                    }
                  >
                    Deposit
                  </div>
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() =>
                      handleNavigate("/clientWE", {
                        state: { heading: "Withdraw" },
                      })
                    }
                  >
                    Withdraw
                  </div>
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() =>
                      handleNavigate("/totalDW", {
                        state: { heading: "TotalDW" },
                      })
                    }
                  >
                    TotalDW
                  </div>
                  {/* {role!=='master' && <div>
                    <div className="px-4 py-2 text-gray-300 hover:bg-gray-600" onClick={() => handleNavigate("/clientReq", { state: { heading: "Super Master DW" } })}>Super Master DW</div>
                    <div className="px-4 py-2 text-gray-300 hover:bg-gray-600" onClick={() => handleNavigate("/clientReq", { state: { heading: "Super DW States" } })}>Super DW States</div>  
                  </div>} */}
                </div>
              )}
            </div>
          )}

          {role === "super" && (
            <>
              <button
                className="text-sm font-semibold text-white  rounded px-2 h-5"
                onClick={() => handleNavigate("/super/accStmt")}
              >
                Account's Statement
              </button>
            </>
          )}

          {role === "master" && (
            <div className="flex space-x-2 md:space-x-5 mb-1">
              {/* <button
              className="text-sm font-semibold text-white  rounded px-2 h-5"
              onClick={handleUsers}
            >
              Add Users
            </button> */}

              <div
                className="relative text-sm font-semibold text-white cursor-pointer  px-2 rounded h-5"
                onMouseEnter={handleMouseEnter3}
                onMouseLeave={handleMouseLeave3}
              >
                Client Info &#9660;
                {showDropdown3 && (
                  <div className="absolute left-0 w-48 bg-gray-700 shadow-lg z-10">
                    <div
                      className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                      onClick={() => handleNavigate("/master/listClient")}
                    >
                      List Of Clients
                    </div>
                    <div
                      className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                      onClick={() => handleNavigate("/curr")}
                    >
                      Client Position
                    </div>
                    <div
                      className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                      onClick={() => handleNavigate("/curr")}
                    >
                      KYC Details
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Conditionally render Client Request */}

          {role === "master" && (
            <div
              className="relative text-sm font-semibold text-white cursor-pointer  px-2 rounded h-5"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              Client Request &#9660;
              {showDropdown && (
                <div className="absolute left-0 w-48 bg-gray-700 shadow-lg z-10">
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() =>
                      handleNavigate("/DM", { state: { heading: "Deposit" } })
                    }
                  >
                    Deposit
                  </div>
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() =>
                      handleNavigate("/WM", { state: { heading: "Withdraw" } })
                    }
                  >
                    Withdraw
                  </div>
                </div>
              )}
            </div>
          )}

          {role === "master" && (
            <button
              className="text-sm font-semibold text-white  rounded px-2 h-5"
              onClick={() => handleNavigate("/master/accStmt")}
            >
              Account's Statement
            </button>
          )}

          {role == "master" && (
            <button
              className="text-sm font-semibold  text-white  rounded px-2 h-5 mb-2 md:mb-0"
              onClick={() => handleNavigate("/paymentGate")}
            >
              Payment Gateway
            </button>
          )}

          {/* ProfitLoss Group */}
          <div
            className="relative text-sm font-semibold cursor-pointer text-white  rounded px-2 h-5"
            onMouseEnter={handleMouseEnter1}
            onMouseLeave={handleMouseLeave1}
          >
            Profit Loss Group &#9660;
            {showDropdown1 && (
              <div className="absolute left-0 w-48 bg-gray-700 shadow-lg z-10 ">
                <div
                  className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                  onClick={() => handleNavigate("/pl")}
                >
                  P/L
                </div>
                <div
                  className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                  onClick={() => handleNavigate("/plc")}
                >
                  P/L(Client)
                </div>
              </div>
            )}
          </div>

          

          {role === "master" && (
            <div
              className="relative text-sm font-semibold cursor-pointer text-white  rounded px-2 h-5"
              onMouseEnter={handleMouseEnter4}
              onMouseLeave={handleMouseLeave4}
            >
              Activity &#9660;
              {showDropdown4 && (
                <div className="absolute left-0 w-48 bg-gray-700 shadow-lg z-10 ">
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() => handleNavigate("/curr")}
                  >
                    Clients Activity
                  </div>
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() => handleNavigate("/curr")}
                  >
                    Clients Activity Monitor
                  </div>
                </div>
              )}
            </div>
          )}

          {/* Analysis bets.. some part of god */}
          <div
            className="relative text-sm font-semibold text-white  rounded px-2 h-5 cursor-pointer"
            onMouseEnter={handleMouseEnter2}
            onMouseLeave={handleMouseLeave2}
          >
            Analysis/Bets &#9660;
            {showDropdown2 && (
              <div className="absolute left-0 w-48 bg-gray-700 shadow-lg z-10">
                {role == "god" && (
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() => handleNavigate("/god/setbets")}
                  >
                    Set Bets
                  </div>
                )}
                <div
                  className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                  onClick={() => handleNavigate("/ana")}
                >
                  Analysis of Running Market
                </div>
                <div
                  className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                  onClick={() => handleNavigate("/curr")}
                >
                  Current Bets
                </div>
                <div
                  className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                  onClick={() => handleNavigate("/allC")}
                >
                  All Current Bets
                </div>
              </div>
            )}
          </div>

          {role === "master" && (
            <div
              className="relative text-sm font-semibold cursor-pointer text-white  rounded px-2 h-5"
              onMouseEnter={handleMouseEnter5}
              onMouseLeave={handleMouseLeave5}
            >
              More &#9660;
              {showDropdown5 && (
                <div className="absolute left-0 w-48 bg-gray-700 shadow-lg z-10 ">
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() => handleNavigate("/modeOfPay")}
                  >
                    Mode of Payment
                  </div>
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() => handleNavigate("/plc")}
                  >
                    Payment Gateway Position
                  </div>
                  <div
                    className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                    onClick={() => handleNavigate("/plc")}
                  >
                    Service Number
                  </div>
                </div>
              )}
            </div>
          )}


        </div>
      </div>

      <div className="flex items-center mt-3 md:mt-0 space-x-2 relative">
        <span>
          <DropdownSelector />
        </span>
        <p
          className="text-sm text-gray-400 font-bold cursor-pointer"
          onClick={handleProfileClick}
        >
          {username}
        </p>
        <IoPersonSharp
          className="text-gray-400 text-xl cursor-pointer"
          onClick={handleProfileClick}
        />
        {!(role == "god") && (
          <div className="flex">
            <p
              className="text-white text-sm cursor-pointer"
              onClick={handleProfileClick}
            >
              {" "}
              Bal: {role === "super" ? superBal : balance}
            </p>
          </div>
        )}

        {showProfileDropdown && (
          <div className="absolute right-0 mt-40 w-48 bg-gray-700 shadow-lg z-10">
            <div
              className="px-4 py-2 text-gray-300 hover:bg-gray-600 cursor-pointer"
              onClick={handleChangePasswordClick}
            >
              Change Password
            </div>
            {
              <div
                className="px-4 py-2 text-gray-300 hover:bg-gray-600 cursor-pointer"
                onClick={handleLogout}
              >
                Logout
              </div>
            }
          </div>
        )}
      </div>

      {/* Change Password Modal */}
      {showChangePasswordModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4 text-center">
              Change Password
            </h2>
            <div className="mb-4">
              <label className="block mb-2">Enter Old Password</label>
              <input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Enter New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Confirm Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>

            <div className="flex justify-end space-x-2">
              <button
                className="bg-green-600 text-white px-4 py-2 rounded-md"
                onClick={handleSubmitChangePassword}
              >
                Submit
              </button>

              <button
                onClick={handleCloseChangePasswordModal}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TopBar;
