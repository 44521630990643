import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const RefillBonusModal = ({ masterId, superPassword, onClose }) => {
  const [refillBonus, setRefillBonus] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleRefillBonusUpdate = async () => {
    if (enteredPassword !== superPassword) {
      alert("Incorrect super password.");
      return;
    }

    try {
      const masterRef = doc(db, "masters", masterId);
      await updateDoc(masterRef, { refillBonus });
      alert("Refill Bonus updated successfully.");
      onClose();
    } catch (error) {
      console.error("Error updating Refill Bonus:", error);
      alert("Failed to update Refill Bonus. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Refill Bonus</h2>

        <input
          type="number"
          placeholder="Refill Bonus %"
          value={refillBonus}
          onChange={(e) => setRefillBonus(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="Super Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? "Hide" : "Show"} Password
        </button>

        <div className="flex justify-between">
          <button
            onClick={handleRefillBonusUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default RefillBonusModal;
