import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

const ResetPasswordModal = ({ masterId, superPassword, onClose }) => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [enteredGodPassword, setEnteredGodPassword] = useState('');  // Field for God's password
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleResetPassword = async () => {
    // Validation: Ensure password has at least 6 characters and contains a special character
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;
    if (newPassword.length < 6 || !specialCharRegex.test(newPassword)) {
      alert('Password must be at least 6 characters long and contain at least one special character.');
      return;
    }

    // Validation: Check if the new password and confirmation match
    if (newPassword !== confirmPassword) {
      alert("Passwords do not match.");
      return;
    }

    // Validate the entered God password
    if (enteredGodPassword !== superPassword) {
      alert("Incorrect God password.");
      return;
    }

    try {
      const masterRef = doc(db, "masters", masterId);
      await updateDoc(masterRef, { password: newPassword });
      alert("Password updated successfully.");
      onClose();  // Close the modal after successful password update
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Failed to update password. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Reset Master Password</h2>

        {/* New Password Input */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="Enter New Password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Confirm Password Input */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="Confirm Password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* God's Password Input */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="Enter God Password"
          value={enteredGodPassword}
          onChange={(e) => setEnteredGodPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? "Hide" : "Show"} Password
        </button>

        {/* Error Message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleResetPassword}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordModal;
