import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
} from "firebase/firestore";
import { useLocation } from "react-router-dom";
import { db } from "../../../firebase";
import StatusModal from "./reuseComp/StatusModal";
import ExposureModal from "./reuseComp/ExposureModal";
import ResetPasswordModal from "./reuseComp/ResetPasswordModal";
import BettingModal from "./reuseComp/BettingModal";
import WithdrawModal from "./reuseComp/WithdrawModal";
import DepositModal from "./reuseComp/DepositModal";
import UserInfoModal from "./reuseComp/UserInfoModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ListOfClients = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const [exposureLimit, setExposureLimit] = useState(50000);
  const [amount, setAmount] = useState(0);

  const [users, setUsers] = useState([]);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({});
  const [isExposureModalOpen, setIsExposureModalOpen] = useState(false); // Control modal visibility
  const [selectedUserId, setSelectedUserId] = useState(null); // Store selected user ID
  const [selectedExposureLimit, setSelectedExposureLimit] = useState(""); // Store current exposure limit
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] =
    useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isBettingModalOpen, setIsBettingModalOpen] = useState(false);
  const [isXGBettingModalOpen, setIsXGBettingModalOpen] = useState(false);
  const [selectedBetType, setSelectedBetType] = useState("bet"); // 'bet' or 'xgBet'
  const [selectedBetStatus, setSelectedBetStatus] = useState(""); // To store current bet status

  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [selectedUserAmount, setSelectedUserAmount] = useState(0);

  const location = useLocation();
  const masterUsername = location.state?.username;

  const checkIfUsernameExists = async (username) => {
    const q = query(collection(db, "users"), where("username", "==", username));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty; // Return true if a document exists
  };

  const handleAdd = () => {
    setIsModalOpen(true);
  };

  const handleOpenStatusModal = (user) => {
    setCurrentUser(user);
    setIsStatusModalOpen(true); // Open the modal
  };

  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
  };

  const handleUpdateStatus = (userId, newStatus) => {
    setUsers(
      users.map((user) =>
        user.id === userId ? { ...user, status: newStatus } : user
      )
    );
  };

  const handleExposureClick = (userId, currentExposure) => {
    setSelectedUserId(userId);
    setSelectedExposureLimit(currentExposure);
    setIsExposureModalOpen(true);
  };

  const handleExposureSubmit = () => {
    setIsExposureModalOpen(false); // Close the modal
    fetchUsers(); // Refresh the user list
  };

  const handleResetPasswordClick = (user) => {
    setSelectedUser(user);
    setIsResetPasswordModalOpen(true);
  };

  const handlePasswordSubmit = async (newPassword) => {
    try {
      const userDocRef = doc(db, "users", selectedUser.id);
      await updateDoc(userDocRef, { password: newPassword });
      setIsResetPasswordModalOpen(false);
      toast.success("Password updated successfully");
    } catch (error) {
      console.error("Error updating password:", error);
      toast.error("Failed to update password.");
    }
  };

  const handleDepositSubmit = () => {
    setIsDepositModalOpen(false);
    fetchUsers(); // Re-fetch users after deposit
  };

  const handleWithdrawSubmit = () => {
    setIsWithdrawModalOpen(false);
    fetchUsers(); // Re-fetch users after withdrawal
  };

  const handleBetClick = (userId, currentBetStatus, type) => {
    setSelectedUserId(userId);
    setSelectedBetStatus(currentBetStatus);
    setSelectedBetType(type);

    if (type === "bet") {
      setIsBettingModalOpen(true);
    } else {
      setIsXGBettingModalOpen(true);
    }
  };

  const handleInfoClick = (user) => {
    setSelectedUser(user);
    setIsInfoModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsInfoModalOpen(false);
    setSelectedUser(null);
  };

  const handleBetSubmit = async (userId, newBetStatus, type) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const fieldToUpdate = type === "bet" ? "bet" : "xgBet"; // Determine which field to update

      await updateDoc(userDocRef, {
        [fieldToUpdate]: newBetStatus, // Update bet or xgBet status
      });

      fetchUsers(); // Refresh the user list
      setIsBettingModalOpen(false); // Close the modal
      setIsXGBettingModalOpen(false); // Close the modal
    } catch (error) {
      console.error("Error updating betting status:", error);
    }
  };

  const handleDepositClick = (user) => {
    setSelectedUser(user);
    setSelectedUserAmount(user.amount);
    setIsDepositModalOpen(true);
  };

  const handleWithdrawClick = (user) => {
    setSelectedUser(user);
    setSelectedUserAmount(user.amount);
    setIsWithdrawModalOpen(true);
  };

  const handleCancel = () => {
    setUsername("");
    setPassword("");
    setPhoneNumber("");
    setExposureLimit(50000); // Reset to default value
    setAmount(0);
    setIsModalOpen(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    const createdAt = new Date().toISOString();

    // Password validation
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Regex for special characters
    if (!password || password.length < 6 || !specialCharRegex.test(password)) {
      toast.error(
        "Password must be at least 6 characters long and contain at least one special character."
      );
      return;
    }

    if (!username) {
      toast.error("Username is required.");
      return;
    }

    try {
      const usernameExists = await checkIfUsernameExists(username);

      if (usernameExists) {
        toast.error("Username already exists. Please choose another one.");
        return;
      }

      // Convert the fields to numbers before saving to Firestore
      const numericPhoneNumber = parseInt(phoneNumber, 10); // Ensure phone number is saved as an integer
      const numericAmount = parseFloat(amount); // Ensure amount is saved as a float
      const numericExposureLimit = parseFloat(exposureLimit); // Ensure exposure limit is saved as a float
      const numericExposure = 0; // Default value for exposure (number)

      // Proceed with adding the user if the username does not exist
      const docRef = await addDoc(collection(db, "users"), {
        username,
        password,
        phoneNumber: isNaN(numericPhoneNumber) ? null : numericPhoneNumber, // Handle invalid phone numbers
        amount: 0, // Store as number
        exposureLimit: numericExposureLimit, // Store as number
        exposure: numericExposure, // Default number for exposure
        createdAt,
        createdBy: masterUsername,
        status: "ACTIVE",
        bet: "Off",
        xgBet: "Off",
        passwordChanged: false,
        ftdDone: false,
        bonus:0
      });

      // Clear fields after submit
      setUsername("");
      setPassword("");
      setPhoneNumber("");
      setExposureLimit(50000); // Reset to default value
      setAmount(0);

      setIsModalOpen(false);
      fetchUsers(); // Refresh the user list after adding
    } catch (e) {
      console.error("Error adding document: ", e);
    }
  };

  // Fetch users with createdBy == masterUsername
  const fetchUsers = async () => {
    if (!masterUsername) return;
    const q = query(
      collection(db, "users"),
      where("createdBy", "==", masterUsername)
    );
    const querySnapshot = await getDocs(q);
    const usersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setUsers(usersList);
  };

  useEffect(() => {
    fetchUsers(); // Initial fetch on component mount
    const intervalId = setInterval(() => {
      fetchUsers(); // Refresh the user list every 15 seconds
    }, 15000);
    return () => clearInterval(intervalId); // Clean up interval on unmount
  }, []);

  return (
    <div className="bg-black h-screen p-4">
      <p className="text-white text-center text-xl font-semibold">
        List Of Clients
      </p>

      <div className="flex space-x-3">
        <button
          onClick={handleAdd}
          className="bg-blue-700 p-2 text-white rounded-lg hover:bg-black hover:border-2"
        >
          Add User
        </button>
        {/* <button onClick={handleAdd} className="bg-blue-700 p-2 text-white rounded-lg hover:bg-black hover:border-2">
          Affiliation List
        </button>
        <button onClick={handleAdd} className="bg-blue-700 p-2 text-white rounded-lg hover:bg-black hover:border-2">
          Campaign Clients
        </button>
        <button onClick={handleAdd} className="bg-blue-700 p-2 text-white rounded-lg hover:bg-black hover:border-2">
          Clients Reference
        </button>
        <button onClick={handleAdd} className="bg-blue-700 p-2 text-white rounded-lg hover:bg-black hover:border-2">
          Total D/W
        </button> */}
      </div>

      {/* Modal */}
      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 ">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            {" "}
            {/* Increase width using w-96 */}
            <h2 className="text-white text-xl mb-4">Add New User</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-white mb-2">
                  Username <span className="font-bold">*</span>{" "}
                  {/* Add asterisk */}
                </label>
                <input
                  type="text"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className="w-full p-2 rounded-lg bg-gray-700 text-white"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-white mb-2">
                  Password <span className="font-bold">*</span>{" "}
                  {/* Add asterisk */}
                </label>
                <input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className="w-full p-2 rounded-lg bg-gray-700 text-white"
                  required
                />
                {/* Show error message if validation fails */}
                {password &&
                  (password.length < 6 ||
                    !/[!@#$%^&*(),.?":{}|<>]/.test(password)) && (
                    <p className="text-red-500 mt-1">
                      Password must be at least 6 characters long and contain
                      one special character.
                    </p>
                  )}
              </div>
              <div className="mb-4">
                <label className="block text-white mb-2">Phone Number</label>
                <input
                  type="number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className="w-full p-2 rounded-lg bg-gray-700 text-white"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white mb-2">Exposure Limit</label>
                <input
                  type="text"
                  value={exposureLimit}
                  onChange={(e) => setExposureLimit(e.target.value)}
                  className="w-full p-2 rounded-lg bg-gray-700 text-white"
                />
              </div>
              <div className="mb-4">
                <label className="block text-white mb-2">Amount</label>
                <input
                  type="text"
                  value="0"
                  disabled // Make the input non-editable
                  className="w-full p-2 rounded-lg bg-gray-700 text-white"
                />
              </div>
              <div className="flex justify-end space-x-4">
                <button
                  type="submit"
                  className="bg-green-600 text-white px-4 py-2 rounded-lg"
                >
                  Submit
                </button>
                <button
                  type="button"
                  onClick={handleCancel} // Reset fields on cancel
                  className="bg-red-600 text-white px-4 py-2 rounded-lg"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* ////////////////////// */}

      <StatusModal
        user={currentUser}
        isOpen={isStatusModalOpen}
        onClose={handleCloseStatusModal}
        onUpdate={handleUpdateStatus}
      />

      {isExposureModalOpen && (
        <ExposureModal
          userId={selectedUserId}
          currentExposure={selectedExposureLimit}
          onClose={() => setIsExposureModalOpen(false)}
          onSubmit={handleExposureSubmit}
        />
      )}

      {isResetPasswordModalOpen && (
        <ResetPasswordModal
          username={selectedUser.username}
          onClose={() => setIsResetPasswordModalOpen(false)}
          onSubmit={handlePasswordSubmit}
        />
      )}

      {isBettingModalOpen && (
        <BettingModal
          userId={selectedUserId}
          currentBetStatus={selectedBetStatus}
          type="bet"
          onClose={() => setIsBettingModalOpen(false)}
          onSubmit={handleBetSubmit}
        />
      )}

      {isXGBettingModalOpen && (
        <BettingModal
          userId={selectedUserId}
          currentBetStatus={selectedBetStatus}
          type="xgBet"
          onClose={() => setIsXGBettingModalOpen(false)}
          onSubmit={handleBetSubmit}
        />
      )}

      {isDepositModalOpen && (
        <DepositModal
          userId={selectedUser.id}
          clientUsername={selectedUser.username}
          masterUsername={masterUsername}
          currentAmount={selectedUserAmount}
          onClose={handleDepositSubmit} // Close modal and refresh data
        />
      )}

      {isWithdrawModalOpen && (
        <WithdrawModal
          userId={selectedUser.id}
          clientUsername={selectedUser.username}
          masterUsername={masterUsername}
          currentAmount={selectedUserAmount}
          onClose={handleWithdrawSubmit} // Close modal and refresh data
        />
      )}

      <UserInfoModal
        isOpen={isInfoModalOpen}
        onClose={handleCloseModal}
        user={selectedUser}
      />

      {/* --------------------------------------- */}

      {/* Display Users */}
      <div className="mt-10 px-4 overflow-x-auto">
        <h2 className="text-white text-xl mb-4">User List</h2>
        <table className="min-w-full text-white">
          <thead>
            <tr className="bg-gray-800">
              <th className="p-2 text-left">UserName</th>
              <th className="p-2 text-left">Transfer</th>
              <th className="p-2 text-left">Betting</th>
              <th className="p-2 text-left">Pass</th>
              <th className="p-2 text-left">Exp Limit</th>
              <th className="p-2 text-left">Registered On</th>
              <th className="p-2 text-left">Status</th>
              <th className="p-2 text-left">Bal</th>
              <th className="p-2 text-left">Exposure</th>
              <th className="p-2 text-left">Net Bal</th>
              <th className="p-2 text-left">Info</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index} className="bg-black border-b border-gray-700">
                <td className="p-2 text-blue-500 font-bold">{user.username}</td>
                <td className="p-2">
                  <button
                    onClick={() => handleDepositClick(user)}
                    className="border-4 border-green-700 px-2 py-1 rounded-md"
                  >
                    D
                  </button>
                  <span>/</span>
                  <button
                    onClick={() => handleWithdrawClick(user)}
                    className="border-4 border-gray-500 px-2 py-1 rounded-md"
                  >
                    W
                  </button>
                </td>
                <td className="p-2">
                  <button
                    className={`border-4 px-2 py-1 rounded-md ${
                      user.bet === "On" ? "border-green-700" : "border-red-500"
                    }`}
                    onClick={() => handleBetClick(user.id, user.bet, "bet")}
                  >
                    {user.bet}
                  </button>
                  <button
                    className={`border-4 px-2 py-1 rounded-md ml-2 ${
                      user.xgBet === "On"
                        ? "border-green-700"
                        : "border-red-500"
                    }`}
                    onClick={() => handleBetClick(user.id, user.xgBet, "xgBet")}
                  >
                    {user.xgBet}
                  </button>
                </td>

                <td className="p-2">
                  <button
                    className="border-4 border-gray-500 px-2 py-1 rounded-md"
                    onClick={() => handleResetPasswordClick(user)}
                  >
                    Reset
                  </button>
                </td>
                <td className="p-2">
                  <button
                    className="border-4 border-purple-800 px-2 py-1 rounded-md"
                    onClick={() =>
                      handleExposureClick(user.id, user.exposureLimit)
                    }
                  >
                    {user.exposureLimit}
                  </button>
                </td>
                <td className="p-2">
                  {new Date(user.createdAt).toLocaleString()}
                </td>
                <td className="p-2">
                  <button
                    className="border-4 border-green-500 px-2 py-1 rounded-md"
                    onClick={() => handleOpenStatusModal(user)} // Pass user to open modal
                  >
                    {user.status}
                  </button>
                </td>

                <td className="p-2">{user.amount}</td>
                <td className="p-2">{user.exposure}</td>
                <td className="p-2 text-gray-300 text-lg">
                  {parseFloat(user.amount) - parseFloat(user.exposure)}
                </td>

                <td className="p-2">
                  <button
                    onClick={() => handleInfoClick(user)}
                    className="bg-gray-600 px-2 py-1 rounded-md"
                  >
                    i
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ListOfClients;
