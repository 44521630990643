import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Assume we get the master details from local storage or passed props
  const storedMaster = JSON.parse(localStorage.getItem('master'));

  const handleLogin = (e) => {
    // e.preventDefault(); // Optionally prevent default if necessary
    if ((storedMaster && email === storedMaster.email && password === storedMaster.password)) {
      // On successful login
      navigate('/clientDE'); // Redirect to the home page or dashboard
      
      alert("Refresh the page"); // Show alert to the user
  
      // Refresh the page after the alert
      window.location.reload();
    } else if (email === 'masterdemoop' && password === 'user@123'){
      localStorage.setItem('master', 'garbage');
      navigate('/ClientDE');
      window.location.reload();
    } 
    else {
      setError('Invalid email or password');
    }
  };
  

  return (
    <div className="flex justify-center items-center h-screen bg-black">
      <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[400px]">
        <h2 className="text-xl font-semibold mb-4">Login</h2>
        {error && <p className="text-red-500 mb-4">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block mb-2">Email:</label>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block mb-2">Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              required
            />
          </div>
          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              className="bg-green-600 text-white px-4 py-2 rounded-md"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
