import React, { useState, useEffect } from 'react';
import { doc, getDoc, getDocs, query, collection, where, updateDoc } from 'firebase/firestore';
import { db } from '../../../firebase';

const CreditAmtModal = ({ masterId, onClose }) => {
  const [creditAmt, setCreditAmt] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [godPassword, setGodPassword] = useState(null);
  const [error, setError] = useState(null);

  // Fetch the god password when the component mounts
  useEffect(() => {
    const fetchGodPassword = async () => {
      try {
        const q = query(collection(db, 'god'), where('username', '==', 'god'));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const godData = querySnapshot.docs[0].data();
          setGodPassword(godData.password);  // Fetch the god password from the doc
        } else {
          setError('God user not found');
        }
      } catch (error) {
        console.error('Error fetching god password:', error);
        setError('Failed to fetch god password');
      }
    };

    fetchGodPassword();
  }, []);

  // Toggle password visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Handle form submission and update Firestore
  const handleCreditAmtUpdate = async () => {
    if (enteredPassword !== godPassword) {
      setError('Incorrect god password.');
      return;
    }

    try {
      const masterRef = doc(db, 'super', masterId);  // Fetch the correct master document by its ID
      const masterSnap = await getDoc(masterRef);  // Fetch the master document

      if (masterSnap.exists()) {
        const masterData = masterSnap.data();
        const newTotalAmt = parseFloat(masterData.balance) + parseFloat(creditAmt);  // Calculate new totalAmt

        // Update both creditAmt and totalAmt in Firestore
        await updateDoc(masterRef, {
          creditAmt: parseFloat(creditAmt),
          totalAmt: newTotalAmt
        });

        alert('Credit Amount and Total Amount updated successfully.');
        onClose();  // Close the modal after successful update
      } else {
        setError("Master document not found.");
      }
    } catch (error) {
      console.error('Error updating Credit Amount and Total Amount:', error);
      alert('Failed to update Credit Amount and Total Amount. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Update Credit Amount</h2>

        {/* Input field for credit amount */}
        <input
          type="number"
          placeholder="Enter Credit Amount"
          value={creditAmt}
          onChange={(e) => setCreditAmt(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password input field */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="God Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? "Hide" : "Show"} Password
        </button>

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleCreditAmtUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreditAmtModal;
