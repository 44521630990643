import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

const ExposureModal = ({ userId, currentExposure, onClose, onSubmit }) => {
  const [newExposureLimit, setNewExposureLimit] = useState(currentExposure);

  // Function to update the exposure limit in the Firestore database
  const handleSubmit = async () => {
    try {
      const userDoc = doc(db, "users", userId);
      await updateDoc(userDoc, {
        exposureLimit: newExposureLimit,
      });
      onSubmit(); // Callback to update the UI and close the modal
    } catch (error) {
      console.error("Error updating exposure limit:", error);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-white text-xl mb-4">Exposure Limit of User</h2>
        <div className="mb-4">
          <label className="block text-white mb-2">Limit</label>
          <input
            type="number"
            value={newExposureLimit}
            onChange={(e) => setNewExposureLimit(e.target.value)}
            className="w-full p-2 bg-gray-700 text-white rounded-md"
          />
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSubmit}
            className="bg-green-600 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-600 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExposureModal;
