import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';

const AcctStmtGodMaster = () => {
  const [transactions, setTransactions] = useState([]);

  // Function to fetch all transactions (withdraw + deposit) for God Master
  const fetchTransactions = async () => {
    try {
      // Fetch withdraw transactions for God Master
      const withdrawRef = collection(db, `accountStmt/godMaster/withdraw`);
      const withdrawQuery = query(withdrawRef, orderBy('createdAt', 'desc'));
      const withdrawSnapshot = await getDocs(withdrawQuery);
      const withdrawTransactions = withdrawSnapshot.docs.map(doc => ({
        id: doc.id,
        type: 'withdraw',
        ...doc.data(),
      }));

      // Fetch deposit transactions for God Master
      const depositRef = collection(db, `accountStmt/godMaster/deposit`);
      const depositQuery = query(depositRef, orderBy('createdAt', 'desc'));
      const depositSnapshot = await getDocs(depositQuery);
      const depositTransactions = depositSnapshot.docs.map(doc => ({
        id: doc.id,
        type: 'deposit',
        ...doc.data(),
      }));

      // Combine both transactions
      const allTransactions = [...withdrawTransactions, ...depositTransactions];

      // Sort all transactions by createdAt in descending order
      const sortedTransactions = allTransactions.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setTransactions(sortedTransactions);  // Set the combined, sorted transactions
    } catch (error) {
      console.error('Error fetching transactions:', error);
    }
  };

  useEffect(() => {
    fetchTransactions();  // Fetch the transactions when component mounts
  }, []);

  return (
    <div className="bg-black h-screen p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-white text-2xl font-semibold">God Master Account Statement</h1>
        <button className="bg-blue-600 text-white px-4 py-2 rounded-lg">Excel</button>
      </div>

      <div className="overflow-x-auto">
        <table className="min-w-full text-white border-collapse text-sm"> {/* Adjust text size here */}
          <thead>
            <tr className="bg-gray-800 text-left">
              <th className="p-3">Trans ID</th>
              <th className="p-3">Master Username</th>
              <th className="p-3">Settled Date</th>
              <th className="p-3 w-1/3">Description</th> {/* Adjust width for description */}
              <th className="p-3">Withdraw</th>
              <th className="p-3">Deposit</th>
              {/* <th className="p-3">Balance</th> */}
              <th className="p-3">Net Balance</th>

            </tr>
          </thead>
          <tbody>
            {transactions.length > 0 ? (
              transactions.map((transaction, index) => {
                const isDebit = transaction.type === 'withdraw';
                const isCredit = transaction.type === 'deposit';
                const balance = transaction.balance || 0;

                return (
                  <tr key={index} className="bg-black border-b border-gray-700">
                    <td className="p-3">{transaction.transactionId}</td>
                    <td className="p-3">{transaction.masterName}</td>
                    <td className="p-3">{new Date(transaction.createdAt).toLocaleString()}</td>
                    <td className="p-3 w-1/3">{transaction.description}</td> {/* Wider description */}
                    <td className="p-3 text-red-500">{isDebit ? transaction.amount.toFixed(2) : '0.00'}</td>
                    <td className="p-3 text-green-500">{isCredit ? transaction.amount.toFixed(2) : '0.00'}</td>
                    {/* <td className="p-3 text-green-500">{balance.toFixed(2)}</td> */}
                    <td className="p-3 text-yellow-500 font-bold">{transaction.netBal ? transaction.netBal.toFixed(2) : '0.00'}</td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td colSpan="7" className="text-center p-4 text-gray-300">
                  No transactions found.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AcctStmtGodMaster;
