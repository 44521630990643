import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ClientWi = () => {
  const location = useLocation();
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 15); // Subtract 15 days
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [heading, setHeading] = useState("Withdraw States");
  const [withdrawData, setWithdrawData] = useState([]);
  const [status, setStatus] = useState("Action");
  const [lastWithdrawalId, setLastWithdrawalId] = useState(null);
  const notificationSound = new Audio("/assets/notification.wav");
  const [loading, setLoading] = useState(true);
  const prevDepositDataRef = useRef([]); // Use useRef to track previous deposit data



  // Set heading dynamically based on location state
  useEffect(() => {
    if (location.state && location.state.heading) {
      setHeading(location.state.heading);
    }
  }, [location.state]);


  const loggedInSuperUsername = location.state?.username

  const fetchMastersAndUsers = async () => {
    setLoading(true);
    try {
      // Step 1: Fetch the masters created by the logged-in super
      const mastersRef = collection(db, 'masters');
      const masterQuery = query(mastersRef, where('title', '==', loggedInSuperUsername));
      const masterSnapshot = await getDocs(masterQuery);
      const masterUsernames = masterSnapshot.docs.map((doc) => doc.data().username);
      // console.log(masterUsernames)
      
      // Step 2: Fetch the users created by those masters
      const usersRef = collection(db, 'users');
      const usersSnapshot = await getDocs(usersRef);
      const filteredUsers = usersSnapshot.docs
        .map((doc) => doc.data())
        .filter((user) => masterUsernames.includes(user.createdBy))
        .map((user) => user.username); // Get just the usernames of these users
      
      return filteredUsers; // Return the filtered usernames
    } catch (error) {
      console.error('Error fetching masters and users:', error);
      setLoading(false);
      return [];
    }
  };

  useEffect(() => {
    const fetchWithdrawalsForFilteredUsers = async () => {
      const filteredUsernames = await fetchMastersAndUsers(); // Fetch users created by the masters of the logged-in super

      if (filteredUsernames.length === 0) {
        setWithdrawData([]); // No users, no withdrawals
        setLoading(false);
        return;
      }

      // Step 3: Fetch withdrawals for these filtered users
      const withdrawalQuery = query(
        collection(db, 'transaction/withdraw/user'),
        where('holderName', 'in', filteredUsernames), // Only fetch withdrawals for users created by the masters
        orderBy('timestamp', 'desc')
      );

      const unsubscribe = onSnapshot(withdrawalQuery, (snapshot) => {
        const newWithdrawData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const prevDepositData = prevDepositDataRef.current;

        const isNewDeposit = newWithdrawData.some((newDeposit) => {
          return !prevDepositData.some((prevDeposit) => prevDeposit.id === newDeposit.id);
        });

        // Check if the content has changed, not just the length
        const hasNewData = newWithdrawData.some(
          (newData, index) => newData.id !== withdrawData[index]?.id
        );

        if (isNewDeposit) {
          notificationSound.play().catch((error) =>
            console.error('Audio playback failed:', error)
          );
        }

        setWithdrawData(newWithdrawData); // Set the withdrawal data in state
        prevDepositDataRef.current = newWithdrawData;

        setLoading(false);
      });

      return () => unsubscribe();
    };

    fetchWithdrawalsForFilteredUsers();
  }, [loggedInSuperUsername]);

  // Handle action selection from dropdown
  // async function handleAction(e, t) {
  //   const selectedAction = e.target.value;
  //   await updateDoc(doc(db, `transaction/withdraw/user/${t.id}`), {
  //     ...t,
  //     orderStatus: selectedAction,
  //   });

  //   if (selectedAction === "Accept") {
  //     const usersCollection = collection(db, "users");
  //     const q = query(usersCollection, where("username", "==", t.holderName));
  //     const querySnapshot = await getDocs(q);

  //     if (!querySnapshot.empty) {
  //       const userDoc = querySnapshot.docs[0];
  //       const userData = userDoc.data();
  //       const currentAmount = userData.amount || 0;
  //       const newAmount = currentAmount - t.amount;

  //       if (newAmount < 0) {
  //         alert("Insufficient funds");
  //         return;
  //       }

  //       await updateDoc(userDoc.ref, { amount: newAmount });
  //       alert("Amount updated successfully!");
  //     } else {
  //       console.log("User not found");
  //     }
  //   } else {
  //     alert("Order status updated");
  //   }
  // }

  // Handle action selection from dropdown
  async function handleAction(e, t) {
    const selectedAction = e.target.value;
    const previousStatus = t.orderStatus; // The previous status before action

  
    // Check if status change is from "Pending" or "Rejected" to "Accepted"
    if (selectedAction === "Accept" && (previousStatus === "pending" || previousStatus === "Rejected")) {
      const usersCollection = collection(db, "users");
      const q = query(usersCollection, where("username", "==", t.holderName));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        const currentUserBalance = parseFloat(userData.amount) || 0;
        const withdrawalAmount = parseFloat(t.amount) || 0;
  
        // Fetch the super's balance (replace 'superUsername' with your method of fetching super's username)
        const superUsername = "loggedInSuper"; // Assume logged-in super's username
        const superQuery = query(collection(db, "super"), where("username", "==", loggedInSuperUsername));
        const superSnapshot = await getDocs(superQuery);
  
        if (!superSnapshot.empty) {
          const superDoc = superSnapshot.docs[0];
          const superData = superDoc.data();
          const currentSuperBalance = parseFloat(superData.balance) || 0;
  
          // Check if the user has enough balance for the withdrawal
          if (currentUserBalance >= withdrawalAmount) {
            // Deduct the withdrawal amount from the user's balance
            const newUserBalance = currentUserBalance - withdrawalAmount;
            await updateDoc(userDoc.ref, { amount: newUserBalance });
  
            // Add the withdrawal amount to the super's balance
            const newSuperBalance = currentSuperBalance + withdrawalAmount;
            await updateDoc(superDoc.ref, { balance: newSuperBalance });
  
            // Update the order status to "Accepted" in the withdrawal transaction
            await updateDoc(doc(db, `transaction/withdraw/user/${t.id}`), {
              ...t,
              orderStatus: selectedAction,
            });
  
            toast.success("Transaction accepted. Amount updated successfully!");
          } else {
            // If insufficient balance, update the status to "Rejected"
            await updateDoc(doc(db, `transaction/withdraw/user/${t.id}`), {
              ...t,
              orderStatus: "Rejected",
            });
  
            toast.error("Insufficient balance. Withdrawal has been rejected.");
          }
        } else {
          console.log("Super not found");
        }
      } else {
        console.log("User not found");
      }
    } else if (selectedAction !== "Accept") {
      // Allow status change only when it's not from Pending to Rejected or vice versa
      if (previousStatus !== selectedAction) {
        // Update the order status if it's not "Accepted"
        await updateDoc(doc(db, `transaction/withdraw/user/${t.id}`), {
          ...t,
          orderStatus: selectedAction,
        });
  
        toast.success("Order status updated");
      }
    } else {
      toast.error("Invalid action or status change not allowed. Try again later");
    }
  }
  


  const handleLoad = () => {
    // Reload the report data
    window.location.reload();
  };

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <h1 className="text-2xl text-center mt-6 font-semibold mb-8">
        {heading}
      </h1>
      <div className="flex flex-col md:flex-row justify-between w-full md:w-3/4 mx-auto">
        <div className="flex flex-col items-start mb-4 md:mb-0">
          <label className="mb-2 text-lg">Start Date and Time</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4">
            Excel
          </button>
        </div>
        <div className="flex flex-col items-start">
          <label className="mb-2 text-lg">End Date and Time</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4"
            onClick={handleLoad}
          >
            Load Report
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 text-white rounded-lg mt-10 pl-4 md:pl-10">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Account Number
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Bank Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Branch Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Holder Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                IFSC Code
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Payment Mode
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Order Status
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Option
              </th>
            </tr>
          </thead>
          {loading ? (
        <div className="flex justify-center items-center h-10vh">
        <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-blue-500"></div>
      </div>
      ) :
          (<tbody>
            {withdrawData?.map((d, index) => (
              <tr key={index} className="bg-gray-800 border-b border-gray-700">
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.accountNum}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.bankName}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.amount}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.branchName}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.holderName}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.ifscCode}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.payMode}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.orderStatus}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  <div className="relative inline-block text-left">
                    <select
                      value={status}
                      onChange={(e) => handleAction(e, d)}
                      className="bg-transparent text-white"
                    >
                      <option disabled className="bg-transparent text-black">
                        Action
                      </option>
                      <option
                        value="pending"
                        className="bg-transparent text-black"
                      >
                        Pending
                      </option>
                      <option
                        value="Accept"
                        className="bg-transparent text-black"
                      >
                        Accept
                      </option>
                      {/* <option
                        value="Reject"
                        className="bg-transparent text-black"
                      >
                        Reject
                      </option> */}
                    </select>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>)}
        </table>
      </div>
    </div>
  );
};

export default ClientWi;
