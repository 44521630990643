import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { collection, doc, getDocs, onSnapshot, orderBy, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ClientDE = () => {
  const location = useLocation();
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 15); // Subtract 15 days
    return date;
  });
  
  const [endDate, setEndDate] = useState(new Date());
  const [heading, setHeading] = useState("DW States");
  const [depositData, setDepositData] = useState([]);
  const [status, setStatus] = useState("Action");
  const notificationSound = new Audio('/assets/notification.wav');
  const [loading, setLoading] = useState(true);
  const prevDepositDataRef = useRef([]); // Use useRef to track previous deposit data



  // Set the heading based on the location state
  useEffect(() => {
    if (location.state && location.state.heading) {
      setHeading(location.state.heading);
    }
  }, [location.state]);

  
  useEffect(() => {
    const fetchMastersAndUsersForDeposits = async () => {
      try {
        setLoading(true); // Start loading
        const loggedInSuper = location.state?.username; // Logged-in super's username
  
        // Step 1: Fetch all masters created by the logged-in super
        const mastersRef = collection(db, 'masters');
        const masterQuery = query(mastersRef, where('title', '==', loggedInSuper));
        const masterSnapshot = await getDocs(masterQuery);
        const mastersData = masterSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  
        // Extract the usernames of those masters
        const masterUsernames = mastersData.map((master) => master.username);
  
        // Step 2: Fetch users created by those masters
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const filteredUsers = usersSnapshot.docs
          .map((doc) => ({ id: doc.id, ...doc.data() }))
          .filter((user) => masterUsernames.includes(user.createdBy))
          .map((user) => user.username); // Get the usernames of the filtered users
  
        // Step 3: Fetch deposits for the filtered users within the specified date range
        if (filteredUsers.length === 0) {
          setDepositData([]); // No users, no deposits
          setLoading(false); // Stop loading since no data
          return;
        }
  
        const depositQuery = query(
          collection(db, 'transaction/deposit/user'),
          where('username', 'in', filteredUsers), // Fetch deposits for the users created by the masters
          where('timestamp', '>=', startDate),
          where('timestamp', '<=', endDate),
          orderBy('timestamp', 'desc')
        );
  
        const unsubscribe = onSnapshot(depositQuery, (snapshot) => {
          const newDepositData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const prevDepositData = prevDepositDataRef.current;
           
          const isNewDeposit = newDepositData.some((newDeposit) => {
            return !prevDepositData.some((prevDeposit) => prevDeposit.id === newDeposit.id);
          });
  
          // Only update if new data is available
        //   if (JSON.stringify(newDepositData) !== JSON.stringify(depositData)) {
        //     setDepositData(newDepositData); // Update the state with new deposits
        //     setLoading(false); // Stop loading after data is fetched
        //     notificationSound.play().catch((error) =>
        //       console.error('Audio playback failed:', error)
        //     );
        //   } else {
        //     setLoading(false); // Stop loading if data hasn't changed
        //   }
        

        if (isNewDeposit) {
          notificationSound.play().catch((error) =>
            console.error('Audio playback failed:', error)
          );
        }

        // Update the state with new deposit data
        setDepositData(newDepositData);
        prevDepositDataRef.current = newDepositData;

        setLoading(false); // Stop loading after data is fetched

      });
  
        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching masters, users, and deposits:', error);
        setLoading(false); // Stop loading in case of error
      }
    };
  
    fetchMastersAndUsersForDeposits();
  }, [startDate, endDate, location.state?.username]); // Remove `depositData` from dependencies
  
  
  

  

  // Handle action dropdown
  async function handleAction(e, t) {
    const selectedAction = e.target.value;

    // Prevent changing to the same status
    if (selectedAction === t.orderStatus) {
      toast.error("This status is already set.");
        return;
    }

    // Fetch the user
    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('username', '==', t.username));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        const currentUserAmount = parseFloat(userData.amount) || 0;
        const depositAmount = parseFloat(t.reqAmnt) || 0;

        // Fetch the logged-in super's balance
        const loggedInSuper = location.state?.username; // Assuming super's username is passed via state
        const superCollection = collection(db, 'super');
        const superQuery = query(superCollection, where('username', '==', loggedInSuper));
        const superSnapshot = await getDocs(superQuery);

        if (!superSnapshot.empty) {
            const superDoc = superSnapshot.docs[0];
            const superData = superDoc.data();
            const superCurrentBalance = parseFloat(superData.balance) || 0;

            // Handle status change between "Pending" and "Rejected" without affecting balances
            if ((t.orderStatus === "Pending" || t.orderStatus === "Rejected") && 
                (selectedAction === "Pending" || selectedAction === "Rejected")) {
                // Simply update the status without touching balances
                await updateDoc(doc(db, `transaction/deposit/user/${t.id}`), {
                    ...t,
                    orderStatus: selectedAction,
                });

                // Update local state for immediate UI change
                setDepositData((prevData) =>
                    prevData.map((item) =>
                        item.id === t.id ? { ...item, orderStatus: selectedAction } : item
                    )
                );

                toast.success("Status changed");
                return;
            }

            // Handle "Accept" status change (checking if super has enough balance)
            if (selectedAction === "Accept" && t.orderStatus !== "Accept") {
                if (superCurrentBalance < depositAmount) {
                  toast.error("Super has insufficient balance to accept this deposit.");
                    return; // Exit without updating the status
                }

                // Deduct from super balance and add to user balance
                const newSuperBalance = superCurrentBalance - depositAmount;
                const newUserAmount = currentUserAmount + depositAmount;

                // Update super's balance and user's balance
                await updateDoc(superDoc.ref, { balance: newSuperBalance });
                await updateDoc(userDoc.ref, { amount: newUserAmount });

                // Update the order status to "Accept"
                await updateDoc(doc(db, `transaction/deposit/user/${t.id}`), {
                    ...t,
                    orderStatus: "Accept",
                });

                // Update local state for immediate UI change
                setDepositData((prevData) =>
                    prevData.map((item) =>
                        item.id === t.id ? { ...item, orderStatus: "Accept" } : item
                    )
                );

                toast.success("Deposit accepted");
            } 
            // Handle reverting from "Accept" to another status (refund super, deduct from user)
            else if (t.orderStatus === "Accept" && selectedAction !== "Accept") {
                // Deduct the deposit amount from the user's balance
                const newUserAmount = currentUserAmount - depositAmount;

                if (newUserAmount < 0) {
                  toast.error("Insufficient user balance to revert this deposit.");
                    return;
                }

                // Refund the deposit amount to the super's balance
                const newSuperBalance = superCurrentBalance + depositAmount;

                // Update super's balance and user's balance
                await updateDoc(superDoc.ref, { balance: newSuperBalance });
                await updateDoc(userDoc.ref, { amount: newUserAmount });

                // Update the order status
                await updateDoc(doc(db, `transaction/deposit/user/${t.id}`), {
                    ...t,
                    orderStatus: selectedAction,
                });

                // Update local state for immediate UI change
                setDepositData((prevData) =>
                    prevData.map((item) =>
                        item.id === t.id ? { ...item, orderStatus: selectedAction } : item
                    )
                );

                toast.success("Deposit status updated");
            }
        } else {
          toast.error("Super not found");
        }
    } else {
      toast.error("User not found");
    }
}







  

  const handleLoad = () => {
    // Reload the data when "Load Report" is clicked
    window.location.reload();
  }

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <h1 className="text-2xl text-center mt-6 font-semibold mb-8">{heading}</h1>
      <div className="flex flex-col md:flex-row justify-between w-full md:w-3/4 mx-auto">
        <div className="flex flex-col items-start mb-4 md:mb-0">
          <label className="mb-2 text-lg">Start Date and Time</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 z-20 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4">Excel</button>
        </div>
        <div className="flex flex-col items-start">
          <label className="mb-2 text-lg">End Date and Time</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4" onClick={handleLoad}>Load Report</button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 text-white rounded-lg mt-10 pl-4 md:pl-10">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Client Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                User Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Req Amount
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Trnst ID
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Order Status
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Option
              </th>
            </tr>
          </thead>
          {
            loading ? (
              <div className="flex justify-center items-center h-10vh">
        <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-blue-500"></div>
      </div>
            ) :
          (<tbody>
            {depositData?.map((d, index) => (
              <tr key={index} className="bg-gray-800 border-b border-gray-700">
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="text-green-500 mr-2">+</span>
                    <span>{d?.clinetName}</span>
                  </div>
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.username}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap text-green-500">{d?.reqAmnt}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.trnstID}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.orderStatus}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  <div className="relative inline-block text-left">
                    <select
                      value={status}
                      onChange={(e) => handleAction(e, d)}
                      className="bg-transparent text-white"
                    >
                      <option disabled className="bg-transparent text-black">
                        Action
                      </option>
                      <option value="pending" className="bg-transparent text-black">
                        Pending
                      </option>
                      <option value="Accept" className="bg-transparent text-black">
                        Accept
                      </option>
                      {/* <option value="Reject" className="bg-transparent text-black">
                        Reject
                      </option> */}
                    </select>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>)}
        </table>
      </div>
    </div>
  );
};

export default ClientDE;
