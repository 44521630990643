import React, { useState, useEffect } from "react";
import { IoPersonSharp } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { db } from "../firebase"; // Firestore DB import
import {
  collection,
  doc,
  getDocs,
  getDoc,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";

const SmallScreenTopBar = ({ role, username }) => {
  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdown1, setShowDropdown1] = useState(false);
  const [showDropdown2, setShowDropdown2] = useState(false);
  const [showDropdown3, setShowDropdown3] = useState(false);
  const [showDropdown4, setShowDropdown4] = useState(false);
  const [showDropdown5, setShowDropdown5] = useState(false);
  const [logo, setLogo] = useState("");
  const [logoload, setLogoLoad] = useState(true);

  const [showProfileDropdown, setShowProfileDropdown] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [balance, setBalance] = useState(0);
  const [superBal, setSuperBal] = useState(0);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showSidebar, setShowSidebar] = useState(false); // Toggle sidebar menu

  const navigate = useNavigate();

  const handleNavigate = (path) => {
    navigate(path, { state: { role, username } });
    setShowSidebar(false);
  };

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  useEffect(() => {
    setSuperBal("");
  }, []);

  useEffect(() => {
    const fetchBalance = async () => {
      try {
        let unsubscribe;

        if (role === "master") {
          // Query to fetch master document where username equals the provided username
          const mastersCollection = collection(db, "masters");
          const masterQuery = query(
            mastersCollection,
            where("username", "==", username)
          );

          // Real-time updates for master
          unsubscribe = onSnapshot(masterQuery, (querySnapshot) => {
            if (!querySnapshot.empty) {
              const masterData = querySnapshot.docs[0].data(); // Assuming only one document matches
              setBalance(masterData.balance);
            } else {
              console.log("Master not found");
            }
          });
        } else if (role === "super") {
          // Query to fetch super document where username equals the provided username
          const superCollection = collection(db, "super");
          const superQuery = query(
            superCollection,
            where("username", "==", username)
          );

          // Real-time updates for super
          unsubscribe = onSnapshot(superQuery, (querySnapshot) => {
            if (!querySnapshot.empty) {
              const superData = querySnapshot.docs[0].data(); // Assuming only one document matches
              setSuperBal(superData.balance);
            } else {
              console.log("Super not found");
            }
          });
        }

        return () => unsubscribe && unsubscribe(); // Clean up listener on unmount
      } catch (error) {
        console.error("Error fetching balance:", error);
      }
    };

    fetchBalance();
  }, [role, username]);

  const handleNavigateAddWhole = (path) => {
    if (role && username) {
      // console.log({ role, username })
      navigate(path, { state: { role: "god", username } }); // Pass serializable data only
    } else {
      console.error("Role or Username is missing");
    }
  };

  const handleMouseEnter = () => {
    setShowDropdown(true);
  };

  const handleMouseLeave = () => {
    setShowDropdown(false);
  };

  const handleMouseEnter1 = () => {
    setShowDropdown1(true);
  };

  const handleMouseLeave1 = () => {
    setShowDropdown1(false);
  };

  const handleMouseEnter2 = () => {
    setShowDropdown2(true);
  };

  const handleMouseLeave2 = () => {
    setShowDropdown2(false);
  };

  const handleMouseEnter3 = () => {
    setShowDropdown3(true);
  };

  const handleMouseLeave3 = () => {
    setShowDropdown3(false);
  };

  const handleMouseEnter4 = () => {
    setShowDropdown4(true);
  };

  const handleMouseLeave4 = () => {
    setShowDropdown4(false);
  };

  const handleMouseEnter5 = () => {
    setShowDropdown5(true);
  };

  const handleMouseLeave5 = () => {
    setShowDropdown5(false);
  };

  const handleProfileClick = () => {
    setShowProfileDropdown(!showProfileDropdown);
  };

  const handleChangePasswordClick = () => {
    setShowChangePasswordModal(true);
    setShowProfileDropdown(false);
  };

  const handleCloseChangePasswordModal = () => {
    setOldPassword("");
    setNewPassword("");
    setConfirmPassword("");

    setShowChangePasswordModal(false);
  };

  const handleUsers = () => {
    handleNavigate("/add");
  };

  const handleLogout = () => {
    navigate("/");
  };

  const fetchlogo = async () => {
    try {
      setLogoLoad(true);
      const logoRef = doc(db, "utils", "logos"); // Ensure your path is correct
      const logoSnap = await getDoc(logoRef);

      if (logoSnap.exists()) {
        setLogo(logoSnap.data().desktop); // Use data() as a function
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLogoLoad(false);
    }
  };

  useEffect(() => {
    fetchlogo();
  }, []);

  const handleSubmitChangePassword = async () => {
    if (newPassword !== confirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Regex for special characters
    if (
      !newPassword ||
      newPassword.length < 6 ||
      !specialCharRegex.test(newPassword)
    ) {
      alert(
        "Password must be at least 6 characters long and contain at least one special character."
      );
      return;
    }

    try {
      // For master role (existing code)
      if (role === "master") {
        const masterCollection = collection(db, "masters");
        const querySnapshot = await getDocs(masterCollection);
        const masterDoc = querySnapshot.docs.find(
          (doc) =>
            doc.data().username === username &&
            doc.data().password === oldPassword
        );

        if (masterDoc) {
          const masterDocRef = doc(db, "masters", masterDoc.id);
          await updateDoc(masterDocRef, { password: newPassword });

          alert("Password updated successfully.");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setShowChangePasswordModal(false);
        } else {
          alert("Incorrect old password.");
        }
      }

      // For super role (new logic)
      if (role === "super") {
        const superCollection = collection(db, "super");
        const querySnapshot = await getDocs(superCollection);
        const superDoc = querySnapshot.docs.find(
          (doc) =>
            doc.data().username === username &&
            doc.data().password === oldPassword
        );

        if (superDoc) {
          const superDocRef = doc(db, "super", superDoc.id);
          await updateDoc(superDocRef, { password: newPassword });

          alert("Password updated successfully.");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setShowChangePasswordModal(false);
        } else {
          alert("Incorrect old password.");
        }
      }

      if (role === "god") {
        const godCollection = collection(db, "god");
        const querySnapshot = await getDocs(godCollection);
        const godDoc = querySnapshot.docs.find(
          (doc) =>
            doc.data().username === username &&
            doc.data().password === oldPassword
        );

        if (godDoc) {
          const godDocRef = doc(db, "god", godDoc.id);
          await updateDoc(godDocRef, { password: newPassword });

          alert("Password updated successfully.");
          setOldPassword("");
          setNewPassword("");
          setConfirmPassword("");
          setShowChangePasswordModal(false);
        } else {
          alert("Incorrect old password.");
        }
      }
    } catch (error) {
      console.error("Error updating password:", error);
      alert("Failed to update password.");
    }
  };

  return (
    <div className="lg:hidden z-10 relative">
      {/* Hamburger icon */}
      <div className="flex items-center justify-between bg-[#242731] p-4">
        <button onClick={toggleSidebar} className="text-white">
          <span className="text-3xl">&#9776;</span> {/* Hamburger Icon */}
        </button>
        {logoload ? (
          <div className="w-16 h-16 md:w-20 md:h-8 object-contain"></div> // Show spinner while loading
        ) : (
          <img
            src={logo}
            alt="icon"
            className="w-24 h-16 md:w-20 md:h-8 object-contain"
          />
        )}
        <div className="text-xs">
        <p className="text-white">{username}</p>
        {!(role == "god")&& (
              <div className="flex">
                <p
                  className="text-white text-sm cursor-pointer"
                >
                  {" "}
                  Bal: <span className="text-lg font-semibold">{role === "super" ? superBal : balance}</span>
                </p>
              </div>
            )}
            </div>
      </div>

      {/* Sidebar (hidden by default) */}
      <div
        className={`fixed top-0 left-0 w-64 h-full bg-[#242731] transform ${
          showSidebar ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out`}
      >
        <div className="flex flex-col p-4">
          <div className="flex justify-between items-center">
            {logoload ? (
              <div className="md:w-20 md:h-8 object-contain"></div> // Show spinner while loading
            ) : (
              <img
                src={logo}
                alt="icon"
                className="w-28 h-16 md:w-20 md:h-8 object-contain"
              />
            )}

            <button onClick={toggleSidebar} className="text-white text-4xl">
              &times;
            </button>
          </div>

          <div className="text-white mt-4">
            <p className="font-bold pb-2 text-lg">{username}</p>
            {!(role == "god") && (
              <div className="flex">
                <p
                  className="text-white text-sm cursor-pointer"
                >
                  {" "}
                  Bal: <span className="text-xl font-bold">{role === "super" ? superBal : balance}</span>
                </p>
              </div>
            )}
          </div>

          {/* Menu items */}
          <nav className="mt-6">
            <div className="flex space-x-2 md:space-x-5 flex-wrap">
              {role === "god" && (
                <div className="mb-1 space-x-3">
                  <button
                    className="ml-[12px] font-bold w-full text-left text-white py-2 hover:bg-gray-700 rounded"
                    onClick={() => handleNavigate("/godHome")}
                  >
                    Creation
                  </button>
                  <button
                    className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                    onClick={() => handleNavigate("/god/listSuper")}
                  >
                    List of all Super
                  </button>
                  <button
                    className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                    onClick={() => handleNavigate("/godMasterList")}
                  >
                    List of all Masters
                  </button>
                  <button
                    className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                    onClick={() => handleNavigate("/godUserList")}
                  >
                    List Of all Users
                  </button>
                </div>
              )}

              {role === "god" && (
                <div
                  className="relative font-bold cursor-pointer w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Client Request &#9660;
                  {showDropdown && (
                    <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10">
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() =>
                          handleNavigate("/god/DE", {
                            state: { heading: "Deposit" },
                          })
                        }
                      >
                        Deposit
                      </div>
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() =>
                          handleNavigate("/god/WE", {
                            state: { heading: "Withdraw" },
                          })
                        }
                      >
                        Withdraw
                      </div>
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() =>
                          handleNavigate("/god/totalDW", {
                            state: { heading: "TotalDW" },
                          })
                        }
                      >
                        TotalDW
                      </div>
                      {/* {role!=='master' && <div>
                    <div className="px-4 py-2 text-gray-300 hover:bg-gray-600" onClick={() => handleNavigate("/clientReq", { state: { heading: "Super Master DW" } })}>Super Master DW</div>
                    <div className="px-4 py-2 text-gray-300 hover:bg-gray-600" onClick={() => handleNavigate("/clientReq", { state: { heading: "Super DW States" } })}>Super DW States</div>  
                  </div>} */}
                    </div>
                  )}
                </div>
              )}

              {role === "god" && (
                <button
                  className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onClick={() => handleNavigate("/god/Stmt")}
                >
                  Account's Statement
                </button>
              )}

              {role == "god" && (
                <button
                  className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onClick={() => handleNavigate("/addWhole")}
                >
                  Add Utils
                </button>
              )}

              {role === "super" && (
                <>
                  <button
                    className="ml-[10px] font-bold w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                    onClick={() => handleNavigate("/listUsers")}
                  >
                    List of Users
                  </button>
                  {/* <button className='text-xs font-semibold text-gray-300 ' onClick={() => handleNavigate('/home')}>List Of Master</button> */}
                  <button
                    className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                    onClick={() => handleNavigate("/masterLists")}
                  >
                    List Of Master
                  </button>
                </>
              )}

              {role === "super" && (
                <div
                  className="relative font-bold cursor-pointer w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Client Request &#9660;
                  {showDropdown && (
                    <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10">
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() =>
                          handleNavigate("/clientDE", {
                            state: { heading: "Deposit" },
                          })
                        }
                      >
                        Deposit
                      </div>
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() =>
                          handleNavigate("/clientWE", {
                            state: { heading: "Withdraw" },
                          })
                        }
                      >
                        Withdraw
                      </div>
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() =>
                          handleNavigate("/totalDW", {
                            state: { heading: "TotalDW" },
                          })
                        }
                      >
                        TotalDW
                      </div>
                      {/* {role!=='master' && <div>
                    <div className="px-4 py-2 text-gray-300 hover:bg-gray-600" onClick={() => handleNavigate("/clientReq", { state: { heading: "Super Master DW" } })}>Super Master DW</div>
                    <div className="px-4 py-2 text-gray-300 hover:bg-gray-600" onClick={() => handleNavigate("/clientReq", { state: { heading: "Super DW States" } })}>Super DW States</div>  
                  </div>} */}
                    </div>
                  )}
                </div>
              )}

              {role === "super" && (
                <>
                  <button
                    className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                    onClick={() => handleNavigate("/super/accStmt")}
                  >
                    Account's Statement
                  </button>
                </>
              )}

              {role === "master" && (
                <div className="flex space-x-2 md:space-x-5 mb-1">
                  <div
                    className="ml-[10px] font-bold relative cursor-pointer w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                    onMouseEnter={handleMouseEnter3}
                    onMouseLeave={handleMouseLeave3}
                  >
                    Client Info &#9660;
                    {showDropdown3 && (
                      <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10">
                        <div
                          className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                          onClick={() => handleNavigate("/master/listClient")}
                        >
                          List Of Clients
                        </div>
                        <div
                          className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                          onClick={() => handleNavigate("/curr")}
                        >
                          Client Position
                        </div>
                        <div
                          className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                          onClick={() => handleNavigate("/curr")}
                        >
                          KYC Details
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}


              {role === "master" && (
                <div
                  className="relative cursor-pointer font-bold w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                >
                  Client Request.
                  {showDropdown && (
                    <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10">
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() =>
                          handleNavigate("/DM", {
                            state: { heading: "Deposit" },
                          })
                        }
                      >
                        Deposit
                      </div>
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() =>
                          handleNavigate("/WM", {
                            state: { heading: "Withdraw" },
                          })
                        }
                      >
                        Withdraw
                      </div>
                    </div>
                  )}
                </div>
              )}

              {role === "master" && (
                <button
                  className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onClick={() => handleNavigate("/master/accStmt")}
                >
                  Account's Statement
                </button>
              )}

              {role == "master" && (
                <button
                  className="w-full font-bold text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onClick={() => handleNavigate("/paymentGate")}
                >
                  Payment Gateway
                </button>
              )}

              {/* ProfitLoss Group */}
              <div
                className="relative font-bold cursor-pointer w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                onMouseEnter={handleMouseEnter1}
                onMouseLeave={handleMouseLeave1}
              >
                Profit Loss Group  &#9660;
                {showDropdown1 && (
                  <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10 ">
                    <div
                      className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                      onClick={() => handleNavigate("/pl")}
                    >
                      P/L
                    </div>
                    <div
                      className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                      onClick={() => handleNavigate("/plc")}
                    >
                      P/L(Client)
                    </div>
                  </div>
                )}
              </div>

              {role === "master" && (
                <div
                  className="relative font-bold cursor-pointer w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onMouseEnter={handleMouseEnter4}
                  onMouseLeave={handleMouseLeave4}
                >
                  Activity &#9660;
                  {showDropdown4 && (
                    <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10 ">
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() => handleNavigate("/curr")}
                      >
                        Clients Activity
                      </div>
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() => handleNavigate("/curr")}
                      >
                        Clients Activity Monitor
                      </div>
                    </div>
                  )}
                </div>
              )}

              {/* Analysis bets.. some part of god */}
              <div
                className="relative font-bold cursor-pointer w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                onMouseEnter={handleMouseEnter2}
                onMouseLeave={handleMouseLeave2}
              >
                Analysis/Bets &#9660;
                {showDropdown2 && (
                  <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10">
                    {role == "god" && (
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() => handleNavigate("/god/setbets")}
                      >
                        Set Bets
                      </div>
                    )}
                    <div
                      className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                      onClick={() => handleNavigate("/ana")}
                    >
                      Analysis of Running Market
                    </div>
                    <div
                      className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                      onClick={() => handleNavigate("/curr")}
                    >
                      Current Bets
                    </div>
                    <div
                      className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                      onClick={() => handleNavigate("/allC")}
                    >
                      All Current Bets
                    </div>
                  </div>
                )}
              </div>

              {role === "master" && (
                <div
                  className="relative font-bold cursor-pointer w-full text-left  text-white py-2 hover:bg-gray-700 rounded"
                  onMouseEnter={handleMouseEnter5}
                  onMouseLeave={handleMouseLeave5}
                >
                  More &#9660;
                  {showDropdown5 && (
                    <div className="absolute left-0 mt-1 w-48 bg-gray-700 shadow-lg z-10 ">
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() => handleNavigate("/modeOfPay")}
                      >
                        Mode of Payment
                      </div>
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() => handleNavigate("/plc")}
                      >
                        Payment Gateway Position
                      </div>
                      <div
                        className="px-4 py-2 text-gray-300 hover:bg-gray-600"
                        onClick={() => handleNavigate("/plc")}
                      >
                        Service Number
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </nav>

          {/* Profile dropdown */}
          <div className="mt-6">
            <button
              className="w-full text-left text-white p-2 bg-gray-900 rounded"
              onClick={() => setShowProfileDropdown(!showProfileDropdown)}
            >
              Profile
            </button>
            {showProfileDropdown && (
              <div className="mt-2 bg-gray-800 p-4 rounded-md">
                <button
                  className="text-left text-sm text-gray-300 hover:bg-gray-600 py-2 w-full"
                  onClick={handleChangePasswordClick}
                >
                  Change Password
                </button>
                <button
                  className="text-left text-sm text-gray-300 hover:bg-gray-600 py-2 w-full"
                  onClick={handleLogout}
                >
                  Logout
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {showChangePasswordModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4 text-center">
              Change Password
            </h2>
            <div className="mb-4">
              <label className="block mb-2">Enter Old Password</label>
              <input
                type="password"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Enter New Password</label>
              <input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Confirm Password</label>
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>

            <div className="flex justify-end space-x-2">
              <button
                className="bg-green-600 text-white px-4 py-2 rounded-md"
                onClick={handleSubmitChangePassword}
              >
                Submit
              </button>

              <button
                onClick={handleCloseChangePasswordModal}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SmallScreenTopBar;
