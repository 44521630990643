import React, { useState, useEffect } from 'react';
import { db } from '../../firebase'; // Import the Firestore database
import { collection, addDoc, getDocs, query, where } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';


const AddUser = ( ) => {  // Get the master username as a prop
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [users, setUsers] = useState([]);

  const location = useLocation();
  const masterUsername = location.state?.username; // This is the master username passed from TopBar


  const handleAdd = () => {
    setIsModalOpen(true);
    console.log(masterUsername)
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const createdAt = new Date().toISOString(); // Get the current timestamp
    const exposureLimit = "500,000.00"; // Default exposure limit

    try {
      // Add user to Firestore with the necessary fields
      const docRef = await addDoc(collection(db, 'users'), {
        username,
        password,
        phoneNumber,
        amount: 0, // Default amount value
        exposureLimit,
        createdAt, // Store creation time
        createdBy: masterUsername, // Store the master username
        status: 'ACTIVE' // Set the default status to ACTIVE
      });
      console.log('Document written with ID: ', docRef.id);
      setIsModalOpen(false);
      fetchUsers(); // Fetch users after adding a new one
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  // const fetchUsers = async () => {
  //   const querySnapshot = await getDocs(collection(db, 'users'));
  //   const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //   setUsers(usersList);
  // };

  const fetchUsers = async () => {
    if (!masterUsername) return; // Make sure masterUsername is available
  
    // Create a query to filter users created by the current logged-in manager
    const q = query(collection(db, 'users'), where('createdBy', '==', masterUsername));
    const querySnapshot = await getDocs(q);
    const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setUsers(usersList);
  };

  useEffect(() => {
    fetchUsers(); // Initial fetch when the component mounts

    const intervalId = setInterval(() => {
      fetchUsers(); // Fetch users every 15 seconds
    }, 15000);

    return () => clearInterval(intervalId); // Clean up the interval on component unmount
  }, []);

  return (
    <div className='bg-black h-screen p-4'>
      <button onClick={handleAdd} className='bg-blue-600 p-2 text-white rounded-lg'>
        Add User
      </button>

      {/* Modal */}
      {isModalOpen && (
        <div className='fixed inset-0 flex items-center justify-center bg-black bg-opacity-50'>
          <div className='bg-gray-800 p-6 rounded-lg shadow-lg'>
            <h2 className='text-white text-xl mb-4'>Add New User</h2>
            <form onSubmit={handleSubmit}>
              <div className='mb-4'>
                <label className='block text-white mb-2'>Username</label>
                <input
                  type='text'
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                  className='w-full p-2 rounded-lg bg-gray-700 text-white'
                  required
                />
              </div>
              <div className='mb-4'>
                <label className='block text-white mb-2'>Password</label>
                <input
                  type='password'
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className='w-full p-2 rounded-lg bg-gray-700 text-white'
                  required
                />
              </div>
              <div className='mb-4'>
                <label className='block text-white mb-2'>Phone Number</label>
                <input
                  type='tel'
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  className='w-full p-2 rounded-lg bg-gray-700 text-white'
                  required
                />
              </div>
              <div className='mb-4'>
                <label className='block text-white mb-2'>Exposure Limit</label>
                <input
                  type='text'
                  value="500,000.00"
                  readOnly
                  className='w-full p-2 rounded-lg bg-gray-700 text-white'
                />
              </div>
              <div className='mb-4'>
                <label className='block text-white mb-2'>Amount</label>
                <input
                  type='number'
                  value={0}
                  readOnly
                  className='w-full p-2 rounded-lg bg-gray-700 text-white'
                />
              </div>
              <div className='flex justify-end space-x-4'>
                <button
                  type='submit'
                  className='bg-green-600 text-white px-4 py-2 rounded-lg'
                >
                  Submit
                </button>
                <button
                  type='button'
                  onClick={() => setIsModalOpen(false)}
                  className='bg-red-600 text-white px-4 py-2 rounded-lg'
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* Display Users */}
      <div className='mt-10 px-4'>
  <h2 className='text-white text-xl mb-4'>User List</h2>
  <table className='w-full text-white'>
    <thead>
      <tr className='bg-gray-800'>
        <th className='p-2 text-left'>UserName</th>
        <th className='p-2 text-left'>Exposure Limit</th>
        <th className='p-2 text-left'>Registered On</th>
        <th className='p-2 text-left'>PhoneNo</th>
      </tr>
    </thead>
    <tbody>
      {users.map((user, index) => (
        <tr key={index} className='bg-black border-b border-gray-700'>
          <td className='p-2'>{user.username}</td>
          <td className='p-2'>{user.exposureLimit}</td>
          <td className='p-2'>{new Date(user.createdAt).toLocaleString()}</td>
          <td className='p-2'>{user.phoneNumber}</td>
        </tr>
      ))}
    </tbody>
  </table>
</div>
    </div>
  );
};

export default AddUser;