import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import folder from "../../images/folder.png";

const PL = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [exchange, setExchange] = useState("ALL");
  const [eventType, setEventType] = useState("All");

  const exchangeOptions = ["ALL", "AUS", "BMK", "CAS", "CF", "Dabba", "EXG", "EZG", "FAN", "GAP", "ICL", "ION", "LTRY", "MFB", "MFN", "QTC", "SBB", "SNC"];
  const eventTypeOptions = ["All", "Blackjack VIP 5", "Irish Wishes", "Thunder Screech", "1 Day Teen Patti", "1 Million Fortunes Megaways", "1 Seat Single Deck Blackjack", "1 Seat Single Deck Blackjack Elite Edition", "10 001 Nights", "10 Swords", "100 Joker Staxx", "100 Lucky Sevens", "10001 Nights Megaways", "101 Candies", "108 Heroes Multiplier Fortunes", "10X Rewind"];
  
  const handleLoad = () =>{
    window.location.reload();
  }

  return (
    <div className="flex flex-col bg-black text-white min-h-screen p-4">
      <h1 className="text-2xl font-semibold mb-8 text-center mt-10">Profit/Loss</h1>
      <div className="flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 mb-8 justify-center">
        <div className="flex flex-col">
          <label className="mb-2 text-lg">Start Date</label>
          <DatePicker
            selected={startDate}
            onChange={date => setStartDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy, HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full md:w-56"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 text-lg">End Date</label>
          <DatePicker
            selected={endDate}
            onChange={date => setEndDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy, HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full md:w-56"
          />
        </div>
        <div className="flex flex-col">
          <label className="mb-2 text-lg">Exchange</label>
          <select
            value={exchange}
            onChange={(e) => setExchange(e.target.value)}
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full md:w-56"
          >
            {exchangeOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col">
          <label className="mb-2 text-lg">Event Type</label>
          <select
            value={eventType}
            onChange={(e) => setEventType(e.target.value)}
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full md:w-56"
          >
            {eventTypeOptions.map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="flex flex-col justify-end">
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md w-full md:w-auto" onClick={handleLoad}>Load Report</button>
        </div>
      </div>
      <div className="mt-16 flex justify-center">
        <img
          src={folder}
          alt="Data"
          className="w-1/2 md:w-[20%] h-auto"
        />
      </div>
    </div>
  );
};

export default PL;
