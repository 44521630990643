import React, { useState } from 'react';

const DefaultRegModal = ({ superPassword, onSubmit, onClose }) => {
  const [toggleState, setToggleState] = useState(false); // Toggle state (on/off)
  const [enteredPassword, setEnteredPassword] = useState(''); // Input for the password
  const [error, setError] = useState(null);

  // Toggle handler for on/off switch
  const handleToggle = () => {
    setToggleState(!toggleState);  // Toggle between true/false
  };

  // Function to handle the form submission
  const handleSubmit = () => {
    if (enteredPassword === superPassword) {
      // Password is correct; call the onSubmit prop
      onSubmit({ toggleState });
    } else {
      setError('Incorrect god password. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Default Reg Status</h2>

        {/* Toggle for On/Off */}
        <div className="flex justify-between mb-4">
          <span className="text-white">Status: </span>
          <button
            className={`px-4 py-2 rounded-lg ${toggleState ? 'bg-green-500' : 'bg-red-500'} text-white`}
            onClick={handleToggle}
          >
            {toggleState ? 'On' : 'Off'}
          </button>
        </div>

        {/* Password Input */}
        <div className="mb-6">
          <input
            type="password"
            placeholder="God Password"
            className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
          />
        </div>

        {/* Error Message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel Buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DefaultRegModal;
