import React from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router

const AcctStmtAll = () => {
  const navigate = useNavigate(); // Initialize useNavigate for navigation

  // Function to route to Super Account Statement
  const handleSuperAccountStatement = () => {
    navigate('/god/accStmt'); // Navigate to Super Account Statement route
  };

  // Function to route to Master Account Statement
  const handleMasterAccountStatement = () => {
    navigate('/god/MasterAccStmt'); // Navigate to Master Account Statement route
  };

  return (
    <div className="bg-black min-h-screen p-4">
      <h1 className="text-2xl text-center text-white mb-6 font-semibold">Account Statements</h1>

      {/* Add buttons for navigating to account statements */}
      <div className="flex justify-center space-x-10 mb-6 mt-10">
        {/* Button for Super Account Statement */}
        <button
          onClick={handleSuperAccountStatement}
          className="bg-blue-600 hover:bg-blue-700 text-white p-3 rounded-lg"
        >
          View Super's Account Statement
        </button>

        {/* Button for Master Account Statement */}
        <button
          onClick={handleMasterAccountStatement}
          className="bg-green-600 hover:bg-green-700 text-white p-3 rounded-lg"
        >
          View Master's Account Statement
        </button>
      </div>
    </div>
  );
};

export default AcctStmtAll;
