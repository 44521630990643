import React from 'react';
import { useNavigate } from 'react-router-dom';


const ClosedPage = () => {

    const navigate= useNavigate()

    const handleLogin = () =>{
        navigate("/")
    }

  return (
    <div className="flex items-center justify-center h-screen bg-gray-900">
      <div className="bg-gray-800 p-16 flex flex-col justify-center rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl text-white mb-6 text-center">Account Closed</h2>
        <p className="text-white text-center mb-4">
          Your account has been closed. Please contact upper officials for more information.
        </p>
        <button onClick={handleLogin} 
        className='bg-gray-400 p-2 rounded-lg mt-3 font-semibold hover:bg-gray-500'>Back to login</button>
      </div>
    </div>
  );
};

export default ClosedPage;
