import React, { useState } from 'react';
import SuperMasterForm from './SuperMasterForm';

const GodPanel = () => {
  const [isUserFormOpen, setIsUserFormOpen] = useState(false);

  const handleOpenUserForm = () => {
    setIsUserFormOpen(true);
  };

  const handleCloseUserForm = () => {
    setIsUserFormOpen(false);
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-900">
      <div className="bg-gray-800 p-8 md:p-16 rounded-lg shadow-lg max-w-xl w-full">
        <h2 className="text-2xl text-white mb-6 text-center font-semibold">God Panel</h2>
        <div className="flex flex-col space-y-4">
          <button
            onClick={handleOpenUserForm}
            className="bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700"
          >
            Create User (Super/Master)
          </button>

          {/* Modal for SuperMasterForm */}
          {isUserFormOpen && (
            <SuperMasterForm onClose={handleCloseUserForm} />
          )}
        </div>
      </div>
    </div>
  );
};

export default GodPanel;
