import React from "react";

const UserInfoModal = ({ isOpen, onClose, user }) => {
  if (!isOpen || !user) {
    return null; // If modal is not open or user is not selected, do not render the modal
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
        <div className="text-center mb-4">
          <h2 className="text-green-500 text-xl font-bold">User Info</h2>{" "}
          {/* Heading similar to your provided image */}
        </div>
        <div className="text-white space-y-2">
          <div className="flex justify-between">
            <span className="font-semibold">Master Name:</span>
            <span>{user.createdBy || "N/A"}</span> {/* Master Name */}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Username:</span>
            <span>{user.username || "N/A"}</span> {/* User Name */}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Phone Number:</span>
            <span>{user.phoneNumber || "N/A"}</span> {/* Phone Number */}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Amount:</span>
            <span>{user.amount || "0"}</span> {/* Amount */}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Exposure Limit:</span>
            <span>{user.exposureLimit || "0"}</span> {/* Exposure Limit */}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Exposure:</span>
            <span>{user.exposure || "0"}</span> {/* Exposure */}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Status:</span>
            <span>{user.status || "N/A"}</span> {/* Status */}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Bet:</span>
            <span>{user.bet || "N/A"}</span> {/* Bet Status */}
          </div>
          <div className="flex justify-between">
            <span className="font-semibold">Created At:</span>
            <span>{new Date(user.createdAt).toLocaleString()}</span>{" "}
            {/* Created At */}
          </div>
        </div>
        <div className="flex justify-center mt-6">
          <button
            onClick={onClose}
            className="bg-red-600 text-white px-4 py-2 rounded-lg hover:bg-red-700"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserInfoModal;
