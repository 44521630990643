import React from "react";

const ActionModal = ({ master, onClose, onGeneratePin }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-80">
        <div className="flex justify-between items-center">
          <h2 className="text-white text-lg mb-4">
            Actions for {master.username}
          </h2>
          <button
            onClick={onClose}
            className="text-white bg-red-500 p-1 rounded"
          >
            X
          </button>
        </div>

        <div className="space-y-2">
          {/* <button className="block w-full bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600">
            Deposit
          </button>
          <button className="block w-full bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600">
            Withdraw
          </button>
          <button className="block w-full bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600">
            Reset Password
          </button> */}

          {/* Pin Number Button with onClick to generate the PIN */}
          <button
            className="block w-full bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600"
            onClick={() => onGeneratePin(master.id)} // Trigger PIN generation
          >
            Pin Number
          </button>

          {/* <button className="block w-full bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600">
            By Default Registered
          </button>
          <button className="block w-full bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600">
            Super Master Navigation
          </button> */}
        </div>

        <div className="flex justify-end mt-4">
          <button
            onClick={onClose}
            className="bg-red-600 px-4 py-2 rounded-lg text-white"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default ActionModal;
