import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase'; // Adjust the path as needed
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ChangePassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);  // Toggle visibility for new password
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);  // Toggle visibility for confirm password
  const navigate = useNavigate();
  const location = useLocation();

  const { username, role } = location.state || {};  // Retrieve username and role passed from the login page

  const handleChangePassword = async (e) => {
    e.preventDefault();
  
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Regex for special characters
    if (!newPassword || newPassword.length < 6 || !specialCharRegex.test(newPassword)) {
      toast.error('Password must be at least 6 characters long and contain at least one special character.');
      return;
    }

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

  
    try {
      // Determine the collection based on the role
      let collectionName = '';
      if (role === 'god') {
        collectionName = 'god';
      } else if (role === 'super') {
        collectionName = 'super';
      } else if (role === 'master') {
        collectionName = 'masters';
      }
  
      // Query to get the document ID based on username
      const userCollection = collection(db, collectionName);
      const q = query(userCollection, where("username", "==", username));
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0]; // Assuming usernames are unique
        const docId = userDoc.id;  // Get the document ID
  
        // Update the password and mark `passwordChanged` as true
        const userRef = doc(db, collectionName, docId); // Use doc ID
        await updateDoc(userRef, {
          password: newPassword,
          passwordChanged: true
        });

        toast.success("Password Change Successfully! Pls re-login with changed password")
  
        // Redirect based on role
        if (role === 'god') {
          navigate('/');
        } else if (role === 'super') {
          navigate('/');
        } else if (role === 'master') {
          navigate('/');
        }
  
      } else {
        setError('User not found.');
      }
  
    } catch (error) {
      console.error('Error updating password:', error);
      setError('Failed to change the password. Please try again.');
    }
  };

  return (
    <div className="flex items-center justify-center h-screen bg-gray-900">
      <div className="bg-gray-800 p-16 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl text-white mb-6 text-center">Change Password</h2>
        <form onSubmit={handleChangePassword}>
          <div className="mb-4">
            <label className="block text-white mb-2">New Password</label>
            <div className="relative">
              <input
                type={showNewPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                className="w-full p-2 rounded-lg bg-gray-700 text-white"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-2 flex items-center text-gray-400"
                onClick={() => setShowNewPassword((prev) => !prev)}  // Toggle visibility
              >
                {showNewPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>

          <div className="mb-4">
            <label className="block text-white mb-2">Confirm Password</label>
            <div className="relative">
              <input
                type={showConfirmPassword ? 'text' : 'password'}  // Toggle between 'text' and 'password'
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="w-full p-2 rounded-lg bg-gray-700 text-white"
                required
              />
              <button
                type="button"
                className="absolute inset-y-0 right-2 flex items-center text-gray-400"
                onClick={() => setShowConfirmPassword((prev) => !prev)}  // Toggle visibility
              >
                {showConfirmPassword ? 'Hide' : 'Show'}
              </button>
            </div>
          </div>

          {error && <p className="text-red-500 mb-4">{error}</p>}
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-lg"
            >
              Change Password
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ChangePassword;
