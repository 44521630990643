import React, { useState } from "react";
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from "firebase/firestore";
import { db } from "../../../firebase";

const CreditAmtModal = ({ masterId, superUsername, superPassword, onClose }) => {
  const [creditAmt, setCreditAmt] = useState("");
  const [enteredPassword, setEnteredPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);

 

  // Toggle password visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Handle form submission and update Firestore
  // const handleCreditAmtUpdate = async () => {
  //   if (enteredPassword !== superPassword) {
  //     setError("Incorrect super password.");
  //     return;
  //   }

  //   try {
  //     const masterRef = doc(db, "masters", masterId);
  //     await updateDoc(masterRef, { creditAmt }); // Update creditAmt in Firestore
  //     alert("Credit Amount updated successfully.");
  //     onClose();
  //   } catch (error) {
  //     console.error("Error updating Credit Amount:", error);
  //     alert("Failed to update Credit Amount. Please try again.");
  //   }
  // };


const handleCreditAmtUpdate = async () => {
  if (enteredPassword !== superPassword) {
    setError("Incorrect super password.");
    return;
  }

  try {
    const masterRef = doc(db, "masters", masterId);

    
    const superQuery = query(collection(db, "super"), where("username", "==", superUsername));
    const superSnapshot = await getDocs(superQuery);

    if (superSnapshot.empty) {
      setError("Super not found.");
      return;
    }

    // Assuming you are only fetching one superuser
    const superDoc = superSnapshot.docs[0];
    const superData = superDoc.data();

    const masterDoc = await getDoc(masterRef);
    if (masterDoc.exists()) {
      const masterData = masterDoc.data();

      const currentMasterBalance = parseFloat(masterData.balance) || 0;
      const previousCreditAmt = parseFloat(masterData.creditAmt) || 0;
      const newCreditAmt = parseFloat(creditAmt) || 0;
      const superBalance = parseFloat(superData.balance) || 0;

      // Add the previous credit amount back to the super balance
      const restoredSuperBalance = superBalance + previousCreditAmt;

      // Check if the restored super balance has enough to subtract the new credit amount
      if (restoredSuperBalance < newCreditAmt) {
        setError("Insufficient balance in the super account.");
        return;
      }

      // Calculate updated master balance: remove the old credit amount, add the new one
      const updatedMasterBalance = currentMasterBalance - previousCreditAmt + newCreditAmt;

      // Calculate updated super balance: after adding back the previous credit, subtract the new one
      const updatedSuperBalance = restoredSuperBalance - newCreditAmt;

      // Update master balance and credit amount
      await updateDoc(masterRef, {
        creditAmt: newCreditAmt,
        balance: updatedMasterBalance,
      });

      // Update super balance
      await updateDoc(superDoc.ref, {
        balance: updatedSuperBalance,
      });

      alert("Credit Amount and Balance updated successfully.");
      onClose();
    } else {
      setError("Master not found.");
    }
  } catch (error) {
    console.error("Error updating Credit Amount and Balance:", error);
    alert("Failed to update Credit Amount and Balance. Please try again.");
  }
};

  
  


  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Update Credit Amount</h2>

        {/* Input field for credit amount */}
        <input
          type="number"
          placeholder="Enter Credit Amount"
          value={creditAmt}
          onChange={(e) => setCreditAmt(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password input field */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="Super Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? "Hide" : "Show"} Password
        </button>

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleCreditAmtUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreditAmtModal;
