import React, { useState, useEffect } from 'react';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AddWhole = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [desktopImage, setDesktopImage] = useState(null);
  const [mobileImage, setMobileImage] = useState(null);
  const [desktopUrl, setDesktopUrl] = useState(null);
  const [mobileUrl, setMobileUrl] = useState(null);
  const [desktopLink, setDesktopLink] = useState(''); // To store desktop URL
  const [mobileLink, setMobileLink] = useState('');   // To store mobile URL
  const [useLink, setUseLink] = useState(false);      // To toggle between image upload and URL entry
  const [loading, setLoading] = useState(false);      // Loading state
  const storage = getStorage();
  const db = getFirestore(); // Initialize Firestore

  // Open Modal
  const openModal = () => setIsModalOpen(true);

  // Close Modal
  const closeModal = () => setIsModalOpen(false);

  // Handle Image Selection
  const handleImageChange = (e, setType) => {
    const file = e.target.files[0];
    setType(file);
  };

  // Fetch uploaded images from Firestore on page load
  useEffect(() => {
    const fetchImages = async () => {
      const docRef = doc(db, "utils", "logos");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setDesktopUrl(data.desktop);
        setMobileUrl(data.mobile);
      }
    };

    fetchImages();
  }, [db]);

  // Upload Images to Firebase
  const handleUpload = async () => {
    setLoading(true); // Start loading spinner

    try {
      let desktopImageUrl = desktopLink; // Use URL if provided
      let mobileImageUrl = mobileLink;

      // Upload desktop image if no link was provided
      if (!desktopLink && desktopImage) {
        const desktopRef = ref(storage, 'utils/logos/desktop');
        await uploadBytes(desktopRef, desktopImage);
        desktopImageUrl = await getDownloadURL(desktopRef);
        setDesktopUrl(desktopImageUrl);
      }

      // Upload mobile image if no link was provided
      if (!mobileLink && mobileImage) {
        const mobileRef = ref(storage, 'utils/logos/mobile');
        await uploadBytes(mobileRef, mobileImage);
        mobileImageUrl = await getDownloadURL(mobileRef);
        setMobileUrl(mobileImageUrl);
      }

      // Save image URLs to Firestore
      const docRef = doc(db, "utils", "logos");
      await setDoc(docRef, {
        desktop: desktopImageUrl,
        mobile: mobileImageUrl
      }, { merge: true });

      toast.success("Images uploaded");
      closeModal();
    } catch (error) {
      console.error("Error uploading images:", error);
      toast.error("Failed to upload images.");
    }

    setLoading(false); // Stop loading spinner
  };

  return (
    <div className="flex flex-col items-center h-screen bg-gray-900">
      <div className="bg-gray-800 rounded-lg shadow-lg w-screen p-6">
        <h1 className="text-white text-center pt-10 text-2xl mb-6">Change Utils in all pages</h1>
        <button
          className="bg-blue-600 hover:bg-blue-800 text-white px-10 py-4 rounded-md ml-5"
          onClick={openModal}
        >
          Upload Logos
        </button>

        {/* Display uploaded images */}
        <div className="mt-5 flex items-center space-x-10">
          {desktopUrl && (
            <div className="mb-6">
              <h3 className="text-white mb-5">Uploaded Desktop Image</h3>
              <img src={desktopUrl} alt="Desktop Logo" className="w-32 h-auto" />
            </div>
          )}

          {mobileUrl && (
            <div className="mb-6">
              <h3 className="text-white mb-5">Uploaded Mobile Image</h3>
              <img src={mobileUrl} alt="Mobile Logo" className="w-32 h-auto" />
            </div>
          )}
        </div>
      </div>

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-black p-10 rounded-lg shadow-lg relative w-[400px]">
            <h2 className="text-xl text-white mb-4">Upload or Enter Image Links</h2>

            <div className="mb-8">
              <label className="block text-white mb-2">Upload Desktop Image or Enter Link</label>
              <div className="flex items-center mb-2">
                <input
                  type="checkbox"
                  checked={useLink}
                  onChange={(e) => setUseLink(e.target.checked)}
                  className="mr-2"
                />
                <span className="text-white">Enter URL instead of uploading</span>
              </div>
              {useLink ? (
                <input
                  className="bg-slate-400 p-1 rounded-md text-slate-900 font-semibold w-full mb-4"
                  type="text"
                  placeholder="Enter Desktop Image URL"
                  value={desktopLink}
                  onChange={(e) => setDesktopLink(e.target.value)}
                />
              ) : (
                <input
                  className="bg-slate-400 rounded-md text-black font-semibold w-full"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, setDesktopImage)}
                />
              )}
            </div>

            <div className="mb-4">
              <label className="block mb-2 text-white">Upload Mobile Image or Enter Link</label>
              {useLink ? (
                <input
                  className="bg-slate-400 p-1 rounded-md text-black font-semibold w-full"
                  type="text"
                  placeholder="Enter Mobile Image URL"
                  value={mobileLink}
                  onChange={(e) => setMobileLink(e.target.value)}
                />
              ) : (
                <input
                  className="bg-slate-400 rounded-md text-slate-900 font-semibold w-full"
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleImageChange(e, setMobileImage)}
                />
              )}
            </div>

            <div className="flex justify-between mt-4">
              <button
                className="bg-green-600 text-white px-4 py-2 rounded-md"
                onClick={handleUpload}
              >
                Submit
              </button>
              <button
                className="bg-red-600 text-white px-4 py-2 rounded-md"
                onClick={closeModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Loading spinner */}
      {loading && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-white"></div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default AddWhole;
