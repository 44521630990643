import React, { useState, useEffect } from 'react';

const AutoReloadToggle = ({time}) => {
  // Initialize state from localStorage or default to false
  const [isToggled, setIsToggled] = useState(() => {
    const saved = localStorage.getItem('autoReloadToggle');
    return saved === 'true' ? true : false;
  });

  const handleToggle = () => {
    const newToggleState = !isToggled;
    setIsToggled(newToggleState);
    localStorage.setItem('autoReloadToggle', newToggleState); // Save to localStorage
  };

  useEffect(() => {
    let intervalId;

    if (isToggled) {
      intervalId = setInterval(() => {
        window.location.reload();
      }, parseInt(time)); // 10 seconds
    } else {
      clearInterval(intervalId);
    }

    // Cleanup interval on component unmount or when toggled off
    return () => clearInterval(intervalId);
  }, [isToggled]);

  return (
    <div className="flex items-center">
      <button
        onClick={handleToggle}
        className={`w-12 h-6 flex items-center rounded-full p-1 duration-300 ease-in-out ${
          isToggled ? 'bg-green-500' : 'bg-gray-300'
        }`}
      >
        <div
          className={`bg-white w-4 h-4 rounded-full shadow-md transform duration-300 ease-in-out ${
            isToggled ? 'translate-x-6' : ''
          }`}
        ></div>
      </button>
      <span className="ml-2 text-white font-medium">Auto Reload</span>
    </div>
  );
};

export default AutoReloadToggle;
