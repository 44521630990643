import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useLocation } from "react-router-dom";

const AcctStmtSuper = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state

  const location = useLocation();

  const username = location.state?.username


  // Function to fetch all transactions (withdraw + deposit)
  const fetchTransactions = async () => {
    try {
      setLoading(true);
      if (!username) {
        console.error("Username is missing.");
        setLoading(false);
        return;
      }

      // Fetch withdraw transactions
      const withdrawRef = collection(db, `accountStmt/super/withdraw`);
      const withdrawQuery = query(
        withdrawRef,
        where("createdBy", "==", username), // Filter by the username
        orderBy("createdAt", "desc")
      );
      const withdrawSnapshot = await getDocs(withdrawQuery);
      const withdrawTransactions = withdrawSnapshot.docs.map((doc) => ({
        id: doc.id,
        type: "withdraw",
        ...doc.data(),
      }));

      // Fetch deposit transactions
      const depositRef = collection(db, `accountStmt/super/deposit`);
      const depositQuery = query(
        depositRef,
        where("createdBy", "==", username), // Filter by the username
        orderBy("createdAt", "desc")
      );
      const depositSnapshot = await getDocs(depositQuery);
      const depositTransactions = depositSnapshot.docs.map((doc) => ({
        id: doc.id,
        type: "deposit",
        ...doc.data(),
      }));

      // Combine both transactions
      const allTransactions = [...withdrawTransactions, ...depositTransactions];

      // Sort all transactions by createdAt in descending order
      const sortedTransactions = allTransactions.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setTransactions(sortedTransactions); // Set the combined, sorted transactions
      setLoading(false); // Set loading to false after data is fetched
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchTransactions(); // Fetch the transactions when component mounts
  }, []);


  const [superBalance, setSuperBalance] = useState(0);

const fetchSuperBalance = async () => {
  try {
    if (!username) {
      console.error("Username is missing.");
      return;
    }

    // Fetch superuser's balance
    const superQuery = query(
      collection(db, "super"), // Assuming "super" is the collection where the superuser data is stored
      where("username", "==", username)
    );

    const superSnapshot = await getDocs(superQuery);
    if (!superSnapshot.empty) {
      const superDoc = superSnapshot.docs[0].data();
      setSuperBalance(parseFloat(superDoc.balance || 0)); // Set the fetched balance
    } else {
      console.error("Superuser not found.");
    }
  } catch (error) {
    console.error("Error fetching super balance:", error);
  }
};

useEffect(() => {
  fetchSuperBalance(); // Fetch super balance when component mounts
  fetchTransactions(); // Fetch the transactions when component mounts
}, []);


  return (
    <div className="bg-black h-screen p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-white text-2xl font-semibold">
          Super Account Statement
        </h1>
        <button className="bg-blue-600 text-white px-4 py-2 rounded-lg">
          Excel
        </button>
      </div>
      {loading ? (
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      ) : (
        <div className="overflow-x-auto max-h-screen">
          <table className="min-w-full text-white border-collapse text-sm">
            {" "}
            {/* Adjust text size here */}
            <thead>
              <tr className="bg-gray-800 text-left">
                <th className="p-3">Trans ID</th>
                <th className="p-3">Master Username</th>
                <th className="p-3">Settled Date</th>
                <th className="p-3 w-1/3">Description</th>{" "}
                {/* Adjust width for description */}
                <th className="p-3">Withdraw</th>
                <th className="p-3">Deposit</th>
                {/* <th className="p-3">Net Balance</th> */}
              </tr>
            </thead>
            <tbody>
              {transactions.length > 0 ? (
                transactions.map((transaction, index) => {
                  const isDebit = transaction.type === "withdraw";
                  const isCredit = transaction.type === "deposit";
                  const balance = transaction.balance || 0;

                  return (
                    <tr
                      key={index}
                      className="bg-black border-b border-gray-700"
                    >
                      <td className="p-3">{transaction.transactionId}</td>
                      <td className="p-3">{transaction.masterName}</td>
                      <td className="p-3">
                        {new Date(transaction.createdAt).toLocaleString()}
                      </td>
                      <td className="p-3 w-1/3">{transaction.description}</td>{" "}
                      {/* Wider description */}
                      <td className="p-3 text-red-500">
                        {isDebit ? transaction.amount.toFixed(2) : "0.00"}
                      </td>
                      <td className="p-3 text-green-500">
                        {isCredit ? transaction.amount.toFixed(2) : "0.00"}
                      </td>
                      {/* <td className="p-3 text-yellow-500 font-bold">
                        {transaction.netBal
                          ? transaction.netBal.toFixed(2)
                          : "0.00"}
                      </td> */}
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="7" className="text-center p-4 text-gray-300">
                    No transactions found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AcctStmtSuper;
