import { doc, getDoc, updateDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../../firebase';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const BetsAdjust = () => {
  const [minBet, setMinBet] = useState("");
  const [maxBet, setMaxBet] = useState("");

  const [urminBet, setUrMinBet] = useState("");
  const [urmaxBet, setUrMaxBet] = useState("");

  // Handle updating Max Bet
  const handleMaxBet = async () => {
    try {
      const docRef = doc(db, "god", "betLimits");
      if(minBet>maxBet){
        toast.error("Max bet must be greater than min bet")
        return;
      }
      await updateDoc(docRef, {
        maxBet: parseFloat(maxBet)
      });
      toast.success("Max Bet updated successfully!");

      // Clear the input and fetch updated data
      setMaxBet("");
      fetchBetLimits(); // Fetch updated values immediately
    } catch (e) {
      console.log(e);
      toast.error("Failed to update Max Bet");
    }
  };

  // Handle updating Min Bet
  const handleMinBet = async () => {
    try {
      const docRef = doc(db, "god", "betLimits");
      if(minBet>maxBet){
        toast.error("Min bet must be less than max bet")
        return;
      }
      await updateDoc(docRef, {
        minBet: parseFloat(minBet)
      });
      toast.success("Min Bet updated successfully!");

      // Clear the input and fetch updated data
      setMinBet("");
      fetchBetLimits(); // Fetch updated values immediately
    } catch (e) {
      console.log(e);
      toast.error("Failed to update Min Bet");
    }
  };

  // Fetch Min and Max Bet values from Firestore
  const fetchBetLimits = async () => {
    try {
      const docRef = doc(db, "god", "betLimits");
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        setMinBet(data.minBet || "");
        setMaxBet(data.maxBet || "");

        setUrMinBet(data.minBet || "");
        setUrMaxBet(data.maxBet || "");
      } else {
        toast.error("No Bets Found");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to get bet limits");
    }
  };

  // Fetch the bet limits when the component mounts
  useEffect(() => {
    fetchBetLimits();
  }, []);

  return (
    <div className="h-screen bg-gray-900 flex flex-col items-center p-5">
      <div>
        <h1 className="text-white font-semibold text-2xl sm:text-3xl text-center">
          Min & Max Bets for Clients
        </h1>
      </div>

      <div className="bg-gray-800 border-4 border-white w-full sm:w-2/3 lg:w-1/2 mt-10 p-8 rounded-lg shadow-xl">
        {/* Min Bet Section */}
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center mb-8">
          <h1 className="text-white font-semibold text-lg sm:text-xl my-2 sm:my-0">
            Min Bet:
          </h1>
          <div className="flex w-full sm:w-auto">
            <input
              type="number"
              value={minBet}
              onChange={(e) => setMinBet(e.target.value)}
              className="h-12 w-full sm:w-auto flex-grow sm:flex-grow-0 rounded-lg font-semibold p-3 border-2 border-gray-500 focus:border-blue-500 outline-none transition"
            />
            <button
              onClick={handleMinBet}
              className="bg-blue-700 hover:bg-blue-800 text-white rounded-lg px-5 py-2 ml-3 transition"
            >
              Submit
            </button>
          </div>
        </div>

        {/* Max Bet Section */}
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <h1 className="text-white font-semibold text-lg sm:text-xl my-2 sm:my-0">
            Max Bet:
          </h1>
          <div className="flex w-full sm:w-auto">
            <input
              type="number"
              value={maxBet}
              onChange={(e) => setMaxBet(e.target.value)}
              className="h-12 w-full sm:w-auto flex-grow sm:flex-grow-0 rounded-lg font-semibold p-3 border-2 border-gray-500 focus:border-blue-500 outline-none transition"
            />
            <button
              onClick={handleMaxBet}
              className="bg-blue-700 hover:bg-blue-800 text-white rounded-lg px-5 py-2 ml-3 transition"
            >
              Submit
            </button>
          </div>
        </div>
      </div>

      {/* Display Assigned Min and Max Bets */}
      <div className="bg-gray-700 border-4 border-white w-full sm:w-2/3 lg:w-1/2 mt-20 p-8 rounded-lg shadow-xl space-y-10">
        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <h1 className='text-white font-semibold text-lg sm:text-xl my-2 sm:my-0'>Assigned Min Bet</h1>
          <p className='text-2xl text-green-400 font-bold'>{urminBet}</p>
        </div>

        <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
          <h1 className='text-white font-semibold text-lg sm:text-xl my-2 sm:my-0'>Assigned Max Bet</h1>
          <p className='text-2xl text-green-400 font-bold'>{urmaxBet}</p>
        </div>
      </div>
    </div>
  );
};

export default BetsAdjust;
