import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, doc, getDoc, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase"; // Adjust the path as per your project structure

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [logo, setLogo] = useState('')
  const [logoload, setLogoLoad] = useState(true)

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    setLoading(true);  // Start loading

  
    // Check if the username and password match the superdemopp or masterdemoop credentials
    if (username === 'superdemopp' && password === 'user@12') {
      navigate('/paymentGate', { state: { role: 'super', username: 'superdemopp' } });
      return;
    }
  
    try {
      // Query for god login
      const godRef = collection(db, "god");
      const qGod = query(godRef, where("username", "==", username));
      const querySnapshotGod = await getDocs(qGod);
  
      if (!querySnapshotGod.empty) {
        querySnapshotGod.forEach((doc) => {
          const godData = doc.data();
          if (godData.password === password) {
            // Handle password change first
            if (!godData.passwordChanged) {
              navigate('/change-password', { state: { role: 'god', username: godData.username } });
            } else {
              navigate('/godHome', { state: { role: 'god', username: godData.username } });
            }
            window.location.reload();
            return;
          }
        });
      }
  
      // Query for master login
      const mastersRef = collection(db, "masters");
      const qMasters = query(mastersRef, where("username", "==", username));
      const querySnapshotMasters = await getDocs(qMasters);
  
      if (!querySnapshotMasters.empty) {
        querySnapshotMasters.forEach((doc) => {
          const masterData = doc.data();
          if (masterData.password === password) {
            // Handle account status
            if (masterData.status === 'SUSPENDED') {
              navigate('/suspended'); // Redirect to the Suspended page
              return;
            } else if (masterData.status === 'CLOSED') {
              navigate('/closed'); // Redirect to the Closed page
              return;
            }

            // Handle password change
            if (!masterData.passwordChanged) {
              navigate('/change-password', { state: { role: 'master', username: masterData.username } });
            } else {
              navigate('/paymentGate', { state: { role: masterData.role, username: masterData.username } });
            }
            window.location.reload();
            return;
          }
        });
      }
  
      // Query for super login
      const superRef = collection(db, "super");
      const qSuper = query(superRef, where("username", "==", username));
      const querySnapshotSuper = await getDocs(qSuper);
  
      if (!querySnapshotSuper.empty) {
        querySnapshotSuper.forEach((doc) => {
          const superData = doc.data();
          if (superData.password === password) {
            // Handle account status
            if (superData.status === 'SUSPENDED') {
              navigate('/suspended'); // Redirect to the Suspended page
              return;
            } else if (superData.status === 'CLOSED') {
              navigate('/closed'); // Redirect to the Closed page
              return;
            }

            // Handle password change
            if (!superData.passwordChanged) {
              navigate('/change-password', { state: { role: 'super', username: superData.username } });
            } else {
              navigate('/listUsers', { state: { role: 'super', username: superData.username } });
            }
            window.location.reload();
            return;
          }
        });
      }
      setLoading(false);  // Stop loading when the login is done

      // If no match is found, set an error message
      setError('Wrong credentials. Please try again.');
    } catch (e) {
      console.error("Error during login: ", e);
      setError('An error occurred. Please try again.');
      setLoading(false);  // Stop loading in case of error
    }
  };

  const fetchlogo = async () =>{
    try {
      setLogoLoad(true)
      const logoRef = doc(db, "utils", "logos"); // Ensure your path is correct
    const logoSnap = await getDoc(logoRef);

    if (logoSnap.exists()) {
      setLogo(logoSnap.data().desktop); // Use data() as a function
    } else {
      console.log("No such document!");
    }
    } catch (error) {
      console.error(error)
    } finally {
      setLogoLoad(false)
    }
  }

  useEffect(()=>{
    fetchlogo();
  },[])
  
  return (
    <div>
    {loading ? (
      // Show spinner when loading is true
      <div className="flex justify-center items-center min-h-screen bg-gray-900">
<div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
</div>
    ) : ( <div className="flex items-center justify-center h-screen bg-gray-900">

      <div className="bg-gray-800 p-16 md:p-28 rounded-lg shadow-lg max-w-md w-full flex flex-col items-center">
      
  { logoload? ("") : (<img 
    // src='https://iriscdn.b-cdn.net/rahulgamex.com/banners/RahulGameX%404x.png' 
    src={logo}
    alt='rahulgameX' 
    className='pb-10 w-60' 
  />)}

        <h2 className="text-2xl text-white mb-6 text-center">Admin Login</h2>
        <form onSubmit={handleLogin}>
          <div className="mb-4">
            <label className="block text-white mb-2">Username</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              className="w-full p-2 rounded-lg bg-gray-700 text-white"
              required
            />
          </div>
          <div className="mb-4">
            <label className="block text-white mb-2">Password</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="w-full p-2 rounded-lg bg-gray-700 text-white"
              required
            />
          </div>
          {error && <p className="text-red-500 mb-4">{error}</p>}
          <div className="flex justify-center">
            <button
              type="submit"
              className="bg-blue-600 text-white px-4 py-2 rounded-lg"
            >
              Login
            </button>
          </div>
        </form>
      </div>
    </div>)}
  </div>
  );
};

export default AdminLogin;
