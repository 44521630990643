import { addDoc, collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { db } from '../../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const MasterForm = ({ superUsername, onSubmit, onClose }) => {
  const [passwordError, setPasswordError] = useState('');
  const [superPassword, setSuperPassword] = useState('');
  const [balance, setBalance] = useState(); // Using balance instead of limit
  const [balanceError, setBalanceError] = useState(''); // For balance error
  const [superBalance, setSuperBalance] = useState(0); // Store super's available balance


  const handleBalanceChange = (e) => {
    const enteredBalance = parseFloat(e.target.value);
    setBalance(enteredBalance);
  
    // Validate entered balance against superBalance
    if (enteredBalance > superBalance) {
      setBalanceError('Insufficient balance');
    } else {
      setBalanceError('');
    }
  };

  useEffect(() => {
    const fetchSuperBalance = async () => {
      try {
        const superRef = collection(db, 'super');
        const querySnapshot = await getDocs(query(superRef, where('username', '==', superUsername)));
        if (!querySnapshot.empty) {
          const superDoc = querySnapshot.docs[0];
          const superData = superDoc.data();
          setSuperBalance(superData.balance || 0); // Set the balance
        } else {
          console.log('Super not found');
        }
      } catch (error) {
        console.error('Error fetching super balance:', error);
      }
    };
  
    fetchSuperBalance();
  }, [superUsername]);
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const username = e.target.username.value;
    const password = e.target.password.value;
    const enteredSecurityId = e.target.securityId.value;


    // Password length validation
    if (password.length <= 5) {
      setPasswordError('Password must have more than 5 characters');
      return;
    }

    if (balanceError) {
      toast.error('Insufficient balance');
      return; // Prevent form submission
    }

    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!specialCharacterRegex.test(password)) {
      setPasswordError('Password must contain at least one special character');
      return;
    }

    if (enteredSecurityId !== superPassword) {
      toast.error('Security ID does not match the super password.');
      return; // Prevent form submission
    }

    const GamesSelect = {
      football: 'off',
      basketball: 'off',
      cricket: 'off',
      tennis: 'off',
      baseball: 'off',
      rugby: 'off',
      hockey: 'off',
    };

    // const newMaster = {
    //   title: superUsername,
    //   username,
    //   password,
    //   refillBonus: e.target.refillBonus.value,
    //   ftdBonus: e.target.ftdBonus.value,
    //   phone: e.target.phone.value,
    //   limit: e.target.limit.value,
    //   role: "master",
    //   balance: 0,
    //   defaultReg: "Off",
    //   status:"ACTIVE",
    //   creditAmt: 0,
    //   GamesSelect,
    //   // totalAmt:balance
    // };
    

    const newMaster = {
      title: superUsername,
      username,
      password,
      refillBonus: parseFloat(e.target.refillBonus.value) || 0, // Ensure refillBonus is a float
      ftdBonus: parseFloat(e.target.ftdBonus.value) || 0, // Ensure ftdBonus is a float
      phone: parseInt(e.target.phone.value, 10) || null, // Ensure phone is an integer
      limit: parseFloat(e.target.limit.value) || 0, // Ensure limit is a float
      role: "master",
      balance: parseFloat(balance), // Ensure balance is a float
      defaultReg: "Off",
      status: "ACTIVE",
      creditAmt: parseFloat(0), // Ensure creditAmt is a float
      GamesSelect,
      totalAmt: parseFloat(balance) || 0, // Ensure totalAmt is a float
      passwordChanged:false,
    };
    
    onSubmit(newMaster);

    const updatedSuperBalance = superBalance - parseFloat(balance);

  // Update the super's balance in Firestore
  const superDocRef = query(collection(db, 'super'), where('username', '==', superUsername));
  const querySnapshot = await getDocs(superDocRef);
  if (!querySnapshot.empty) {
    const superDocId = querySnapshot.docs[0].id; // Get the super doc ID
    const superDocRef = doc(db, 'super', superDocId); // Reference to the super's document

    await updateDoc(superDocRef, {
      balance: updatedSuperBalance, // Update the balance in Firestore
    });

    // toast.success('Master created successfully and super balance updated.');
  } else {
    toast.error('Super user not found.');
  }

  try {
    await addDoc(collection(db, 'paymentGateway'), {
      createdBy: username, // New master's username
      minDeposit: 50000,
      minWithdraw: 50000,
      withdrawCount: 1,
      updatedAt: new Date().toISOString(),
      withdrawCountChangedAt: new Date().toISOString(),
    });
    // toast.success('Payment gateway created successfully.');
  } catch (error) {
    console.error('Error creating payment gateway:', error);
    toast.error('Failed to create payment gateway.');
  }
  };


  useEffect(() => {
    const fetchSuperPassword = async () => {
      const superRef = collection(db, 'super');
      const querySnapshot = await getDocs(query(superRef, where('username', '==', superUsername)));
      const superDoc = querySnapshot.docs[0];
      setSuperPassword(superDoc?.data().password || '');
    };
    fetchSuperPassword();
  }, [superUsername]);
  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-full max-w-[90%] md:max-w-[500px] mx-auto max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4 text-center">Create New Master</h2>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label className="block mb-2">Title</label>
            <input
              type="text"
              value={superUsername}
              className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              readOnly
            />
          </div>

          {/* Username Field */}
          <div className="mb-4">
            <label className="block mb-2">Username <span className="text-white-500 text-xl font-bold">*</span></label>
            <input
              type="text"
              name="username"
              className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              required
            />
          </div>

          {/* Password Field */}
          <div className="mb-4">
            <label className="block mb-2">Password <span className="text-white-500 text-xl font-bold">*</span></label>
            <input
              type="password"
              name="password"
              className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              required
            />
            {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
          </div>

          <div className="mb-4">
      <label className="block mb-2">Balance <span className="text-white-500 text-xl font-bold">*</span></label>
      <input
  type="number"
  value={balance}
  onChange={handleBalanceChange}
  className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
  required
/>
{balanceError && <p className="text-red-500">{balanceError}</p>}
    </div>

          {/* Refill Bonus & FTD Bonus Fields */}
          <div className="flex flex-col md:flex-row md:space-x-2 mb-4">
            <div className="flex-1 mb-4 md:mb-0">
              <label className="block mb-2">Refill Bonus %:</label>
              <input
                type="number"
                name="refillBonus"
                defaultValue="0"
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="flex-1">
              <label className="block mb-2">FTD Bonus %:</label>
              <input
                type="number"
                name="ftdBonus"
                defaultValue="0"
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
          </div>

          {/* Phone Number Field */}
          <div className="mb-4">
            <label className="block mb-2">Phone No:</label>
            <input
              type="number"
              name="phone"
              defaultValue="+91 74104 10123"
              className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
            />
          </div>

          

          {/* Limit & Security Id Fields */}
          <div className="flex flex-col md:flex-row md:space-x-2 mb-4">
            <div className="flex-1 mb-4 md:mb-0">
              <label className="block mb-2">Limit:</label>
              <input
                type="number"
                name="limit"
                defaultValue="0"
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="flex-1">
              <label className="block mb-2">Security Id:</label>
              <input
                type="text"
                name="securityId"
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
          </div>

          {/* Submit & Close Buttons */}
          <div className="flex justify-end space-x-2">
            <button
              type="submit"
              className="bg-green-600 text-white px-4 py-2 rounded-md"
            >
              Submit
            </button>
            <button
              type="button"
              onClick={onClose}
              className="bg-red-500 text-white px-4 py-2 rounded-md"
            >
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MasterForm;
