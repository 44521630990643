import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../../firebase";

const StatusModal = ({ user, isOpen, onClose, onUpdate }) => {
  const [newStatus, setNewStatus] = useState(user.status);

  const handleSubmitStatus = async () => {
    try {
      const userDocRef = doc(db, "users", user.id); // Reference to Firestore document
      await updateDoc(userDocRef, { status: newStatus }); // Update Firestore
      onUpdate(user.id, newStatus); // Call onUpdate to update the parent component
      onClose(); // Close the modal
    } catch (error) {
      console.error("Error updating status: ", error);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
        <h2 className="text-white text-xl mb-4">
          Account Status of {user.username}
        </h2>
        <div className="mb-4">
          <label className="block mb-2 text-white">Account Status:</label>
          <select
            className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
            value={newStatus}
            onChange={(e) => setNewStatus(e.target.value)}
          >
            <option value="ACTIVE">ACTIVE</option>
            <option value="SUSPENDED">SUSPENDED</option>
            <option value="CLOSED">CLOSED</option>
          </select>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            className="bg-green-600 text-white px-4 py-2 rounded-md"
            onClick={handleSubmitStatus}
          >
            Submit
          </button>
          <button
            className="bg-red-500 text-white px-4 py-2 rounded-md"
            onClick={onClose}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
