import React, { useEffect, useState } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

const MasterInfoModal = ({ masterId, onClose }) => {
  const [masterData, setMasterData] = useState(null);

  useEffect(() => {
    const fetchMasterData = async () => {
      if (!masterId) return;

      const masterRef = doc(db, 'masters', masterId);  // Fetch from masters collection
      const docSnap = await getDoc(masterRef);
      if (docSnap.exists()) {
        setMasterData(docSnap.data());
      } else {
        console.error('No such document!');
      }
    };

    fetchMasterData();
  }, [masterId]);

  // Helper function to capitalize the first letter of each key
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  if (!masterData) {
    return null; // Don't render anything if there's no data
  }

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 max-h-screen overflow-y-auto">
        <div className="flex justify-between items-center">
          <h2 className="text-green-500 font-bold m-auto text-xl mb-7">Master Info</h2>
          <button onClick={onClose} className="text-white bg-red-500 p-1 rounded">X</button>
        </div>

        <ul className="text-white space-y-2">
          {/* Display God Name instead of Super Name if needed */}
          <li className="flex justify-between">
            <span className="font-bold">Super Name</span>
            <span>{masterData.title}</span>
          </li>

          {/* Master Name */}
          <li className="flex justify-between">
            <span className="font-bold">Master Name</span>
            <span>{masterData.username}</span>
          </li>

          {/* Phone Number */}
          <li className="flex justify-between">
            <span className="font-bold">Phone Number</span>
            <span>{masterData.phone}</span>
          </li>

          {/* Exclude pinNumber, password, GamesSelect, title */}
          {Object.entries(masterData)
            .filter(([key]) => key !== 'password' && key !== 'title' && key !== 'pinNumber' && key !== 'GamesSelect' && key !== 'username' && key !== 'phone')
            .map(([key, value]) => (
              <li key={key} className="flex justify-between">
                <span className="font-bold">{capitalizeFirstLetter(key)}</span>
                <span>{value.toString()}</span>
              </li>
            ))
          }

          {/* GamesSelect at the end */}
          {masterData.GamesSelect && (
            <li className="mb-4">
              <span className="font-bold">Games Select:</span>
              {Object.entries(masterData.GamesSelect).map(([gameKey, gameValue]) => (
                <div key={gameKey} className="flex justify-between mt-1">
                  <span>{capitalizeFirstLetter(gameKey)}</span>
                  <select
                    value={gameValue}
                    disabled
                    className="p-1 bg-gray-700 rounded text-white"
                  >
                    <option value="off">Off</option>
                    <option value="on">On</option>
                  </select>
                </div>
              ))}
            </li>
          )}
        </ul>

        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="bg-red-600 px-4 py-2 rounded-lg text-white">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default MasterInfoModal;
