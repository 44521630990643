import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import { Howl } from 'howler';


const DM = () => {
  const location = useLocation();
  const loggedInMasterUsername = location.state?.username || ''; // Assuming logged-in master’s username is passed in route state
  const [heading, setHeading] = useState("DW States");
  const [depositData, setDepositData] = useState([]);
  const [status, setStatus] = useState("Action");
  const prevDepositDataRef = useRef([]);  // Correctly initialize the ref to store previous deposits

  const notificationSound = new Audio('/assets/notification.wav');

  // Set the heading based on the location state
  useEffect(() => {
    if (location.state && location.state.heading) {
      setHeading(location.state.heading);
    }
  }, [location.state]);

  // Fetch deposit data for users created by the logged-in master
  useEffect(() => {
    const fetchDepositsForMaster = async () => {
      try {
        // First, fetch all users created by the logged-in master
        const usersQuery = query(
          collection(db, 'users'),
          where('createdBy', '==', loggedInMasterUsername)
        );
        const usersSnapshot = await getDocs(usersQuery);
        const userNames = usersSnapshot.docs.map((doc) => doc.data().username);

        if (userNames.length === 0) {
          setDepositData([]); // No users created by the master, no deposits
          return;
        }

        // Fetch deposits for these users
        const depositsQuery = query(
          collection(db, 'transaction/deposit/user'),
          where('username', 'in', userNames) // Only fetch deposits for users created by the logged-in master
        );

        const unsubscribe = onSnapshot(depositsQuery, (snapshot) => {
          const newDepositData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const prevDepositData = prevDepositDataRef.current;
          

          const isNewDeposit = newDepositData.some((newDeposit) => {
            return !prevDepositData.some((prevDeposit) => prevDeposit.id === newDeposit.id);
          });

          // Check if the content has changed, not just the length
          const hasNewData = newDepositData.some(
            (newData, index) => newData.id !== depositData[index]?.id
          );

          if (isNewDeposit) {
            notificationSound.play().catch((error) => console.log(error));
          }
          setDepositData(newDepositData);  // Automatically update the state
          prevDepositDataRef.current = newDepositData;


        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching deposits for master:', error);
      }
    };

    fetchDepositsForMaster();
  }, [loggedInMasterUsername, depositData]);




  // Handle action dropdown
  async function handleAction(e, t) {
    const selectedAction = e.target.value;

    // Prevent changing to the same status
    if (selectedAction === t.orderStatus) {
      toast.error("This status is already set.");
      return;
    }
  
    // Fetch the user
    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('username', '==', t.username));
    const querySnapshot = await getDocs(q);
  
    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();
      const currentUserAmount = parseFloat(userData.amount) || 0;
      const depositAmount = parseFloat(t.reqAmnt) || 0;
  
      // Fetch the master's balance
      const mastersCollection = collection(db, 'masters');
      const masterQuery = query(mastersCollection, where('username', '==', userData.createdBy)); // Assuming 'createdBy' holds the master's username
      const masterSnapshot = await getDocs(masterQuery);
  
      if (!masterSnapshot.empty) {
        const masterDoc = masterSnapshot.docs[0];
        const masterData = masterDoc.data();
        const currentMasterBalance = parseFloat(masterData.balance) || 0;
  
        if (selectedAction === "Accept") {
          // Check if the master has enough balance to cover the deposit
          if (currentMasterBalance < depositAmount) {
            toast.error("Master has insufficient balance to accept this deposit.");
            return; // Exit without updating the status
          }
  
          // Deduct the deposit amount from the master's balance
          const newMasterBalance = currentMasterBalance - depositAmount;
          await updateDoc(masterDoc.ref, { balance: newMasterBalance });
  
          // Update the user's balance with the deposited amount
          const newUserAmount = currentUserAmount + depositAmount; // New user balance after deposit
          await updateDoc(userDoc.ref, { amount: newUserAmount });
  
          // After successfully updating the balances, update the status to "Accepted"
          await updateDoc(doc(db, `transaction/deposit/user/${t.id}`), {
            ...t,
            orderStatus: selectedAction,
          });
  
          // Update local state for immediate UI change
          setDepositData((prevData) =>
            prevData.map((item) =>
              item.id === t.id ? { ...item, orderStatus: selectedAction } : item
            )
          );
  
          toast.success("Deposit accepted");
        } else if (t.orderStatus === "Accept") {
          // Only adjust balances if the current status is "Accept" and changing to another status
          
          // Deduct the deposit amount from the user's balance
          const newUserAmount = currentUserAmount - depositAmount;
  
          if (newUserAmount < 0) {
            toast.error("Insufficient user balance to revert this deposit.");
            return;
          }
  
          await updateDoc(userDoc.ref, { amount: newUserAmount });
  
          // Add the deposit amount back to the master's balance
          const newMasterBalance = currentMasterBalance + depositAmount;
          await updateDoc(masterDoc.ref, { balance: newMasterBalance });
  
          // Update the status in the database
          await updateDoc(doc(db, `transaction/deposit/user/${t.id}`), {
            ...t,
            orderStatus: selectedAction,
          });
  
          // Update local state for immediate UI change
          setDepositData((prevData) =>
            prevData.map((item) =>
              item.id === t.id ? { ...item, orderStatus: selectedAction } : item
            )
          );
  
          toast.success("Deposit status updated and balances adjusted.");
        } else {
          // If the status is changing from "Pending" to "Rejected" or vice versa, no balance changes
          await updateDoc(doc(db, `transaction/deposit/user/${t.id}`), {
            ...t,
            orderStatus: selectedAction,
          });
  
          // Update local state for immediate UI change
          setDepositData((prevData) =>
            prevData.map((item) =>
              item.id === t.id ? { ...item, orderStatus: selectedAction } : item
            )
          );
  
          toast.success("Deposit status updated");
        }
      } else {
        console.log("Master not found");
      }
    } else {
      console.log("User not found");
    }
  }

  
  
  

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <h1 className="text-2xl text-center mt-6 font-semibold mb-8">{heading}</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 text-white rounded-lg mt-10 pl-4 md:pl-10">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Client Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                User Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Req Amount
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                UPI ID
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Order Status
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Option
              </th>
            </tr>
          </thead>
          <tbody>
            {depositData?.map((d, index) => (
              <tr key={index} className="bg-gray-800 border-b border-gray-700">
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  <div className="flex items-center">
                    <span className="text-green-500 mr-2">+</span>
                    <span>{d?.clinetName}</span>
                  </div>
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.username}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap text-green-500">{d?.reqAmnt}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.trnstID}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.orderStatus}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  <div className="relative inline-block text-left">
                    <select
                      value={status}
                      onChange={(e) => handleAction(e, d)}
                      className="bg-transparent text-white"
                    >
                      <option disabled className="bg-transparent text-black">
                        Action
                      </option>
                      <option value="pending" className="bg-transparent text-black">
                        Pending
                      </option>
                      <option value="Accept" className="bg-transparent text-black">
                        Accept
                      </option>
                      <option value="Reject" className="bg-transparent text-black">
                        Reject
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default DM;
