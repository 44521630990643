import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

const CreditAmtModal = ({ masterId, currentCreditAmt, superPassword, onClose }) => {
  const [creditAmt, setCreditAmt] = useState(currentCreditAmt || ''); // Initialize with the current creditAmt
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    setCreditAmt(currentCreditAmt); // When the modal opens, initialize the field with the current creditAmt value
  }, [currentCreditAmt]);

  // Toggle password visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Handle form submission and update Firestore
  // const handleCreditAmtUpdate = async () => {
  //   if (enteredPassword !== superPassword) {
  //     setError('Incorrect god password.');
  //     return;
  //   }

  //   try {
  //     const masterRef = doc(db, 'masters', masterId);
  //     await updateDoc(masterRef, { creditAmt });  // Update creditAmt in Firestore
  //     alert('Credit Amount updated successfully.');
  //     onClose();
  //   } catch (error) {
  //     console.error('Error updating Credit Amount:', error);
  //     alert('Failed to update Credit Amount. Please try again.');
  //   }
  // };

  const handleCreditAmtUpdate = async () => {
    if (enteredPassword !== superPassword) {
      setError('Incorrect god password.');
      return;
    }
  
    try {
      const masterRef = doc(db, 'masters', masterId);
      
      // Fetch current master document
      const masterDoc = await getDoc(masterRef);
      if (masterDoc.exists()) {
        const currentData = masterDoc.data();
        const currentBalance = parseFloat(currentData.balance) || 0;
        const prevCreditAmt = parseFloat(currentData.creditAmt) || 0; // Get previous credit amount
        const newCreditAmt = parseFloat(creditAmt) || 0;
        
        // First, subtract the previous credit amount from the balance
        const adjustedBalance = currentBalance - prevCreditAmt;
        
        // Then, add the new credit amount to the adjusted balance
        const updatedBalance = adjustedBalance + newCreditAmt;
        
        // Update both creditAmt and balance in Firestore
        await updateDoc(masterRef, { 
          creditAmt: newCreditAmt, 
          balance: updatedBalance 
        });
  
        alert('Credit Amount and Balance updated successfully.');
        onClose();
      } else {
        console.error('Master not found.');
      }
    } catch (error) {
      console.error('Error updating Credit Amount and Balance:', error);
      alert('Failed to update Credit Amount and Balance. Please try again.');
    }
  };
  

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Update Credit Amount</h2>

        {/* Input field for credit amount */}
        <input
          type="number"
          placeholder="Enter Credit Amount"
          value={creditAmt}
          onChange={(e) => setCreditAmt(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password input field */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="God Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? "Hide" : "Show"} Password
        </button>

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleCreditAmtUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreditAmtModal;
