import React, { useState } from 'react';

const DropdownSelector = () => {
  const [selectedOption, setSelectedOption] = useState('INDIA');
  const [showDropdown, setShowDropdown] = useState(false);

  const options = ['INDIA', 'UAE', 'UK', 'PAKISTAN'];

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setShowDropdown(false);
    
  };

  return (
    <div className="relative inline-block text-left">
      <div
        className="cursor-pointer bg-gray-800 text-white px-4 py-2 rounded-md flex items-center"
        onClick={() => setShowDropdown(!showDropdown)}
      >
        {selectedOption}
        <svg
          className="ml-2 h-5 w-5 text-gray-400"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 20 20"
          fill="currentColor"
          aria-hidden="true"
        >
          <path
            fillRule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clipRule="evenodd"
          />
        </svg>
      </div>

      {showDropdown && (
        <div className="absolute mt-2 w-full rounded-md shadow-lg bg-gray-800 ring-1 ring-black ring-opacity-5 z-10">
          <div className="py-1">
            {options.map((option) => (
              <div
                key={option}
                onClick={() => handleOptionClick(option)}
                className={`block px-4 py-2 text-sm text-white hover:bg-blue-600 cursor-pointer ${
                  selectedOption === option ? 'bg-blue-600' : ''
                }`}
              >
                {option}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default DropdownSelector;
