import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db } from "../../firebase";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ModeOfPayment = () => {
  const location = useLocation();
  const SuperUsername = location.state?.username; // This is the master username passed from TopBar

  const [paymentModes, setPaymentModes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [isModalOpen, setIsModalOpen] = useState(false); // Modal visibility state
  const [accountNumber, setAccountNumber] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [bankName, setBankName] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [masterPassword, setMasterPassword] = useState(""); // For add bank modal

  const [masterPasswordStatus, setMasterPasswordStatus] = useState(""); // For action modal
  const [selectedAccount, setSelectedAccount] = useState(null); // For the selected account details
  const [isEditModalOpen, setIsEditModalOpen] = useState(false); // For edit modal visibility
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [selectedModeId, setSelectedModeId] = useState(null);
  const [newStatus, setNewStatus] = useState(""); // Initialize with an empty string or default value
  const [qrCodeFile, setQrCodeFile] = useState(null);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isQrUploading, setIsQrUploading] = useState(false);
  const [statusLoading, setStatusLoading] = useState(false);  // Track loading state for status change



  const openEditModal = (mode) => {
    setSelectedAccount(mode); // Set the selected account details
    setIsEditModalOpen(true); // Open the modal
  };

  // Function to open the modal
  const openModal = () => setIsModalOpen(true);

  // Function to close the modal
  const closeModal = () => {
    // Clear form fields when closing the modal
    setAccountNumber("");
    setAccountHolderName("");
    setBankName("");
    setIfscCode("");
    setMasterPassword(""); // Clear add bank password
    setQrCodeFile(null)
    setIsModalOpen(false);
  };

  const openStatusModal = (id, currentStatus) => {
    setSelectedModeId(id);
    setNewStatus(currentStatus); // Set the current status when opening the modal
    setIsStatusModalOpen(true);
  };

  const closeStatusModal = () => {
    setIsStatusModalOpen(false);
    setMasterPasswordStatus(""); // Clear status modal password
  };


  const handleSubmit = async () => {
    // Validate if all fields are filled
    if (
      !accountNumber ||
      !accountHolderName ||
      !bankName ||
      !ifscCode ||
      !masterPassword ||
      !qrCodeFile
    ) {
      toast.error("All fields are required.");
      return; // Stop form submission
    }

    setIsSubmitting(true); // Show spinner during submission

    try {
      // Validate master password with Firestore
      const docRef = collection(db, "masters");
      const snapshot = await getDocs(docRef);
      const masterDoc = snapshot.docs.find(
        (doc) => doc.data().username === SuperUsername
      );

      if (masterDoc && masterDoc.data().password === masterPassword) {
        let qrCodeUrl = null;

        if (qrCodeFile) {
          setIsQrUploading(true); // Show QR code upload spinner

          const storage = getStorage();
          const storageRef = ref(
            storage,
            `qrCodes/${SuperUsername}_${Date.now()}`
          );

          // Upload the QR code file
          await uploadBytes(storageRef, qrCodeFile);
          qrCodeUrl = await getDownloadURL(storageRef); // Get the download URL

          setIsQrUploading(false); // Hide QR code upload spinner
        }

        // Store data in Firestore with createdBy field
        const newDocRef = await addDoc(collection(db, "modeOfPayment"), {
          accountNumber,
          accountHolderName,
          bankName,
          ifscCode,
          status: "Closed",
          createdBy: SuperUsername,
          createdAt: new Date().toISOString(),
          qrCodeUrl, // Store the QR code URL in Firestore
        });

        // Access new document ID from newDocRef
        const newDocId = newDocRef.id;

        // Update the paymentModes state to reflect the new entry
        setPaymentModes((prev) => [
          ...prev,
          {
            id: newDocId, // Use the new document ID
            accountNumber,
            accountHolderName,
            bankName,
            ifscCode,
            status: "Closed",
            createdBy: SuperUsername,
            createdAt: new Date().toISOString(),
            qrCodeUrl,
          },
        ]);

        closeModal();
      } else {
        toast.error("Invalid master password");
      }
    } catch (error) {
      console.error("Error adding bank details:", error);
      toast.error("Failed to add bank details");
    } finally {
      setIsSubmitting(false); // Hide spinner after update
    }
  };

  const handleStatusChange = async () => {
    setStatusLoading(true);  // Stop loading spinner

    try {
      // Validate Master Password
      const docRef = collection(db, "masters");
      const snapshot = await getDocs(docRef);
      const masterDoc = snapshot.docs.find(
        (doc) => doc.data().username === SuperUsername
      );

      if (masterDoc && masterDoc.data().password === masterPasswordStatus) {
        // Check if there's already an account with "Active" status
        if (newStatus === "Active") {
          const q = query(
            collection(db, "modeOfPayment"),
            where("createdBy", "==", SuperUsername), // Check only for this user
            where("status", "==", "Active")
          );
          const activeAccountSnapshot = await getDocs(q);

          if (
            !activeAccountSnapshot.empty &&
            selectedModeId !== activeAccountSnapshot.docs[0].id
          ) {
            toast.error("Only one account can have Active status at a time.");
            setStatusLoading(false);  // Stop loading spinner
            return; // Prevent further execution
          }
        }

        // Update the status in Firestore
        const modeRef = doc(db, "modeOfPayment", selectedModeId);
        await updateDoc(modeRef, {
          status: newStatus,
        });

        // Update the local UI in real-time
        const updatedPaymentModes = paymentModes.map((mode) =>
          mode.id === selectedModeId ? { ...mode, status: newStatus } : mode
        );
        setPaymentModes(updatedPaymentModes);

        closeStatusModal();
      } else {
        toast.error("Invalid Master Password");
      }
    } catch (error) {
      console.error("Error updating status:", error);
    } finally {
      setMasterPasswordStatus(""); // Clear status modal password
      setStatusLoading(false);  // Stop loading spinner
    }
  };


  const handleUpdate = async () => {
    setIsSubmitting(true); // Show spinner during update

    try {
      const docRef = collection(db, "masters");
      const snapshot = await getDocs(docRef);
      const masterDoc = snapshot.docs.find(
        (doc) => doc.data().username === SuperUsername
      );

      if (masterDoc && masterDoc.data().password === masterPasswordStatus) {
        let qrCodeUrl = selectedAccount.qrCodeUrl;

        // If a new QR code is uploaded, replace the existing one
        if (qrCodeFile) {
          const storage = getStorage();
          const storageRef = ref(
            storage,
            `qrCodes/${SuperUsername}_${Date.now()}`
          );

          // Upload new QR code
          await uploadBytes(storageRef, qrCodeFile);
          qrCodeUrl = await getDownloadURL(storageRef);
        }

        // Update the account details in Firestore
        const modeRef = doc(db, "modeOfPayment", selectedAccount.id);
        await updateDoc(modeRef, {
          accountNumber: selectedAccount.accountNumber,
          accountHolderName: selectedAccount.accountHolderName,
          bankName: selectedAccount.bankName,
          ifscCode: selectedAccount.ifscCode,
          qrCodeUrl, // Update QR code URL
        });

        // Update the local state
        const updatedPaymentModes = paymentModes.map((mode) =>
          mode.id === selectedAccount.id
            ? { ...selectedAccount, qrCodeUrl }
            : mode
        );
        setPaymentModes(updatedPaymentModes);

        setIsEditModalOpen(false); // Close the modal
        setMasterPasswordStatus(""); // Clear the password field
      } else {
        toast.error("Invalid Master Password");
      }
    } catch (error) {
      console.error("Error updating account:", error);
    } finally {
      setIsSubmitting(false); // Hide spinner after update
    }
  };

  const handleDelete = async () => {
    try {
      const docRef = collection(db, "masters");
      const snapshot = await getDocs(docRef);
      const masterDoc = snapshot.docs.find(
        (doc) => doc.data().username === SuperUsername
      );

      if (masterDoc && masterDoc.data().password === masterPasswordStatus) {
        const modeRef = doc(db, "modeOfPayment", selectedAccount.id);
        await deleteDoc(modeRef);
        toast.success("Account deleted successfully!");
        setIsEditModalOpen(false);
        setPaymentModes(
          paymentModes.filter((mode) => mode.id !== selectedAccount.id)
        ); // Update local UI
      } else {
        toast.error("Invalid Master Password");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      toast.error("Error deleting account:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, "modeOfPayment"),
          where("createdBy", "==", SuperUsername) // Filter by logged-in user
        );
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          setPaymentModes([]); // No payment modes added by this user
        } else {
          const data = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPaymentModes(data);
        }
      } catch (error) {
        console.error("Error fetching payment modes:", error);
      }
      setLoading(false);
    };

    fetchData();
  }, [SuperUsername]);

  const handleEditCancel = () =>{
    setMasterPasswordStatus("")
    setIsEditModalOpen(false)
  }

  return (
    <div className="flex flex-col h-screen pb-10 bg-black text-white overflow-x-auto">
      <h1 className="text-2xl text-center mt-6 font-semibold mb-8">
        Deposit Available Accounts
      </h1>

      {isSubmitting ? (
        <div className="flex justify-center items-center min-h-screen bg-gray-900">
          <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      ) : (
        <div className="flex justify-start mb-4 ml-6">
          <button
            className="bg-blue-600 hover:bg-blue-700 text-white font-semibold px-4 py-2 rounded-lg"
            onClick={openModal} // Open modal on button click
          >
            Add Bank Details
          </button>
        </div>
      )}

      {/* Display bank accounts in table format */}
      {isSubmitting ? (
        ""
      ) : (
        <div className="mx-5">
          <table className="min-w-full w-full text-white">
            <thead className="bg-gray-800 text-gray-300">
              <tr className="text-left">
                <th className="p-3">Acct No.</th>
                <th className="p-3">Acct. Holder Name</th>
                <th className="p-3">Bank Name</th>
                <th className="p-3">IFSC Code</th>
                <th className="p-3">QR Code</th>
                <th className="p-3">Status</th>
                <th className="p-3">Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">Loading...</td>
                </tr>
              ) : paymentModes.length === 0 ? (
                <tr>
                  <td colSpan="6" className="text-center pt-10">
                    No mode of payment added.
                  </td>
                </tr>
              ) : (
                paymentModes.map((mode) => (
                  <tr key={mode.id}>
                    <td className="p-3 text-blue-300 font-semibold">
                      {mode.accountNumber}
                    </td>
                    <td className="p-3">{mode.accountHolderName}</td>
                    <td className="p-3 text-blue-400 font-bold">
                      {mode.bankName}
                    </td>
                    <td className="p-3">{mode.ifscCode}</td>
                    <td className="p-3">
                      {mode.qrCodeUrl ? (
                        <img
                          src={mode.qrCodeUrl}
                          alt="QR Code"
                          className="w-20 h-20"
                        />
                      ) : (
                        <p>No QR Code</p>
                      )}
                    </td>

                    <td
                      className={`cursor-pointer`}
                      onClick={() => openStatusModal(mode.id, mode.status)}
                    >
                      <span
                        className={`px-4 py-1 rounded-lg border-4 ${
                          mode.status === "Active"
                            ? "border-green-500"
                            : "border-red-500"
                        }`}
                      >
                        {mode.status}
                      </span>
                    </td>

                    <td className="p-3">
                      <button
                        className="bg-blue-700 hover:bg-blue-800 px-3 py-1 rounded"
                        onClick={() => openEditModal(mode)} // Pass the mode object to the modal
                      >
                        Edit Details
                      </button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      )}

      {/* Add Bank Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <div className="loader">Loading...</div>{" "}
              {/* Add your spinner class or use any loader component */}
            </div>
          ) : (
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-green-500 text-xl mb-4">Add Bank Details</h2>

              <label className="text-white">Acct No:</label>
              <input
                type="text"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">Acct Holder Name:</label>
              <input
                type="text"
                value={accountHolderName}
                onChange={(e) => setAccountHolderName(e.target.value)}
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">Bank Name:</label>
              <input
                type="text"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">IFSC Code:</label>
              <input
                type="text"
                value={ifscCode}
                onChange={(e) => setIfscCode(e.target.value)}
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">Master Password:</label>
              <input
                type="password"
                value={masterPassword}
                onChange={(e) => setMasterPassword(e.target.value)}
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">QR Code:</label>
              {isQrUploading ? (
  <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
) :(<input
                type="file"
                onChange={(e) => setQrCodeFile(e.target.files[0])}
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />)}

              <div className="flex justify-between">
              <button
  onClick={handleSubmit}
  disabled={!accountNumber || !accountHolderName || !bankName || !ifscCode || !masterPassword || !qrCodeFile || isSubmitting}
  className={`bg-green-600 px-4 py-2 rounded-lg text-white ${
    (!accountNumber || !accountHolderName || !bankName || !ifscCode || !masterPassword || !qrCodeFile || isSubmitting) ? "opacity-50 cursor-not-allowed" : ""
  }`}
>
  {isSubmitting ? "Submitting..." : "Submit"}
</button>
                <button
                  onClick={closeModal}
                  className="bg-red-600 px-4 py-2 rounded-lg text-white"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}

      {/* Status Change Modal */}
      {isStatusModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          {statusLoading ? (
  <div className="flex justify-center items-center min-h-screen">
    <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-blue-500"></div>
  </div>
) :(<div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            <h2 className="text-green-500 text-xl mb-4">Change Status</h2>

            <label className="text-white">Select Status:</label>
            <select
              value={newStatus}
              onChange={(e) => setNewStatus(e.target.value)}
              className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
            >
              <option value="Active">Active</option>
              <option value="Closed">Closed</option>
            </select>

            <label className="text-white">Master Password:</label>
            <input
              type="password"
              value={masterPasswordStatus}
              onChange={(e) => setMasterPasswordStatus(e.target.value)}
              className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
            />

            <div className="flex justify-between">
              <button
                onClick={handleStatusChange}
                className="bg-green-600 px-4 py-2 rounded-lg text-white"
              >
                Submit
              </button>
              <button
                onClick={closeStatusModal}
                className="bg-red-600 px-4 py-2 rounded-lg text-white"
              >
                Cancel
              </button>
            </div>
          </div>)}
        </div>
      )}

      {/* Edit/Delete Modal */}
      {isEditModalOpen && selectedAccount && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          {isSubmitting ? (
            <div className="flex justify-center items-center">
              <div className="loader">Loading...</div>{" "}
              {/* Add your spinner class or use any loader component */}
            </div>
          ) : (
            <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
              <h2 className="text-green-500 text-xl mb-4">
                Edit Account Details
              </h2>

              <label className="text-white">Acct No:</label>
              <input
                type="text"
                value={selectedAccount.accountNumber}
                onChange={(e) =>
                  setSelectedAccount({
                    ...selectedAccount,
                    accountNumber: e.target.value,
                  })
                }
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">Acct Holder Name:</label>
              <input
                type="text"
                value={selectedAccount.accountHolderName}
                onChange={(e) =>
                  setSelectedAccount({
                    ...selectedAccount,
                    accountHolderName: e.target.value,
                  })
                }
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">Bank Name:</label>
              <input
                type="text"
                value={selectedAccount.bankName}
                onChange={(e) =>
                  setSelectedAccount({
                    ...selectedAccount,
                    bankName: e.target.value,
                  })
                }
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">IFSC Code:</label>
              <input
                type="text"
                value={selectedAccount.ifscCode}
                onChange={(e) =>
                  setSelectedAccount({
                    ...selectedAccount,
                    ifscCode: e.target.value,
                  })
                }
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">Update QR Code:</label>
              <input
                type="file"
                onChange={(e) => setQrCodeFile(e.target.files[0])}
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <label className="text-white">Master Password:</label>
              <input
                type="password"
                value={masterPasswordStatus}
                onChange={(e) => setMasterPasswordStatus(e.target.value)}
                className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
              />

              <div className="flex justify-between">
                <button
                  onClick={handleUpdate}
                  className="bg-green-600 px-4 py-2 rounded-lg text-white"
                >
                  Update
                </button>
                <button
                  onClick={handleDelete}
                  className="bg-red-600 px-4 py-2 rounded-lg text-white"
                >
                  Delete
                </button>
                <button
                  onClick={handleEditCancel}
                  className="bg-gray-600 px-4 py-2 rounded-lg text-white"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default ModeOfPayment;
