import React, { useState, useEffect } from 'react';
import { collection, getDocs, query, orderBy } from 'firebase/firestore';  // Import orderBy
import { db } from '../../firebase';
import UserInfoModal from '../Master/clientInfoM/reuseComp/UserInfoModal';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Fetch users and super names from Firestore, sorted by 'createdAt' in descending order
  const fetchUsers = async () => {
    try {
      // Get all users and sort them by createdAt in descending order
      const usersQuery = query(collection(db, 'users'), orderBy('createdAt', 'desc'));
      const usersSnapshot = await getDocs(usersQuery);
      const usersList = usersSnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

      // Get all masters (to map super names)
      const mastersQuery = query(collection(db, 'masters'));
      const mastersSnapshot = await getDocs(mastersQuery);
      const mastersList = mastersSnapshot.docs.map((doc) => doc.data());

      // Map each user to their corresponding super name based on `createdBy`
      const usersWithSuperNames = usersList.map((user) => {
        const master = mastersList.find((master) => master.username === user.createdBy);
        const superName = master ? master.title : 'Unknown';
        return { ...user, superName }; // Add superName to user data
      });

      setUsers(usersWithSuperNames);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers();  // Fetch users when component mounts
    const intervalId = setInterval(fetchUsers, 15000);  // Refresh users every 15 seconds
    return () => clearInterval(intervalId);  // Clean up interval on component unmount
  }, []);

  const handleInfoClick = (user) => {
    setSelectedUser(user);
    setIsInfoModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsInfoModalOpen(false);
    setSelectedUser(null);
  };

  return (
    <div className="bg-black h-screen p-4">
      <p className="text-white text-center text-xl font-semibold mb-4">List Of Clients</p>

      {/* User Table */}
      <div className="w-full overflow-x-auto">
        <table className="min-w-full bg-black text-white">
          <thead>
            <tr className="bg-gray-800">
              <th className="p-3 text-left font-bold text-blue-500">Super Name</th>
              <th className="p-3 text-left font-bold text-blue-500">Master Name</th>
              <th className="p-3 text-left font-bold text-blue-500">User Name</th>
              <th className="p-3 text-left font-bold text-blue-500">Balance</th>
              <th className="p-3 text-left font-bold text-blue-500">Exposure</th>
              <th className="p-3 text-left font-bold text-blue-500">Net Balance</th>
              <th className="p-3 text-left font-bold text-blue-500">Status</th>
              <th className="p-3 text-left font-bold text-blue-500">Exposure Limit</th>
              <th className="p-3 text-left font-bold text-blue-500">Registered On</th>
              <th className="p-3 text-left font-bold text-blue-500">Phone No</th>
              <th className="p-3 text-left font-bold text-blue-500">Info</th>
            </tr>
          </thead>
          <tbody>
            {users.map((user, index) => (
              <tr key={index} className="border-b border-gray-700 hover:bg-gray-900">
                {/* Super Name */}
                <td className="p-3">{user.superName}</td>
                {/* Master Name */}
                <td className="p-3">{user.createdBy}</td>
                {/* User Name */}
                <td className="p-3 text-blue-300 font-bold">{user.username}</td>
                {/* Balance */}
                <td className="p-3">{user.amount || 0}</td>
                {/* Exposure */}
                <td className="p-3">{user.exposure || 0}</td>
                {/* Net Balance (Balance - Exposure) */}
                <td className="p-3 font-semibold text-lg">
                  {(parseFloat(user.amount || 0) - parseFloat(user.exposure || 0)).toFixed(2)}
                </td>
                {/* Status */}
                <td className="p-3 font-semibold">
                  <span className={`px-2 py-1 rounded-md ${user.status === 'ACTIVE' ? 'bg-green-600' : 'bg-red-600'}`}>
                    {user.status}
                  </span>
                </td>
                {/* Exposure Limit */}
                <td className="p-3">{user.exposureLimit || 'N/A'}</td>
                {/* Registered On */}
                <td className="p-3">{new Date(user.createdAt).toLocaleString()}</td>
                {/* Phone No */}
                <td className="p-3">{user.phoneNumber || 'N/A'}</td>
                {/* Info Button */}
                <td className="p-3">
                  <button
                    onClick={() => handleInfoClick(user)}
                    className="bg-gray-600 px-2 py-1 rounded-md text-white hover:bg-gray-500"
                  >
                    Info
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Info Modal */}
      {isInfoModalOpen && selectedUser && (
        <UserInfoModal
          isOpen={isInfoModalOpen}
          onClose={handleCloseModal}
          user={selectedUser}
        />
      )}
    </div>
  );
};

export default UserList;
