import React, { useState } from "react";

const BettingModal = ({
  userId,
  currentBetStatus,
  type,
  onClose,
  onSubmit,
}) => {
  const [betStatus, setBetStatus] = useState(currentBetStatus === "On");

  const handleSubmit = () => {
    onSubmit(userId, betStatus ? "On" : "Off", type); // Update bet status based on the toggle
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg">
        <h2 className="text-white text-xl mb-4">{`${type} On/Off`}</h2>
        <div className="mb-4">
          <label className="block text-white mb-2">{type} Betting</label>
          <div className="flex items-center">
            <span className="text-white mr-2">Off</span>
            <div
              className={`rounded-2xl relative inline-block w-12 h-6 align-middle select-none transition duration-200 ease-in ${
                betStatus ? "bg-green-500" : "bg-red-500"
              }`}
              onClick={() => setBetStatus(!betStatus)} // Toggle the bet status
            >
              <span
                className={`absolute left-0 top-0 h-6 w-6 bg-white border-4 rounded-full transform transition-transform duration-200 ease-in-out ${
                  betStatus ? "translate-x-6" : ""
                }`}
              />
            </div>
            <span className="text-white ml-2">On</span>
          </div>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            onClick={handleSubmit}
            className="bg-green-600 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-600 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default BettingModal;
