import React, { useState, useEffect } from 'react';
import { BrowserRouter, Routes, Route  } from "react-router-dom";
import Main from './component/Main';
import { ToastContainer } from 'react-toastify';


const App = () => {
  
  return (
    <div>
    
      <BrowserRouter>
      <ToastContainer
  autoClose={2000}
  hideProgressBar={false}
  closeOnClick={true}
  pauseOnHover={true}
  draggable={true}
  progress={undefined}
  theme="dark"
/>

<ToastContainer
  autoClose={2000}
  hideProgressBar={false}
  closeOnClick={true}
  pauseOnHover={true}
  draggable={true}
  progress={undefined}
  theme="dark"
/>

      <Main />
    </BrowserRouter>
    </div>
  );
}

export default App;
