import React from 'react';
import { FaSyncAlt } from 'react-icons/fa';  // Assuming you're using react-icons for the reload icon
import folderIcon from "../../images/folder.png";  // Update with the correct path to your image

const Analysis = () => {

  const handleLoad = () =>{
    window.location.reload();
  }

  return (
    <div className="flex flex-col md:flex-row h-screen bg-black text-white">
      {/* Sidebar */}
      <div className="w-full md:w-1/5 bg-gray-800 flex flex-col items-center justify-start py-4">
        <div>
          <button className='flex items-center' onClick={handleLoad}> <FaSyncAlt className="text-lg mr-2"/>
          <button className="font-semibold">Reload</button></button>
        </div>
      </div>

      {/* Main Content Area */}
      <div className="w-full md:w-4/5 flex flex-col items-center mt-10 md:mt-0">
        <h1 className="text-2xl font-semibold mb-16">Analysis of Running Market</h1>
        <img src={folderIcon} alt="No Data" className="w-3/4 md:w-[30%] h-auto mb-4" />
      </div>
    </div>
  );
};

export default Analysis;
