import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../../../firebase";

export const fetchUserData = async (createdBy) => {
  try {
    // Reference the users collection and query based on the createdBy field (which is the master's username)
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("createdBy", "==", createdBy)); // Query for users created by this master
    const querySnapshot = await getDocs(q);

    let totalAmount = 0;
    let totalExposure = 0;

    // Loop through each user document and sum up the amount and exposure
    querySnapshot.forEach((doc) => {
      const userData = doc.data();
      totalAmount += parseFloat(userData.amount);
      totalExposure += parseFloat(userData.exposure);
    });

    // Return the aggregated values
    return {
      totalAmount: totalAmount.toString(), // Convert back to string for consistency
      totalExposure: totalExposure.toString(),
    };
  } catch (error) {
    console.error("Error fetching user data:", error);
    return { totalAmount: "0", totalExposure: "0" }; // Return default values in case of error
  }
};
