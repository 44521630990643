import React, { useState, useEffect } from 'react';
import { collection, doc, getDoc, getDocs, query, updateDoc, where, setDoc } from 'firebase/firestore';
import { db } from '../../firebase';
import { useLocation } from 'react-router-dom'; // To access the passed username

const PaymentGateway = () => {
  const location = useLocation();
  const loggedInSuperuser = location.state?.username; // Access the logged-in username from the state


  const [minDeposit1, setMinDeposit1] = useState(); // Default value
  const [minWithdraw1, setMinWithdraw1] = useState(); // Default value
  const [withdrawCount1, setWithdrawCount1] = useState(); // Default value

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState(''); // Field being edited
  const [inputValue, setInputValue] = useState(''); // Value to edit

  const openModal = (field) => {
    setFieldToEdit(field);
    setInputValue(
      field === 'minDeposit' ? minDeposit1 : field === 'minWithdraw' ? minWithdraw1 : withdrawCount1
    );
    setIsModalOpen(true);
  };
  
  // Handle the input change and ensure it's a valid number
  // Handle the input change and ensure it's a valid number or empty string
const handleInputChange = (e) => {
  const value = e.target.value;
  
  // Allow empty input to be set
  if (value === "") {
    setInputValue(""); // Allow the field to be empty
  } else {
    // Set the value as a number
    setInputValue(parseFloat(value) || 0);
  }
};

  

  const handleSubmit1 = async () => {
    try {
      const q = query(
        collection(db, 'paymentGateway'),
        where('createdBy', '==', loggedInSuperuser) // Filter by logged-in superuser
      );
      const querySnapshot = await getDocs(q);
  
      let docRef;
  
      if (!querySnapshot.empty) {
        // If a document exists for this superuser, get the docId and use it
        const docId = querySnapshot.docs[0].id;
        docRef = doc(db, 'paymentGateway', docId);
      } else {
        // Create a new document reference if no document exists
        docRef = doc(collection(db, 'paymentGateway'));
      }
  
      // Build the update object based on which field is being edited
      const updateData = {};
  
      if (fieldToEdit === 'minDeposit') {
        updateData.minDeposit = parseFloat(inputValue); // Ensure it's stored as a number
      } else if (fieldToEdit === 'minWithdraw') {
        updateData.minWithdraw = parseFloat(inputValue); // Ensure it's stored as a number
      } else if (fieldToEdit === 'withdrawCount') {
        updateData.withdrawCount = parseInt(inputValue, 10); // Ensure it's stored as an integer
        updateData.withdrawCountChangedAt = new Date().toISOString();
      }
  
      updateData.updatedAt = new Date().toISOString();
      updateData.createdBy = loggedInSuperuser;
  
      // Use updateDoc to only update specific fields
      await updateDoc(docRef, updateData);
  
      console.log("Data updated successfully!");
  
      // Update the local state to reflect the new data in the UI
      if (fieldToEdit === 'minDeposit') setMinDeposit1(parseFloat(inputValue));
      if (fieldToEdit === 'minWithdraw') setMinWithdraw1(parseFloat(inputValue));
      if (fieldToEdit === 'withdrawCount') setWithdrawCount1(parseInt(inputValue, 10));
  
      setIsModalOpen(false); // Close the modal after updating
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };
  

  
  // Fetch existing data from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        const q = query(
          collection(db, 'paymentGateway'),
          where('createdBy', '==', loggedInSuperuser)
        );
        const querySnapshot = await getDocs(q);

        if (!querySnapshot.empty) {
          const data = querySnapshot.docs[0].data();
          setMinDeposit1(data.minDeposit || 50000);
          setMinWithdraw1(data.minWithdraw || 50000);
          setWithdrawCount1(data.withdrawCount || 2);
        } else {
          // If no document exists, set default values
          setMinDeposit1(50000);
          setMinWithdraw1(50000);
          setWithdrawCount1(2);
          console.log("No payment gateway data found. Please enter the required fields.");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      }
    };

    fetchData();
  }, [loggedInSuperuser]);

  return (
    <div className="flex h-screen flex-col items-center justify-center bg-black text-white">
  <h1 className="text-2xl sm:text-3xl font-bold text-center mb-10">Payment Gateway</h1>

  <div className="space-y-6 mb-10 w-full sm:w-[80%] md:w-[60%] lg:w-[40%] p-8 border-4 border-gray-500 bg-gray-800 rounded-lg shadow-xl">
  <p className="text-xl sm:text-2xl font-semibold text-gray-200 mb-8 text-center">Payment Summary</p>

    <div className="flex justify-between items-center">
      <p className="text-base sm:text-lg font-medium text-gray-300">Min Deposit Amount</p>
      <p className="text-xl sm:text-2xl font-bold text-white">{minDeposit1}</p>
      <button className="bg-blue-600 hover:bg-blue-700 text-white text-sm px-4 py-2 rounded-lg shadow-md" onClick={() => openModal('minDeposit')}>
        Edit
      </button>
    </div>

    <div className="flex justify-between items-center">
      <p className="text-base sm:text-lg font-medium text-gray-300">Max Withdraw Amount</p>
      <p className="text-xl sm:text-2xl font-bold text-white">{minWithdraw1}</p>
      <button className="bg-blue-600 hover:bg-blue-700 text-white text-sm px-4 py-2 rounded-lg shadow-md" onClick={() => openModal('minWithdraw')}>
        Edit
      </button>
    </div>

    <div className="flex justify-between items-center">
      <p className="text-base sm:text-lg font-medium text-gray-300">Withdraw Count</p>
      <p className="text-xl sm:text-2xl font-bold text-white pl-4">{withdrawCount1}</p>
      <button className="bg-blue-600 hover:bg-blue-700 text-white text-sm px-4 py-2 rounded-lg shadow-md" onClick={() => openModal('withdrawCount')}>
        Edit
      </button>
    </div>
  </div>

  {isModalOpen && (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-60">
<div className="bg-gray-900 p-6 rounded-lg shadow-lg w-full max-w-md">
<h2 className="text-2xl font-semibold text-white mb-6 text-center">Edit {fieldToEdit}</h2>
      <input
        type="number"
        value={inputValue}
        onChange={handleInputChange} // Use the handleInputChange function to ensure a number is stored
        className="w-full p-3 bg-gray-800 text-white border-2 border-gray-600 rounded-lg mb-4"
      />
      <div className="flex justify-between space-x-4">
        <button className="bg-green-600 text-white px-5 py-2 rounded-lg hover:bg-green-700" onClick={handleSubmit1}>
          Submit
        </button>
        <button className="bg-red-600 text-white px-5 py-2 rounded-lg hover:bg-red-700" onClick={() => setIsModalOpen(false)}>
          Cancel
        </button>
      </div>
    </div>
  </div>
  
  )}
</div>

  );
};

export default PaymentGateway;
