import React, { useState, useEffect } from "react";
import {
  doc,
  updateDoc,
  collection,
  addDoc,
  getDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase"; // Adjust path to your Firebase config
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DepositModal = ({
  masterName,
  masterId,
  superPassword,
  superUsername,
  onClose,
}) => {
  const [amount, setAmount] = useState(""); // Input field for the deposit amount
  const [description, setDescription] = useState(""); // Input field for description
  const [enteredPassword, setEnteredPassword] = useState("");
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);
  const [creditAmt, setCreditAmt] = useState(0); // Store the master's credit amount
  const [totalExposure, setTotalExposure] = useState(0); // Store total exposure of master’s clients

  // console.log(superUsername)


  // Toggle password visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Fetch master's credit amount and total exposure from clients
  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        // Fetch master data
        const masterRef = doc(db, "masters", masterId);
        const masterDoc = await getDoc(masterRef);
        // console.log(masterDoc)

        if (masterDoc.exists()) {
          const masterData = masterDoc.data();
          setCreditAmt(parseFloat(masterData.creditAmt) || 0); // Set credit amount

          // Fetch total exposure from clients created by this master
          const usersRef = collection(db, "users");
          const usersSnapshot = await getDocs(usersRef);
          let totalExposureValue = 0;

          usersSnapshot.forEach((doc) => {
            const userData = doc.data();
            if (userData.createdBy === masterName) {
              totalExposureValue += parseFloat(userData.exposure || 0);
            }
          });

          setTotalExposure(totalExposureValue); // Set total exposure
        }
      } catch (error) {
        console.error("Error fetching master data:", error);
      }
    };

    fetchMasterData();
  }, [masterId, masterName]);

  // Handle form submission and update Firestore
  // const handleSubmit = async () => {
  //   if (enteredPassword !== superPassword) {
  //     setError("Incorrect super password.");
  //     return;
  //   }

  //   try {
  //     // Fetch the current balance of the master
  //     const masterRef = doc(db, "masters", masterId);
  //     const masterDoc = await getDoc(masterRef); // Fetch the document



  //     if (masterDoc.exists()) {
  //       const currentBalance = masterDoc.data().balance || 0; // Get current balance, default to 0 if undefined

  //       // Calculate new balance and total balance
  //       const depositAmount = parseFloat(amount);
  //       const newBalance = currentBalance + depositAmount;
  //       const netBal = newBalance - totalExposure + creditAmt; // Calculate total balance

  //       // Update the master's balance in Firestore
  //       await updateDoc(masterRef, { balance: newBalance });

  //       // Store deposit transaction in Firestore
  //       const transactionId = Date.now().toString(); // Generate unique transaction ID
  //       const depositRef = collection(db, `accountStmt/super/deposit`);
  //       await addDoc(depositRef, {
  //         amount: depositAmount,
  //         description: `${superUsername}: ${description}`, // Super username included in description
  //         createdAt: new Date().toISOString(),
  //         transactionId,
  //         createdBy: superUsername, // Store the username of the super creating the deposit
  //         balance: newBalance,
  //         netBal, // Store the calculated total balance
  //         masterName: masterName,
  //       });

  //       toast.success("Deposit successful.");
  //       onClose();
  //     } else {
  //       console.error("Master not found");
  //     }
  //   } catch (error) {
  //     console.error("Error updating deposit:", error);
  //     toast.error("Failed to update deposit. Please try again.");
  //   }
  // };


const handleSubmit = async () => {
  if (enteredPassword !== superPassword) {
    setError("Incorrect super password.");
    return;
  }

  try {
    // Fetch the current balance of the master
    const masterRef = doc(db, "masters", masterId);
    const masterDoc = await getDoc(masterRef);

    // Fetch the super document where username matches superUsername
    const superCollectionRef = collection(db, "super");
    const superQuery = query(superCollectionRef, where("username", "==", superUsername));
    const superSnapshot = await getDocs(superQuery);

    if (masterDoc.exists() && !superSnapshot.empty) {
      const superDoc = superSnapshot.docs[0]; // Get the first matching document
      const currentSuperBalance = superDoc.data().balance || 0;   // Get super's current balance

      const currentMasterBalance = masterDoc.data().balance || 0; // Get master's current balance
      const depositAmount = parseFloat(amount);

      // Check if super has enough balance for the deposit
      if (currentSuperBalance >= depositAmount) {
        const newMasterBalance = currentMasterBalance + depositAmount; // Calculate new master balance
        const newSuperBalance = currentSuperBalance - depositAmount;   // Calculate new super balance
        const netBal = newMasterBalance - totalExposure + creditAmt;   // Calculate master net balance

        // Update the master's balance in Firestore
        await updateDoc(masterRef, { balance: newMasterBalance });

        // Update the super's balance in Firestore
        const superDocRef = doc(db, "super", superDoc.id); // Get the super document reference by ID
        await updateDoc(superDocRef, { balance: newSuperBalance });

        // Store deposit transaction in Firestore
        const transactionId = Date.now().toString(); // Generate unique transaction ID
        const depositRef = collection(db, `accountStmt/super/deposit`);
        await addDoc(depositRef, {
          amount: depositAmount,
          description: `${superUsername}: ${description}`, // Super username included in description
          createdAt: new Date().toISOString(),
          transactionId,
          createdBy: superUsername, // Store the username of the super creating the deposit
          balance: newMasterBalance,
          netBal, // Store the calculated total balance for master
          masterName: masterName,
        });

        toast.success("Deposit successful.");
        onClose();
      } else {
        toast.error("Super does not have enough balance to complete this deposit.");
      }
    } else {
      console.error("Master or Super not found");
      toast.error("Failed to fetch master or super data.");
    }
  } catch (error) {
    console.error("Error processing deposit:", error);
    toast.error("Failed to process deposit. Please try again.");
  }
};


  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Deposit for {masterName}</h2>

        {/* Amount Input */}
        <input
          type="number"
          placeholder="Deposit Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Description Input */}
        <textarea
          placeholder="Enter description..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          rows="4"
        />

        {/* Password input field */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="Super Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? "Hide" : "Show"} Password
        </button>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositModal;
