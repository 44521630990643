import React, { useEffect, useState } from 'react';
import folderIcon from "../../images/folder.png";  // Update with the correct path to your image
import AutoReloadToggle from '../WorkComp/AutoToggle';

const AllCurr = () => {
  const [reloadInterval, setReloadInterval] = useState('10 Sec');
  const [time,setTime] = useState(10000)
  
  useEffect(() => {
    const intervalInSeconds = parseInt(reloadInterval.split(' ')[0], 10);
    setTime(intervalInSeconds * 1000);
  }, [reloadInterval])

  return (
    <div className="flex flex-col md:flex-row h-screen bg-black text-white pl-10">
      {/* Sidebar */}
      <div className="w-full md:w-1/4 bg-black flex flex-col items-center justify-start py-4">
        <div className="flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-2 mb-4">
          {/* Toggle Switch */}
          
          <AutoReloadToggle time={time} />
          
          {/* Dropdown */}
          <select
            value={reloadInterval}
            onChange={(e) => setReloadInterval(e.target.value)}
            className="bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
          >
            <option value="10 Sec">10 Sec</option>
            <option value="20 Sec">20 Sec</option>
            <option value="40 Sec">40 Sec</option>
            <option value="60 Sec">60 Sec</option>
          </select>
        </div>
      </div>


      {/* Main Content Area */}
      <div className="w-full md:w-3/4 flex flex-col items-center md:mr-72 mt-10 md:mt-0">
        <h1 className="text-2xl font-semibold mb-28">All Current Fancy</h1>
        <img src={folderIcon} alt="No Data" className="w-3/4 md:w-[40%] h-auto mb-4" />
      </div>
    </div>
  );
};

export default AllCurr;
