import React from 'react';

const InfoModal = ({ superData, onClose }) => {
  // Filter out the password and any other sensitive fields
  const filteredData = { ...superData };
  delete filteredData.password;  // Remove the password field
  
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-96">
        <h2 className="text-white text-xl mb-6">Super Information</h2>

        <div className="text-white">
          {Object.entries(filteredData).map(([key, value]) => (
            <div key={key} className="mb-2">
              <span className="font-bold capitalize">{key}: </span>
              <span>{value !== null ? value.toString() : 'N/A'}</span>
            </div>
          ))}
        </div>

        {/* Close Button */}
        <div className="flex justify-end mt-4">
          <button onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded-lg">
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default InfoModal;
