// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import { getFirestore } from "firebase/firestore"; // For Firestore
import { getStorage } from "firebase/storage";


// KIGO-------------------------
const firebaseConfig = {
  apiKey: "AIzaSyB7B3Wjhk-zb2e3Oi2b_T8_G20fuJ13LDI",
  authDomain: "kigo-effaa.firebaseapp.com",
  databaseURL: "https://kigo-effaa-default-rtdb.firebaseio.com",
  projectId: "kigo-effaa",
  storageBucket: "kigo-effaa.appspot.com",
  messagingSenderId: "31406969582",
  appId: "1:31406969582:web:1e689428805b6dfd4d59ff"
};
// -------------------------------------

// const firebaseConfig = {
//   apiKey: "AIzaSyDgyAoP_uOIvRr5jodhlPf5M5fWtyzV5Io",
//   authDomain: "admin-master-e3ae6.firebaseapp.com",
//   projectId: "admin-master-e3ae6",
//   storageBucket: "admin-master-e3ae6.appspot.com",
//   messagingSenderId: "842267559170",
//   appId: "1:842267559170:web:53ab87d0f4a63320352e1b",
//   measurementId: "G-P3ME1SBTQS"
// };



// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);


export { app, db, storage };

