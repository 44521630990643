import React, { useState, useEffect } from 'react';
import { doc, updateDoc, collection, addDoc, getDoc, getDocs } from 'firebase/firestore';
import { db } from '../../../../firebase';

const DepositModal = ({ masterName, masterId, godPassword, godUsername, onClose }) => {
  const [amount, setAmount] = useState('');  // Input field for the deposit amount
  const [description, setDescription] = useState('');  // Input field for description
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);
  const [creditAmt, setCreditAmt] = useState(0); // Credit amount from master data
  const [totalExposure, setTotalExposure] = useState(0);  // Total exposure of master’s clients

 
  // Toggle password visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Fetch master’s `creditAmt` and calculate total exposure from the clients created by this master
  useEffect(() => {
    const fetchMasterData = async () => {
      try {
        // Fetch master data from Firestore
        const masterRef = doc(db, 'masters', masterId);
        const masterDoc = await getDoc(masterRef);
        if (masterDoc.exists()) {
          const masterData = masterDoc.data();
          setCreditAmt(parseFloat(masterData.creditAmt) || 0); // Set credit amount

          // Fetch total exposure of clients created by this master
          const usersRef = collection(db, 'users');
          const usersSnapshot = await getDocs(usersRef);
          let totalExposureValue = 0;

          usersSnapshot.forEach((doc) => {
            const userData = doc.data();
            if (userData.createdBy === masterName) {
              totalExposureValue += parseFloat(userData.exposure || 0);
            }
          });

          setTotalExposure(totalExposureValue);  // Set total exposure
        }
      } catch (error) {
        console.error('Error fetching master data:', error);
      }
    };

    fetchMasterData();
  }, [masterId, masterName]);

  // Handle form submission and update Firestore
  const handleSubmit = async () => {
    if (enteredPassword !== godPassword) {
      setError('Incorrect god password.');
      return;
    }

    try {
      // Fetch the current balance of the master
      const masterRef = doc(db, 'masters', masterId);
      const masterDoc = await getDoc(masterRef);

      if (masterDoc.exists()) {
        const currentBalance = masterDoc.data().balance || 0;

        // Calculate the new balance and net balance
        const depositAmount = parseFloat(amount);
        const newBalance = currentBalance + depositAmount;
        const netBalance = newBalance - totalExposure + creditAmt;

        // Update the master balance in Firestore
        await updateDoc(masterRef, { balance: newBalance });

        // Store deposit transaction in Firestore
        const depositRef = collection(db, `accountStmt/godMaster/deposit`);
        await addDoc(depositRef, {
          amount: depositAmount,
          description: `${godUsername}: ${description}`, // God username included in description
          createdAt: new Date().toISOString(),
          transactionId: Date.now().toString(),
          createdBy: godUsername, // Store the username of the god creating the deposit
          balance: newBalance,
          netBal: netBalance, // Add the net balance (total balance)
          masterName: masterName
        });

        alert('Deposit successful.');
        onClose();
      } else {
        console.error('Master not found');
      }
    } catch (error) {
      console.error('Error updating deposit:', error);
      alert('Failed to update deposit. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Deposit for {masterName}</h2>

        {/* Amount Input */}
        <input
          type="number"
          placeholder="Deposit Amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Description Input */}
        <textarea
          placeholder="Enter description..."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
          rows="4"
        />

        {/* Password input field */}
        <input
          type={passwordVisible ? 'text' : 'password'}
          placeholder="God Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? 'Hide' : 'Show'} Password
        </button>

        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositModal;
