import React, { useState } from 'react';
import { doc, updateDoc } from "firebase/firestore";
import { db } from '../../../../firebase';

const FTDBonusModal = ({ masterId, superPassword, onClose }) => {
  const [ftdBonus, setFtdBonus] = useState('');
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);

  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleFTDBonusUpdate = async () => {
    if (enteredPassword !== superPassword) {
      alert("Incorrect god password.");
      return;
    }

    try {
      const masterRef = doc(db, "masters", masterId);
      await updateDoc(masterRef, { ftdBonus });
      alert("FTD Bonus updated successfully.");
      onClose();
    } catch (error) {
      console.error("Error updating FTD Bonus:", error);
      alert("Failed to update FTD Bonus. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">FTD Bonus</h2>

        {/* FTD Bonus Input */}
        <input
          type="number"
          placeholder="FTD Bonus %"
          value={ftdBonus}
          onChange={(e) => setFtdBonus(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* God Password Input */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="God Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? "Hide" : "Show"} Password
        </button>

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleFTDBonusUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default FTDBonusModal;
