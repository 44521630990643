import React, { useState, useEffect } from "react";
import { collection, addDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { query, where, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const WithdrawModal = ({
  userId,
  masterUsername,
  currentAmount,
  onClose,
  clientUsername,
}) => {
  const [amount, setAmount] = useState("");
  const [bonus, setBonus] = useState("");
  const [description, setDescription] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [error, setError] = useState("");
  const [masterPin, setMasterPin] = useState("");
  const [clientExposure, setClientExposure] = useState(0); // Store client exposure

  // Fetch the master pin from the master collection
  const fetchMasterPin = async () => {
    const q = query(
      collection(db, "masters"),
      where("username", "==", masterUsername)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const masterDoc = querySnapshot.docs[0];
      setMasterPin(masterDoc.data().pinNumber);
    } else {
      setError("Master not found.");
    }
  };

  // Fetch the client exposure from the users collection
  const fetchClientExposure = async () => {
    const clientRef = doc(db, "users", userId);
    const clientDoc = await getDoc(clientRef);
    if (clientDoc.exists()) {
      setClientExposure(clientDoc.data().exposure || 0);
    }
  };

  useEffect(() => {
    // fetchMasterPin();
    fetchClientExposure();
  }, [masterUsername, userId]);

  // const handleSubmit = async () =>{
  //   // if (!masterPin) {
  //   //   setError('Ask your super for a pin');
  //   //   return;
  //   // }

  //   // if (!description.trim()) {
  //   //   setError('Description is required');
  //   //   return;
  //   // }

  //   // if (pinCode !== masterPin.toString()) {
  //   //   setError('Incorrect pin code');
  //   //   return;
  //   // }

  //   const q = query(
  //     collection(db, "masters"),
  //     where("username", "==", masterUsername)
  //   );
  //   const querySnapshot = await getDocs(q);

  //   if (!querySnapshot.empty) {
  //     const masterDoc = querySnapshot.docs[0];
  //     const fetchedMasterPin = masterDoc.data().pinNumber; // Fetch the pin from the DB

  //     // Validate the fetched pin
  //     if (!fetchedMasterPin) {
  //       setError("Ask your super for a pin");
  //       return;
  //     }

  //     if (pinCode !== fetchedMasterPin.toString()) {
  //       setError("Incorrect pin code");
  //       return;
  //     }
  //   } else {
  //     setError("Master not found.");
  //     return;
  //   }

  //   if (!description.trim()) {
  //     setError("Description is required");
  //     return;
  //   }

  //   const parsedAmount = parseFloat(amount);
  //   const parsedBonus = parseFloat(bonus) || 0;
  //   const totalWithdraw = parsedAmount + (parsedAmount * parsedBonus) / 100;

  //   if (currentAmount < totalWithdraw) {
  //     setError("Insufficient balance to withdraw");
  //     return;
  //   }

  //   try {
  //     const newBalance = currentAmount - totalWithdraw;
  //     const newBal = newBalance - clientExposure; // Calculate the newBal after subtracting exposure

  //     // Update the user's balance in Firestore
  //     const userRef = doc(db, "users", userId);
  //     await updateDoc(userRef, { amount: newBalance });

  //     // Store the withdrawal transaction in Firestore
  //     const withdrawRef = collection(db, `accountStmt/master/withdraw`);
  //     await addDoc(withdrawRef, {
  //       amount: parsedAmount,
  //       bonus: parsedBonus,
  //       description: `${masterUsername}: ${description}`,
  //       createdAt: new Date().toISOString(),
  //       transactionId: Date.now().toString(),
  //       createdBy: masterUsername,
  //       balance: newBalance,
  //       netBal: newBal, // Store newBal (balance - exposure)
  //       username: clientUsername,
  //     });

  //     setError("");
  //     toast.success("Withdrawal successful");
  //     onClose();
  //   } catch (error) {
  //     console.error("Error adding withdrawal:", error);
  //     setError("Failed to complete withdrawal");
  //   }
  // };
                                     
  const handleSubmit = async () => {
    try {
      // Fetch the master's balance
      const q = query(
        collection(db, "masters"),
        where("username", "==", masterUsername)
      );
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        const masterDoc = querySnapshot.docs[0];
        const masterData = masterDoc.data();
        const masterBalance = parseFloat(masterData.balance) || 0; // Get master's current balance
        const fetchedMasterPin = masterData.pinNumber; // Fetch the pin from the DB
  
        // Validate the fetched pin
        if (!fetchedMasterPin) {
          setError("Ask your super for a pin");
          return;
        }
  
        if (pinCode !== fetchedMasterPin.toString()) {
          setError("Incorrect pin code");
          return;
        }
  
        if (!description.trim()) {
          setError("Description is required");
          return;
        }
  
        const parsedAmount = parseFloat(amount);
        const parsedBonus = parseFloat(bonus) || 0;
        const totalWithdraw = parsedAmount + (parsedAmount * parsedBonus) / 100; // Calculate withdrawal including bonus
  
        // Check if user has enough balance
        if (currentAmount < totalWithdraw) {
          setError("Insufficient balance to withdraw");
          return;
        }
  
        // Calculate the new user's balance after the withdrawal
        const newBalance = currentAmount - totalWithdraw;
        const newBal = newBalance - clientExposure; // Calculate the new net balance after subtracting exposure
  
        // Update the user's balance in Firestore
        const userRef = doc(db, "users", userId);
        await updateDoc(userRef, { amount: newBalance });
  
        // Add the withdrawn amount to the master's balance
        const updatedMasterBalance = masterBalance + totalWithdraw;
        await updateDoc(masterDoc.ref, { balance: updatedMasterBalance });
  
        // Store the withdrawal transaction in Firestore
        const withdrawRef = collection(db, `accountStmt/master/withdraw`);
        await addDoc(withdrawRef, {
          amount: parsedAmount,
          bonus: parsedBonus,
          description: `${masterUsername}: ${description}`,
          createdAt: new Date().toISOString(),
          transactionId: Date.now().toString(),
          createdBy: masterUsername,
          balance: newBalance,
          netBal: newBal, // Store net balance (balance - exposure)
          username: clientUsername,
        });
  
        setError("");
        toast.success("Withdrawal successful");
        onClose();
      } else {
        setError("Master not found.");
      }
    } catch (error) {
      console.error("Error adding withdrawal:", error);
      setError("Failed to complete withdrawal");
    }
  };
  
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-green-500 text-xl mb-4">
          Withdraw from {clientUsername}
        </h2>

        <label className="text-white">Amount:</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="0"
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
        />

        <label className="text-white">Bonus in % (Reversal):</label>
        <input
          type="number"
          value={bonus}
          onChange={(e) => setBonus(e.target.value)}
          placeholder="0"
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
        />

        <label className="text-white">Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description..."
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
        />

        <label className="text-white">Pin Code:</label>
        <input
          type="password"
          value={pinCode}
          onChange={(e) => setPinCode(e.target.value)}
          placeholder="Enter Pin Code"
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
        />

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="bg-green-600 px-4 py-2 rounded-lg text-white"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-600 px-4 py-2 rounded-lg text-white"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default WithdrawModal;
