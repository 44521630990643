import React, { useState, useEffect } from 'react';
import { getDocs, collection } from 'firebase/firestore';
import { db } from '../../firebase';

const SuperList = () => {
  const [supers, setSupers] = useState([]); // Change to an array to hold multiple supers

  useEffect(() => {
    const fetchSupers = async () => {
      const querySnapshot = await getDocs(collection(db, "super"));
      const supersData = [];
      querySnapshot.forEach((doc) => {
        supersData.push({ id: doc.id, ...doc.data() }); // Collect all documents
      });
      setSupers(supersData); // Update state with all supers
    };

    fetchSupers();
  }, []);

  return (
    <div className='bg-black h-screen'>
      <p className='text-white text-2xl text-center pt-10 font-semibold'>List of Supers</p>

      {supers.length > 0 ? (
        <div className="mt-10 px-4 md:px-10 overflow-x-auto">
          <table className="w-full text-white">
            <thead>
              <tr className="bg-gray-800">
                <th className="p-2 text-left">Username</th>
                <th className="p-2 text-left">Phone No</th>
                <th className="p-2 text-left">Balance</th>
                <th className="p-2 text-left">Security Id</th>
              </tr>
            </thead>
            <tbody>
              {supers.map((superUser) => (
                <tr key={superUser.id} className="bg-black border-b border-gray-700">
                  <td className="p-3">{superUser.username}</td>
                  <td className="p-3">{superUser.phone}</td>
                  <td className="p-3">{superUser.balance}</td>
                  <td className="p-3">{superUser.securityId}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p className="text-white text-center mt-10">No supers found.</p>
      )}
    </div>
  );
};

export default SuperList;
