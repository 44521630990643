import React, { useEffect, useState } from 'react';
import { addDoc, collection, doc, getDoc, getDocs, onSnapshot, query, updateDoc, where } from "firebase/firestore";

// import ResetPasswordModal from './otherComp/ResetPasswordModal';
import { db } from '../../../firebase';
import ResetPasswordModal from './MastersModals/ResetPasswordModal';
import StatusModal from './MastersModals/StatusModal';
import RefillBonusModal from './MastersModals/RefillBonusModal';
import FTDBonusModal from './MastersModals/FTDBonusModal';
import DefaultRegModal from './MastersModals/DefaultRegModal';
import GamesModal from './MastersModals/GamesModal';
import ExposureLimitModal from './MastersModals/ExposureLimitModal';
import CreditAmtModal from './MastersModals/CreditAmtModal';
import MasterInfoModal from './MastersModals/MasterInfoModal';
import WithdrawModal from './MastersModals/WithdrawModal';
import DepositModal from './MastersModals/DepositModal';
import { useLocation } from 'react-router-dom';


// ... other imports

const ListOfMastersForGod = () => {
  const [masters, setMasters] = useState([]);
  const [godPassword, setGodPassword] = useState(null);  // Store god password here
  const [selectedMasterId, setSelectedMasterId] = useState(null);
  const [userTotals, setUserTotals] = useState({});  // Store total user data (amount, exposure) for each master

  const [generatedPin, setGeneratedPin] = useState(null);
  
  const location = useLocation()


  // Modal states
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isRefillModalOpen, setIsRefillModalOpen] = useState(false);
  const [isFtdModalOpen, setIsFtdModalOpen] = useState(false);
  const [isDefaultRegModalOpen, setIsDefaultRegModalOpen] = useState(false);
const [isGamesModalOpen, setIsGamesModalOpen] = useState(false);
const [currentGamesSelect, setCurrentGamesSelect] = useState({});
const [isCreditAmtModalOpen, setIsCreditAmtModalOpen] = useState(false);
const [isExposureLimitModalOpen, setIsExposureLimitModalOpen] = useState(false);
const [currentCreditAmt, setCurrentCreditAmt] = useState('');  // Store the current credit amount
const [currentLimit, setCurrentLimit] = useState('');  // Store the current exposure limit
const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);  // State for controlling modal visibility
const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
const [selectedMaster, setSelectedMaster] = useState(null);  // To store the master details for the modals
 

const superUsername = location.state.username; // Assuming you pass the super's username in route state




  

const fetchUserData = async (masterName) => {
  const usersRef = collection(db, "users");  // Reference to the users collection
  const q = query(usersRef, where("createdBy", "==", masterName));  // Query to get users created by the selected master

  const querySnapshot = await getDocs(q);
  let totalAmount = 0;
  let totalExposure = 0;

  querySnapshot.forEach((doc) => {
    const userData = doc.data();
    totalAmount += parseFloat(userData.amount);  // Sum up the amounts
    totalExposure += parseFloat(userData.exposure);  // Sum up the exposures
  });

  return { totalAmount, totalExposure };  // Return the totals
};


  

  useEffect(() => {
    const fetchAllUserTotals = async () => {
      const totals = {};
      for (let master of masters) {
        const data = await fetchUserData(master.username);  // Fetch total amount and exposure for users created by this master
        totals[master.username] = data;  // Store the totals for this master
      }
      setUserTotals(totals);
    };
  
    if (masters.length > 0) {
      fetchAllUserTotals();
    }
  }, [masters]);

  const handleGeneratePin = async (masterId) => {
    const randomPin = Math.floor(1000 + Math.random() * 9000);  // Generate a 4-digit random PIN
    setGeneratedPin(randomPin);  // Set the generated PIN
  
    try {
      const masterRef = doc(db, 'masters', masterId);  // Reference to the master document
      await updateDoc(masterRef, { pinNumber: randomPin });  // Update Firestore with the generated PIN
      console.log("Pin generated and saved successfully.");
    } catch (error) {
      console.error("Error generating PIN:", error);
    }
  };
  
  useEffect(() => {
    if (generatedPin) {
      const timer = setTimeout(() => {
        setGeneratedPin(null);  // Clear the PIN after 10 seconds
      }, 5000);  // 5 seconds
  
      return () => clearTimeout(timer);  // Cleanup the timer if component unmounts or pin changes
    }
  }, [generatedPin]);
  

  const openRefillModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsRefillModalOpen(true);
  };
  
  const closeRefillModal = () => {
    setIsRefillModalOpen(false);
    setSelectedMasterId(null);
  };
  
  const openFtdModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsFtdModalOpen(true);
  };
  
  const closeFtdModal = () => {
    setIsFtdModalOpen(false);
    setSelectedMasterId(null);
  };

  const openDefaultRegModal = (masterId) => {
    setSelectedMasterId(masterId);  // Store the ID of the selected master
    setIsDefaultRegModalOpen(true);
  };
  
  const closeDefaultRegModal = () => {
    setIsDefaultRegModalOpen(false);
    setSelectedMasterId(null);  // Reset selected master ID
  };
  const openCreditAmtModal = (masterId, currentAmt) => {
    setSelectedMasterId(masterId);
    setCurrentCreditAmt(currentAmt);  // Set the current credit amount
    setIsCreditAmtModalOpen(true);
  };
  
  const closeCreditAmtModal = () => {
    setIsCreditAmtModalOpen(false);
    setSelectedMasterId(null);
  };
  
  const openExposureLimitModal = (masterId, currentLimitValue) => {
    setSelectedMasterId(masterId);
    setCurrentLimit(currentLimitValue);  // Set the current limit value
    setIsExposureLimitModalOpen(true);
  };
  
  const closeExposureLimitModal = () => {
    setIsExposureLimitModalOpen(false);
    setSelectedMasterId(null);
  };

  const openInfoModal = (masterId) => {
    setSelectedMasterId(masterId);  // Set the selected master ID
    setIsInfoModalOpen(true);  // Open the modal
  };
  
  const closeInfoModal = () => {
    setIsInfoModalOpen(false);  // Close the modal
    setSelectedMasterId(null);  // Clear the selected master ID
  };

  const openDepositModal = (master) => {
    setSelectedMaster(master);  // Set the selected master
    setIsDepositModalOpen(true);  // Open the deposit modal
  };
  
  const openWithdrawModal = (master) => {
    setSelectedMaster(master);  // Set the selected master
    setIsWithdrawModalOpen(true);  // Open the withdraw modal
  };
  
  const closeModals = () => {
    setIsDepositModalOpen(false);
    setIsWithdrawModalOpen(false);
    setSelectedMaster(null);
  };
  
  

  
  
  

  const openGamesModal = async (masterId) => {
    try {
      const masterRef = doc(db, 'masters', masterId);  // Reference to the specific document
      const masterDoc = await getDoc(masterRef);  // Fetch the document from Firestore
  
      if (masterDoc.exists()) {
        const data = masterDoc.data();
        setCurrentGamesSelect(data.GamesSelect);  // Load the current GamesSelect field
        setSelectedMasterId(masterId);
        setIsGamesModalOpen(true);
      } else {
        console.error('Master not found.');
      }
    } catch (error) {
      console.error('Error fetching master data:', error);
    }
  };
  
  const closeGamesModal = () => {
    setIsGamesModalOpen(false);
    setSelectedMasterId(null);  // Reset selected master ID
  };

  
  const handleDefaultRegSubmit = async ({ toggleState }) => {
    try {
      const masterRef = doc(db, "masters", selectedMasterId);  // Reference to the master document
  
      // Update the "defaultReg" field in Firestore
      await updateDoc(masterRef, { defaultReg: toggleState ? "On" : "Off" });
  
      console.log("Default Reg updated successfully.");
      closeDefaultRegModal();  // Close the modal after a successful update
    } catch (error) {
      console.error("Error updating Default Reg:", error);
      alert("Failed to update Default Reg. Please try again.");
    }
  };
  
  
  
  

  // Fetch God password from Firestore
  const fetchGodPassword = async () => {
    try {
      const godRef = collection(db, "god");
      const querySnapshot = await getDocs(godRef);

      if (!querySnapshot.empty) {
        const godDoc = querySnapshot.docs[0]; // Assuming one god document
        setGodPassword(godDoc.data().password);
      } else {
        console.error("No God password found.");
      }
    } catch (error) {
      console.error("Error fetching God password:", error);
    }
  };

  // Fetch all masters (God can access everything)
  // const fetchMasters = async () => {
  //   const q = query(collection(db, 'masters'));
  //   const querySnapshot = await getDocs(q);
  //   const mastersList = querySnapshot.docs.map((doc) => ({
  //     id: doc.id,
  //     ...doc.data(),  // Include balance, creditAmt, etc.
  //   }));
  //   setMasters(mastersList);
  // };

  const fetchMasters = () => {
    const q = query(collection(db, 'masters'));
  
    // Use onSnapshot and return the unsubscribe function
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const mastersList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setMasters(mastersList);
    }, (error) => {
      console.error("Error fetching masters: ", error);
    });
  
    return unsubscribe;  // Return the unsubscribe function
  };
  
  useEffect(() => {
    const unsubscribe = fetchMasters();  // Call fetchMasters and get the unsubscribe function
  
    return () => {
      unsubscribe();  // Call unsubscribe on component unmount to clean up the listener
    };
  }, []);
  
  
  

    

  // Run once to fetch masters and god password
  useEffect(() => {
    fetchGodPassword();
  }, []);

  const openResetPasswordModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsResetModalOpen(true);
  };

  const closeResetPasswordModal = () => {
    setIsResetModalOpen(false);
    setSelectedMasterId(null);
  };

  const openStatusModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsStatusModalOpen(true);
  };

  const closeStatusModal = () => {
    setIsStatusModalOpen(false);
    setSelectedMasterId(null);
  };

  return (
    <div className="bg-black min-h-screen p-4">
      <h1 className="text-2xl text-center text-white mb-6 font-semibold">List of Masters (God View)</h1>

      {masters.length > 0 ? (
        <div className='overflow-x-auto w-full'>
        <table className="min-w-full text-white">
          <thead className="bg-gray-800">
            <tr>
              {/* ... table headers */}
              
              <th className="p-3 text-left">Super Name</th>
              <th className="p-3 text-left">Master Name</th>
              <th className="p-3 text-left">Transfer</th>

              <th className="p-3 text-left">Password</th>
              <th className="p-3 text-left">Refill%</th>
              <th className="p-3 text-left">FTD%</th>
              <th className="p-3 text-left">Default Reg</th>
              <th className="p-3 text-left">Games Select</th>

              <th className="p-3 text-left">C.Bal</th>
              <th className="p-3 text-left">C.Exposure</th>
              <th className="p-3 text-left">C.Net Bal</th>
              <th className="p-3 text-left">M.Bal</th>
              <th className="p-3 text-left">M.NetBal</th>
              <th className="p-3 text-left">CreditAmt</th>
              <th className="p-3 text-left">TotalBal</th>
              <th className="p-3 text-left">Status</th>
              <th className="p-3 text-left">Exp Limit</th>
              <th className="p-3 text-left">Info</th>
              <th className="p-3 text-left">Action</th>


              {/* ... other columns */}
            </tr>
          </thead>
          <tbody>
            {masters.map((master) => (
              <tr key={master.id} className="bg-black border-b border-gray-700">
                <td className="p-1 text-sm pr-3">{master.title}</td>
                <td className="p-1 text-blue-500 font-bold">{master.username}</td>
                <td className="p-1 flex space-x-1">
        <button
          className="border-4 border-green-700 px-1 py-1 rounded text-white"
          onClick={() => openDepositModal(master)}  // Open Deposit Modal
        >
          D
        </button>
        <button
          className="border-4 border-gray-500 px-1 py-1 rounded text-white"
          onClick={() => openWithdrawModal(master)}  // Open Withdraw Modal
        >
          W
        </button>
      </td>
                <td className="p-1">
                  <button className="border-4 border-red-500 px-1 py-1 rounded text-white"
                    onClick={() => openResetPasswordModal(master.id)}>Reset</button>
                </td>
                <td className="p-1">
  <button
    className="border-4 border-green-500 px-2 py-1 rounded"
    onClick={() => openRefillModal(master.id)}
  >
    {master.refillBonus}
  </button>
</td>

<td className="p-1">
  <button
    className="border-4 border-green-500 px-2 py-1 rounded"
    onClick={() => openFtdModal(master.id)}
  >
    {master.ftdBonus}
  </button>
</td>

<td className="p-1">
  <button
    className="block p-1 border-4 border-yellow-500 rounded"
    onClick={() => openDefaultRegModal(master.id)}
  >
    {master.defaultReg}
  </button>
</td>
<td className="p-1">
  <button
    className="block p-1 border-4 border-yellow-500 rounded"
    onClick={() => openGamesModal(master.id)}
  >
    Games Select
  </button>
</td>
<td className="p-1">
  {userTotals[master.username]?.totalAmount}  {/* Show aggregated total amount */}
</td>

<td className="p-1">
  {userTotals[master.username]?.totalExposure}  {/* Show aggregated total exposure */}
</td>

<td className="p-1">
  <span className="block p-1 text-gray-300 text-lg">
    {(userTotals[master.username]?.totalAmount) - (userTotals[master.username]?.totalExposure)}
  </span>  {/* Show C.Net Bal (C.Bal - C.Exposure) */}
</td>
<td className="p-1">
<span>{master.balance-master.creditAmt}</span>  {/* Show aggregated total exposure */}
</td>
<td className="p-1">
<span>{parseFloat(master.balance-master.creditAmt) - userTotals[master.username]?.totalExposure}</span>  {/* Show aggregated total exposure */}
</td>
              <td className="p-1">
  <button
    className="block p-1 border-4 border-yellow-500 rounded"
    onClick={() => openCreditAmtModal(master.id, master.creditAmt)}  // Pass the current credit amount
  >
    {master.creditAmt}
  </button>
</td>

<td className="p-1">
  <span className="block p-1 text-gray-300 text-lg">
  {(parseFloat(master.balance) - userTotals[master.username]?.totalExposure)}
  </span>  {/* Show C.Net Bal (C.Bal - C.Exposure) */}
</td>



                <td className="p-1">
                  <button className="border-4 border-purple-700 px-1 py-1 rounded text-white"
                    onClick={() => openStatusModal(master.id)}>{master.status}</button>
                </td>
                <td className="p-1">
  <button
    className="block p-1 border-4 border-gray-500 px-2 py-1 rounded"
    onClick={() => openExposureLimitModal(master.id, master.limit)}  // Pass the current exposure limit
  >
    {master.limit}
  </button>
</td>
<td>
  <button
    onClick={() => openInfoModal(master.id)}  // Open modal and pass master ID
    className="bg-gray-600 px-2 py-1 rounded-md"
  >
    Info
  </button>
</td>
<td>
  <button
    onClick={() => handleGeneratePin(master.id)}  // Generate and store the PIN
    className="bg-green-800 px-2 py-1 rounded-md"
  >
    Pin
  </button>
</td>


                {/* ... other columns */}
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      ) : (
        <p className="text-white text-center mt-10">No masters found.</p>
      )}

      {isResetModalOpen && (
        <ResetPasswordModal
          masterId={selectedMasterId}
          onClose={closeResetPasswordModal}
          superPassword={godPassword}  // Replace with godPassword
        />
      )}

      {isStatusModalOpen && (
        <StatusModal
          masterId={selectedMasterId}
          superPassword={godPassword}  // Replace with godPassword
          onClose={closeStatusModal}
        />
      )}

{isRefillModalOpen && (
  <RefillBonusModal
    masterId={selectedMasterId}
    superPassword={godPassword}  // Pass God's password
    onClose={closeRefillModal}
  />
)}

{isFtdModalOpen && (
  <FTDBonusModal
    masterId={selectedMasterId}
    superPassword={godPassword}  // Pass God's password
    onClose={closeFtdModal}
  />
)}

{isDefaultRegModalOpen && (
  <DefaultRegModal
    superPassword={godPassword}  // Pass God's password
    onSubmit={handleDefaultRegSubmit}  // Handle form submission
    onClose={closeDefaultRegModal}  // Handle closing the modal
  />
)}

{isGamesModalOpen && (
  <GamesModal
    masterId={selectedMasterId}
    currentGamesSelect={currentGamesSelect}  // Pass current GamesSelect data
    superPassword={godPassword}  // Pass God's password
    onClose={closeGamesModal}  // Handle closing the modal
  />
)}

{isCreditAmtModalOpen && (
  <CreditAmtModal
    masterId={selectedMasterId}
    superUsername={superUsername}
    currentCreditAmt={currentCreditAmt}  // Pass the current credit amount
    superPassword={godPassword}  // Pass God's password
    onClose={closeCreditAmtModal}
  />
)}

{isExposureLimitModalOpen && (
  <ExposureLimitModal
    masterId={selectedMasterId}
    currentLimit={currentLimit}  // Pass the current exposure limit
    superPassword={godPassword}  // Pass God's password
    onClose={closeExposureLimitModal}
  />
)}

{isInfoModalOpen && (
  <MasterInfoModal
    masterId={selectedMasterId}  // Pass the selected master ID to the modal
    onClose={closeInfoModal}  // Close the modal
  />
)}
{isDepositModalOpen && selectedMaster && (
  <DepositModal
    masterName={selectedMaster.username}
    masterId={selectedMaster.id}
    godPassword={godPassword}  // Pass the god's password
    godUsername={"God"}  // Pass the god's username for createdBy field
    onClose={closeModals}  // Close the modal when needed
  />
)}

{isWithdrawModalOpen && selectedMaster && (
  <WithdrawModal
    masterName={selectedMaster.username}
    masterId={selectedMaster.id}
    godPassword={godPassword}  // Pass the god's password
    godUsername={"God"}  // Pass the god's username for createdBy field
    onClose={closeModals}  // Close the modal when needed
  />
)}

{generatedPin && (
  <div className="fixed top-0 left-0 right-0 bg-yellow-500 text-black text-center p-2">
    Generated PIN: {generatedPin}
  </div>
)}






    </div>
  );
};

export default ListOfMastersForGod;
