import React, { useState, useEffect } from 'react';
import { db } from '../../firebase';  // Make sure the path is correct
import { collection, getDocs, doc, updateDoc, onSnapshot } from 'firebase/firestore';  // Import required Firestore functions
import CreditAmtModal from './Modals/CreditAmtModal';  // Assuming you've stored the modal component in the same folder
import StatusModal from './Modals/StatusModal';
import ExpLimitModal from './Modals/ExpLimitModal';
import PasswordModal from './Modals/PasswordModal';
import WithdrawModal from './Modals/WithdrawModal';
import DepositModal from './Modals/DepositModal';
import InfoModal from './Modals/InfoModal';

const ListOfSuper = () => {
  const [superData, setSuperData] = useState([]);
  const [selectedMaster, setSelectedMaster] = useState(null);  // For handling which master was selected for modal
  const [selectedStatusMaster, setSelectedStatusMaster] = useState(null);  // Separate state for status modal
  const [selectedExpMaster, setSelectedExpMaster] = useState(null);  // Separate state for Exp Limit modal
  const [selectedPasswordMaster, setSelectedPasswordMaster] = useState(null);  // State for password modal
  const [selectedInfoMaster, setSelectedInfoMaster] = useState(null);  // State for Info modal


  const [selectedDepositMaster, setSelectedDepositMaster] = useState(null);  // State for deposit modal
const [selectedWithdrawMaster, setSelectedWithdrawMaster] = useState(null);  // State for withdraw modal


const handleOpenDepositModal = (master) => {
  setSelectedDepositMaster(master);  // Open deposit modal for selected master
};

const handleCloseDepositModal = () => {
  setSelectedDepositMaster(null);  // Close deposit modal
};

const handleOpenWithdrawModal = (master) => {
  setSelectedWithdrawMaster(master);  // Open withdraw modal for selected master
};

const handleCloseWithdrawModal = () => {
  setSelectedWithdrawMaster(null);  // Close withdraw modal
};

const handleOpenInfoModal = (master) => {
  setSelectedInfoMaster(master);  // Open info modal for selected master
};

const handleCloseInfoModal = () => {
  setSelectedInfoMaster(null);  // Close info modal
};






  // Fetch data from Firestore on component mount
  // useEffect(() => {
  //   const fetchSuperData = async () => {
  //     const querySnapshot = await getDocs(collection(db, 'super'));  // Fetch data from Firestore
  //     const data = querySnapshot.docs.map(doc => ({
  //       id: doc.id,
  //       ...doc.data(),
  //       totalBal: doc.data().balance + doc.data().creditAmt  // Calculate total balance
  //     }));
  //     setSuperData(data);
  //   };

  //   fetchSuperData();
  // }, []);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(db, 'super'),  // Listen for changes in the 'super' collection
      (snapshot) => {
        const data = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          totalBal: doc.data().balance + doc.data().creditAmt  // Recalculate total balance
        }));
        setSuperData(data);  // Update state with real-time data
      },
      (error) => {
        console.error('Error fetching real-time data: ', error);  // Handle any errors
      }
    );
  
    return () => unsubscribe();  // Clean up the listener when the component unmounts
  }, []);
  

  // Handle opening of the modal
  const handleOpenModal = (master) => {
    setSelectedMaster(master);
  };
  const handleOpenExpLimitModal = (master) => {
    setSelectedExpMaster(master);  // Store the selected master for Exp Limit modal
  };
  
  const handleCloseExpLimitModal = () => {
    setSelectedExpMaster(null);  // Reset the selected master for Exp Limit modal
  };

  const handleOpenPasswordModal = (master) => {
    setSelectedPasswordMaster(master);  // Open password modal for selected master
  };
  
  const handleClosePasswordModal = () => {
    setSelectedPasswordMaster(null);  // Close the password modal
  };
  
  

  const handleOpenStatusModal = (master) => {
    setSelectedStatusMaster(master);  // Store the selected master for status modal
  };

  const handleCloseStatusModal = () => {
    setSelectedStatusMaster(null);  // Reset the selected master for status modal
  };
  
  

  // Handle closing of the modal
  const handleCloseModal = () => {
    setSelectedMaster(null);
  };

  return (
    <div className="bg-black h-screen p-4">
      <p className="text-white text-center text-xl font-semibold">List Of Super</p>

      <div className="mt-10 px-4">
        <h2 className="text-white text-xl mb-4">Super List</h2>
        <table className="min-w-full text-white">
          <thead>
            <tr className="bg-gray-800">
              <th className="p-2 text-left">Super Name</th>
              <th className="p-2 text-left">Transfer</th>
              <th className="p-2 text-left">Password</th>
              <th className="p-2 text-left">Bal</th>
              {/* <th className="p-2 text-left">Credit Amt</th>
              <th className="p-2 text-left">Total Bal</th> */}
              <th className="p-2 text-left">Status</th>
              <th className="p-2 text-left">Exp Limit</th>
              <th className="p-2 text-left">Setting</th>
              <th className="p-2 text-left">Info</th>
            </tr>
          </thead>
          <tbody>
            {superData.map((master) => (
              <tr key={master.id} className="bg-black border-b border-gray-700">
                <td className="p-1">{master.username}</td>
                <td className="p-1">
  <button
    className="border-4 border-green-700 px-1 py-1 rounded text-white"
    onClick={() => handleOpenDepositModal(master)}  // Open deposit modal
  >
    D
  </button>
  <button
    className="border-4 border-gray-500 px-1 py-1 rounded text-white"
    onClick={() => handleOpenWithdrawModal(master)}  // Open withdraw modal
  >
    W
  </button>
</td>
                <td className="p-1">
  <button
    className="border-4 border-red-500 px-1 py-1 rounded text-white"
    onClick={() => handleOpenPasswordModal(master)}  // Open the password modal
  >
    Reset
  </button>
</td>

                <td className="p-1">
                  <span className="block p-1 text-xl">{master.balance}</span>
                </td>
                {/* <td className="p-1"> */}
                  {/* Credit Amount Button that triggers the modal */}
                  {/* <button
                    className="block p-1 border-4 border-yellow-500 rounded bg-transparent text-white"
                    onClick={() => handleOpenModal(master)}
                  >
                    {master.creditAmt}
                  </button>
                </td>
                <td className="p-1 text-gray-300 text-lg font-semibold">{master.totalBal}</td> */}
                <td className="p-1">
  <button
    className="block p-1 border-4 border-purple-700 rounded"
    onClick={() => handleOpenStatusModal(master)}  // Use the new function to open the status modal
  >
    {master.status}
  </button>
</td>
<td className="p-1">
  <button
    className="block p-1 border-4 border-gray-500 px-2 py-1 rounded"
    onClick={() => handleOpenExpLimitModal(master)}  // Open the Exp Limit modal
  >
    {master.limit}
  </button>
</td>

                <td className="p-2">
                  <button className="bg-gray-600 px-2 py-1 rounded-md">⚙</button>
                </td>
                <td className="p-2">
                <button
  className="bg-gray-600 px-2 py-1 rounded-md"
  onClick={() => handleOpenInfoModal(master)}  // Open info modal on click
>
  Info
</button>

                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Conditionally render the modal if a master is selected */}
      {selectedMaster && (
        <CreditAmtModal
          masterId={selectedMaster.id}
          onClose={handleCloseModal}
        />
      )}

{selectedStatusMaster && (
  <StatusModal
    masterId={selectedStatusMaster.id}
    currentStatus={selectedStatusMaster.status}  // Pass current status to the modal
    onClose={handleCloseStatusModal}  // Close the modal
  />
)}

{selectedExpMaster && (
  <ExpLimitModal
    masterId={selectedExpMaster.id}
    currentLimit={selectedExpMaster.limit}  // Pass current exp limit to the modal
    onClose={handleCloseExpLimitModal}  // Close the modal
  />
)}

{selectedPasswordMaster && (
  <PasswordModal
    masterId={selectedPasswordMaster.id}  // Pass the selected master's ID
    onClose={handleClosePasswordModal}  // Close the modal
  />
)}

{selectedDepositMaster && (
  <DepositModal
    masterId={selectedDepositMaster.id}  // Pass selected master's ID
    currentBalance={selectedDepositMaster.balance}  // Pass current balance
    onClose={handleCloseDepositModal}  // Close the modal
  />
)}

{selectedWithdrawMaster && (
  <WithdrawModal
    masterId={selectedWithdrawMaster.id}  // Pass selected master's ID
    currentBalance={selectedWithdrawMaster.balance}  // Pass current balance
    onClose={handleCloseWithdrawModal}  // Close the modal
  />
)}

{selectedInfoMaster && (
  <InfoModal
    superData={selectedInfoMaster}  // Pass all the data of the selected master
    onClose={handleCloseInfoModal}  // Close the modal
  />
)}





    </div>
  );
};

export default ListOfSuper;
