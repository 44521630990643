import React, { useState } from 'react';
import { addDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../../firebase';

const SuperMasterForm = ({ onClose }) => {
  const [userType, setUserType] = useState('super'); // Default to 'super'
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [limit, setLimit] = useState(50000); // Limit for master
  const [phone, setPhone] = useState('');
  const [balance, setBalance] = useState(); // Using balance instead of limit
  const [securityId, setSecurityId] = useState('');
  const [refillBonus, setRefillBonus] = useState('0');
  const [ftdBonus, setFtdBonus] = useState('0');
  const [passwordError, setPasswordError] = useState('');


  const validateSecurityId = async (enteredSecurityId) => {
    try {
      // Query Firestore to fetch the securityId from the god collection
      const godCollection = collection(db, 'god');
      const querySnapshot = await getDocs(godCollection);
  
      if (!querySnapshot.empty) {
        const godDoc = querySnapshot.docs[0]; // Assuming there's only one god user, or you can add filtering logic
        const godData = godDoc.data();
  
        // Validate the entered security ID with the fetched securityId from Firestore
        if (godData.password === enteredSecurityId) {
          return true; // Validation passed
        } else {
          alert('Invalid Security ID. Please enter the correct Security ID.');
          return false; // Validation failed
        }
      } else {
        alert('God document not found.');
        return false; // No document found
      }
    } catch (error) {
      console.error('Error fetching security ID:', error);
      alert('An error occurred while validating the Security ID.');
      return false;
    }
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validate the required fields
    if (username.trim() === '' || password.trim() === '' || (userType === 'master' && balance === '')) {
      alert('Please fill all the required fields.');
      return;
    }

  // Validate security ID
  const isValidSecurityId = await validateSecurityId(securityId);
  if (!isValidSecurityId) return
  
    // Validate password (at least 6 characters)
    if (password.length <= 5) {
      setPasswordError('Password must have more than 5 characters');
      return;
    }

    const specialCharacterRegex = /[!@#$%^&*(),.?":{}|<>]/;

    if (!specialCharacterRegex.test(password)) {
      setPasswordError('Password must contain at least one special character');
      return;
    }

    const GamesSelect = {
      football: 'off',
      basketball: 'off',
      cricket: 'off',
      tennis: 'off',
      baseball: 'off',
      rugby: 'off',
      hockey: 'off',
    };
  
    // const newUser = {
    //   username,
    //   password,
    //   phone,
    //   role: userType, // 'super' or 'master'
    //   balance, // Default balance
    //   limit, // Include title and limit only for master
    //   creditAmt: 0,
    //   status:"ACTIVE",
    //   title: 'god',
    //   ...(userType === 'master' && { 
    //     refillBonus, 
    //     ftdBonus, 
    //     defaultReg: "Off",
    //   GamesSelect
    //   }),
    // };

    const newUser = {
      username,
      password,
      phone: parseInt(phone, 10) || null, // Ensure phone is stored as an integer
      role: userType, // 'super' or 'master'
      balance: parseFloat(balance) || 0, // Ensure balance is stored as a float
      limit: parseFloat(limit) || 50000, // Ensure limit is stored as a float
      creditAmt: 0, // Ensure credit amount is stored as a float
      status: "ACTIVE",
      title: 'god',
      passwordChanged:false,
      ...(userType === 'master' && { 
        refillBonus: parseFloat(refillBonus) || 0, // Ensure refill bonus is stored as a float
        ftdBonus: parseFloat(ftdBonus) || 0, // Ensure ftd bonus is stored as a float
        defaultReg: "Off",
        GamesSelect,
      }),
    };

    
  
    try {
      // Check if the user already exists by username
      const userCollection = collection(db, userType === 'super' ? 'super' : 'masters');
      const q = query(
        userCollection,
        where('username', '==', newUser.username),
      );
      const querySnapshot = await getDocs(q);
  
      if (!querySnapshot.empty) {
        alert(`A ${userType} with this username already exists.`);
        return;
      }
  
      // Add the new user to Firestore
      const docRef = await addDoc(userCollection, newUser);
      console.log(`Document written with ID: ${docRef.id}`);

      if (userType === 'master') {
        await addDoc(collection(db, 'paymentGateway'), {
          createdBy: username, // Superuser's username
          minDeposit: 50000,
          minWithdraw: 50000,
          withdrawCount: 1,
          updatedAt: new Date().toISOString(),
          withdrawCountChangedAt: new Date().toISOString(),
        });
        // console.log('Payment Gateway entry created successfully!');
      }

      
      alert(`${userType.charAt(0).toUpperCase() + userType.slice(1)} created successfully!`);
      onClose();
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('Failed to create user. Please try again.');
    }
  };
  
  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-full max-w-[90%] md:max-w-[500px] mx-auto max-h-[90vh] overflow-y-auto">
        <h2 className="text-xl font-semibold mb-4 text-center">Create New {userType.charAt(0).toUpperCase() + userType.slice(1)}</h2>
        <form onSubmit={handleSubmit}>
  <div className="mb-4">
    <label className="block mb-2">Select User Type</label>
    <select
      value={userType}
      onChange={(e) => setUserType(e.target.value)}
      className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
    >
      <option value="super">Super</option>
      <option value="master">Master</option>
    </select>
  </div>

  <div className="mb-4">
    <label className="block mb-2">Username <span className="text-white-500 text-xl font-bold">*</span></label>
    <input
      type="text"
      value={username}
      onChange={(e) => setUsername(e.target.value)}
      className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
      required
    />
  </div>

  <div className="mb-4">
    <label className="block mb-2">Password <span className="text-white-500 text-xl font-bold">*</span></label>
    <input
      type="password"
      value={password}
      onChange={(e) => setPassword(e.target.value)}
      className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
      required
    />
    {passwordError && <p className="text-red-500 text-sm">{passwordError}</p>}
  </div>

  {(
    <div className="mb-4">
      <label className="block mb-2">Balance <span className="text-white-500 text-xl font-bold">*</span></label>
      <input
        type="number"
        value={balance}
        onChange={(e) => setBalance(e.target.value)}
        className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
        required
      />
    </div>
  )}

  <div className="mb-4">
    <label className="block mb-2">Phone No:</label>
    <input
      type="number"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
      
    />
  </div>

  <div className="mb-4">
        <label className="block mb-2">Limit <span className="text-white-500 text-xl font-bold">*</span></label>
        <input
          type="number"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
          className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
          required
        />
      </div>
  
  {userType === 'master' && (
    <>
      <div className="mb-4">
        <label className="block mb-2">Refill Bonus %:</label>
        <input
          type="number"
          value={refillBonus}
          onChange={(e) => setRefillBonus(e.target.value)}
          className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
        />
      </div>
      <div className="mb-4">
        <label className="block mb-2">FTD Bonus %:</label>
        <input
          type="number"
          value={ftdBonus}
          onChange={(e) => setFtdBonus(e.target.value)}
          className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
        />
      </div>
      
    </>
  )}

  

  <div className="mb-4">
    <label className="block mb-2">Security Id:</label>
    <input
      type="text"
      value={securityId}
      onChange={(e) => setSecurityId(e.target.value)}
      className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
    />
  </div>

  <div className="flex justify-end space-x-2">
    <button type="submit" className="bg-green-600 text-white px-4 py-2 rounded-md">
      Submit
    </button>
    <button type="button" onClick={onClose} className="bg-red-500 text-white px-4 py-2 rounded-md">
      Close
    </button>
  </div>
</form>

      </div>
    </div>
  );
};

export default SuperMasterForm;
