import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import folder from "../../images/folder.png";

const AccStmt = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleLoad = () =>{
    window.location.reload();
  }

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <h1 className="text-2xl text-center mt-6 font-semibold mb-8">Account Statement</h1>
      <div className="flex justify-between w-3/4 mx-auto">
        <div className="flex flex-col items-start">
          <label className="mb-2 text-lg">Start Date</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4">Excel</button>
        </div>
        <div className="flex flex-col items-start">
          <label className="mb-2 text-lg">End Date</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4" onClick={handleLoad}>Load Report</button>
        </div>
      </div>
      <div className="mt-16 flex justify-center">
        <img
          src={folder}
          alt="Data"
          className="w-48 h-48"
        />
      </div>
    </div>
  );
};

export default AccStmt;
