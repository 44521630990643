import React, { useState, useEffect } from "react";
import { collection, addDoc, doc, updateDoc, getDoc } from "firebase/firestore";
import { db } from "../../../../firebase";
import { query, where, getDocs } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const DepositModal = ({
  userId,
  masterUsername,
  currentAmount,
  onClose,
  clientUsername,
}) => {
  const [amount, setAmount] = useState("");
  const [bonus, setBonus] = useState("");
  const [description, setDescription] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [error, setError] = useState("");
  const [masterPin, setMasterPin] = useState("");
  const [clientExposure, setClientExposure] = useState(0); // Store client exposure

  // Fetch the master pin from the master collection
  const fetchMasterPin = async () => {
    const q = query(
      collection(db, "masters"),
      where("username", "==", masterUsername)
    );
    const querySnapshot = await getDocs(q);
    if (!querySnapshot.empty) {
      const masterDoc = querySnapshot.docs[0];
      setMasterPin(masterDoc.data().pinNumber);
    } else {
      setError("Master not found.");
    }
  };

  // Fetch the client exposure from the users collection
  const fetchClientExposure = async () => {
    const clientRef = doc(db, "users", userId);
    const clientDoc = await getDoc(clientRef);
    if (clientDoc.exists()) {
      setClientExposure(clientDoc.data().exposure || 0);
    }
  };

  useEffect(() => {
    // fetchMasterPin(); // Fetch master pin on component mount
    fetchClientExposure(); // Fetch client exposure on component mount
  }, [masterUsername, userId]);

  // const handleSubmit = async () => {
  //   // if (!masterPin) {
  //   //   setError('Ask your super for a pin');
  //   //   return;
  //   // }

  //   // if (!description.trim()) {
  //   //   setError('Description is required');
  //   //   return;
  //   // }

  //   // if (pinCode !== masterPin.toString()) {
  //   //   setError('Incorrect pin code');
  //   //   return;
  //   // }

  //   try {
  //     const q = query(
  //       collection(db, "masters"),
  //       where("username", "==", masterUsername)
  //     );
  //     const querySnapshot = await getDocs(q);
  //     if (!querySnapshot.empty) {
  //       const masterDoc = querySnapshot.docs[0];
  //       const fetchedMasterPin = masterDoc.data().pinNumber;

  //       // Use the fetched pin in your original validation logic
  //       if (!fetchedMasterPin) {
  //         setError("Ask your super for a pin");
  //         return;
  //       }

  //       // Validate pin code entered by the user
  //       if (pinCode !== fetchedMasterPin.toString()) {
  //         setError("Incorrect pin code");
  //         return;
  //       }
  //     } else {
  //       setError("Master not found.");
  //       return;
  //     }

  //     // Validate description
  //     if (!description.trim()) {
  //       setError("Description is required");
  //       return;
  //     }

  //     const parsedAmount = parseFloat(amount);
  //     const parsedBonus = parseFloat(bonus) || 0;
  //     const newBalance =
  //       currentAmount + parsedAmount + (parsedAmount * parsedBonus) / 100;
  //     const newBal = newBalance - clientExposure; // Calculate the newBal after subtracting exposure

  //     // Update the user's balance in Firestore
  //     const userRef = doc(db, "users", userId);
  //     await updateDoc(userRef, { amount: newBalance });

  //     // Store the deposit transaction in Firestore
  //     const depositRef = collection(db, `accountStmt/master/deposit`);
  //     await addDoc(depositRef, {
  //       amount: parsedAmount,
  //       bonus: parsedBonus,
  //       description: `${masterUsername}: ${description}`,
  //       createdAt: new Date().toISOString(),
  //       transactionId: Date.now().toString(),
  //       createdBy: masterUsername,
  //       balance: newBalance,
  //       netBal: newBal, // Store newBal (balance - exposure)
  //       username: clientUsername,
  //     });

  //     setError("");
  //     toast.success("Deposit successful");
  //     onClose();
  //   } catch (error) {
  //     console.error("Error adding deposit:", error);
  //     setError("Failed to complete deposit");
  //   }
  // };
  
  const handleSubmit = async () => {
    try {
      // Fetch the master's balance
      const q = query(collection(db, "masters"), where("username", "==", masterUsername));
      const querySnapshot = await getDocs(q);
  
      if (querySnapshot.empty) {
        setError("Master not found.");
        return;
      }
  
      const masterDoc = querySnapshot.docs[0]; // Get the master document
      const masterData = masterDoc.data();
      const masterBalance = parseFloat(masterData.balance) || 0; // Get master's current balance
      const fetchedMasterPin = masterData.pinNumber;
  
      // Validate pin code entered by the user
      if (!fetchedMasterPin || pinCode !== fetchedMasterPin.toString()) {
        setError("Incorrect pin code");
        return;
      }
  
      // Validate description
      if (!description.trim()) {
        setError("Description is required");
        return;
      }
  
      const parsedAmount = parseFloat(amount);
      const parsedBonus = parseFloat(bonus) || 0;
  
      // Calculate the amount including bonus
      const totalDepositAmount = parsedAmount + (parsedAmount * parsedBonus) / 100;
  
      // Check if master has enough balance
      if (masterBalance < totalDepositAmount) {
        setError("Master has insufficient balance for this deposit.");
        return;
      }
  
      // Calculate the new user's balance after deposit
      const newBalance = currentAmount + totalDepositAmount;
      const newBal = newBalance - clientExposure; // Calculate the newBal after subtracting exposure
  
      // Update the user's balance in Firestore
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, { amount: newBalance });
  
      // Subtract the deposited amount (including bonus) from the master's balance
      const updatedMasterBalance = masterBalance - totalDepositAmount;
      await updateDoc(masterDoc.ref, { balance: updatedMasterBalance });
  
      // Store the deposit transaction in Firestore
      const depositRef = collection(db, `accountStmt/master/deposit`);
      await addDoc(depositRef, {
        amount: totalDepositAmount, // Use the total deposit amount including bonus
        bonus: parsedBonus,
        description: `${masterUsername}: ${description}`,
        createdAt: new Date().toISOString(),
        transactionId: Date.now().toString(),
        createdBy: masterUsername,
        balance: newBalance,
        netBal: newBal, // Store newBal (balance - exposure)
        username: clientUsername,
      });
  
      setError("");
      toast.success("Deposit successful");
      onClose();
    } catch (error) {
      console.error("Error adding deposit:", error);
      setError("Failed to complete deposit");
    }
  };
  
  


  

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
        <h2 className="text-green-500 text-xl mb-4">
          Deposit to {clientUsername}
        </h2>

        <label className="text-white">Amount:</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="0"
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
        />

        <label className="text-white">Bonus in %:</label>
        <input
          type="number"
          value={bonus}
          onChange={(e) => setBonus(e.target.value)}
          placeholder="0"
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
        />

        <label className="text-white">Description:</label>
        <textarea
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          placeholder="Enter description..."
          required
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
        />

        <label className="text-white">Pin Code:</label>
        <input
          type="password"
          value={pinCode}
          onChange={(e) => setPinCode(e.target.value)}
          placeholder="Enter Pin Code"
          className="w-full p-2 rounded-lg bg-gray-700 text-white mb-4"
        />

        {error && <p className="text-red-500 mb-4">{error}</p>}

        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="bg-green-600 px-4 py-2 rounded-lg text-white"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-600 px-4 py-2 rounded-lg text-white"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DepositModal;
