import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

const StatusModal = ({ masterId, superPassword, onClose }) => {
  const [status, setStatus] = useState('ACTIVE');  // Default value for the dropdown
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);

  // Toggle password visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Handle form submission and update Firestore
  const handleStatusUpdate = async () => {
    if (enteredPassword !== superPassword) {
      setError('Incorrect god password.');
      return;
    }

    try {
      const masterRef = doc(db, 'masters', masterId);
      await updateDoc(masterRef, { status });  // Update status in Firestore
      alert('Status updated successfully.');
      onClose();
    } catch (error) {
      console.error('Error updating status:', error);
      alert('Failed to update status. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Account Status</h2>

        {/* Dropdown for account status */}
        <label className="text-white mb-2 block">Account Status:</label>
        <select
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          className={`w-full p-2 rounded border focus:outline-none mb-4 text-white bg-gray-700 border-gray-400 appearance-none 
          ${status === 'ACTIVE' ? 'border-green-500' : status === 'SUSPENDED' ? 'border-yellow-500' : 'border-red-500'}`}
        >
          <option value="ACTIVE" className="font-semibold">ACTIVE</option>
          <option value="SUSPENDED" className="font-semibold">SUSPENDED</option>
          <option value="CLOSED" className="font-semibold">CLOSED</option>
        </select>

        {/* Password input field */}
        <input
          type={passwordVisible ? 'text' : 'password'}
          placeholder="God Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? 'Hide' : 'Show'} Password
        </button>

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleStatusUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg hover:bg-green-600"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg hover:bg-red-600"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default StatusModal;
