import React from 'react';
import { useNavigate } from 'react-router-dom';

const SuspendedPage = () => {
    
    const navigate= useNavigate()

    const handleLogin = () =>{
        navigate("/")
    }

  return (
    
    <div className="flex items-center justify-center h-screen bg-gray-900">
      <div className="bg-gray-800 flex flex-col justify-center p-16 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-2xl text-white mb-6 text-center">Account Suspended</h2>
        <p className="text-white text-center mb-4">
          Your account is currently suspended. Please contact upper officials for more information.
        </p>
        <button onClick={handleLogin} 
        className='bg-gray-400 p-2 rounded-lg mt-3 font-semibold hover:bg-gray-500'>Back to login</button>
      </div>
    </div>
  );
};

export default SuspendedPage;
