import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase';

const AcctStmtGod = () => {
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        // Fetch deposit and withdraw data
        const depositRef = collection(db, 'accountStmt', 'god', 'deposit');
        const withdrawRef = collection(db, 'accountStmt', 'god', 'withdraw');

        const depositQuery = query(depositRef, orderBy('createdTime', 'desc'));
        const withdrawQuery = query(withdrawRef, orderBy('createdTime', 'desc'));

        const depositSnapshot = await getDocs(depositQuery);
        const withdrawSnapshot = await getDocs(withdrawQuery);

        // Combine deposit and withdraw data
        const depositData = depositSnapshot.docs.map((doc) => ({
          ...doc.data(),
          type: 'Deposit',
          id: doc.id,
        }));

        const withdrawData = withdrawSnapshot.docs.map((doc) => ({
          ...doc.data(),
          type: 'Withdraw',
          id: doc.id,
        }));

        // Merge and sort by date
        const combinedData = [...depositData, ...withdrawData].sort(
          (a, b) => b.createdTime.toMillis() - a.createdTime.toMillis()
        );

        // Fetch creditAmt for each super and add it to balance
        const enrichedData = await Promise.all(
          combinedData.map(async (transaction) => {
            const superRef = doc(db, 'super', transaction.superName);  // Fetch super data by username
            const superSnap = await getDoc(superRef);

            let creditAmt = 0;
            if (superSnap.exists()) {
              creditAmt = superSnap.data().creditAmt;  // Fetch creditAmt, default to 0 if not found
            }

            // Add creditAmt to the balance
            const updatedBalance = transaction.balance + creditAmt;
            // console.log(creditAmt)

            return {
              ...transaction,
              creditAmt,       // Store creditAmt for display
              updatedBalance,  // Store the updated balance
            };
          })
        );

        setTransactions(enrichedData);
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
    };

    fetchTransactions();
  }, []);

  return (
    <div className="bg-black h-screen p-4">
      <h2 className="text-white text-xl font-semibold text-center mb-6">Super Account Statement</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full text-white table-auto text-sm">  {/* Smaller text size */}
          <thead>
            <tr className="bg-gray-800">
              <th className="p-2 text-left">Trans ID</th>
              <th className="p-2 text-left">Super Username</th>
              <th className="p-2 text-left">Settled Date</th>
              <th className="p-2 text-left w-1/2">Description</th>  {/* More space for description */}
              <th className="p-2 text-left">Withdraw</th>
              <th className="p-2 text-left">Deposit</th>
              {/* <th className="p-2 text-left">Net Balance</th> */}
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction) => (
              <tr key={transaction.id} className="border-b border-gray-700">
                <td className="p-2">{transaction.transactionId}</td>
                <td className="p-2">{transaction.superName}</td>
                <td className="p-2">
                  {new Date(transaction.createdTime.toMillis()).toLocaleString()}
                </td>
                <td className="p-2 break-all">{transaction.description}</td>  {/* Ensure description can wrap */}
                <td className="p-2 text-red-500">
                  {transaction.type === 'Withdraw' ? transaction.amount?.toFixed(2) : '0.00'}
                </td>
                <td className="p-2 text-green-500">
                  {transaction.type === 'Deposit' ? transaction.amount?.toFixed(2) : '0.00'}
                </td>
                {/* <td className="p-2 text-yellow-500 font-bold">{transaction.netBal ? transaction.netBal.toFixed(2) : '0.00'}</td>  
                Updated balance with creditAmt */}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AcctStmtGod;
