import React, { useEffect, useState } from "react";
import { collection, getDocs, query, orderBy, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useLocation } from "react-router-dom";

const AcctStmtMaster = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true); // Add loading state
  const location = useLocation();

  // Function to fetch all master transactions (withdraw + deposit)
  const fetchTransactions = async () => {
    try {
      const masterUsername = location.state?.username;
      console.log("Fetching transactions for: ", masterUsername);

      if (!masterUsername) {
        console.error("Master username is missing from route state");
        setLoading(false);
        return;
      }

      const withdrawRef = collection(db, `accountStmt/master/withdraw`);
      const withdrawQuery = query(
        withdrawRef,
        where("createdBy", "==", masterUsername),
        orderBy("createdAt", "desc")
      );
      const withdrawSnapshot = await getDocs(withdrawQuery);
      const withdrawTransactions = withdrawSnapshot.docs.map((doc) => ({
        id: doc.id,
        type: "withdraw",
        ...doc.data(),
      }));

      console.log("Withdraw Transactions: ", withdrawTransactions); // Log transactions

      const depositRef = collection(db, `accountStmt/master/deposit`);
      const depositQuery = query(
        depositRef,
        where("createdBy", "==", masterUsername),
        orderBy("createdAt", "desc")
      );
      const depositSnapshot = await getDocs(depositQuery);
      const depositTransactions = depositSnapshot.docs.map((doc) => ({
        id: doc.id,
        type: "deposit",
        ...doc.data(),
      }));

      console.log("Deposit Transactions: ", depositTransactions); // Log transactions

      const allTransactions = [...withdrawTransactions, ...depositTransactions];
      const sortedTransactions = allTransactions.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setTransactions(sortedTransactions);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching transactions:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTransactions(); // Fetch the transactions when component mounts
  }, []);

  return (
    <div className="bg-black h-screen p-6">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-white text-2xl font-semibold">
          Master Account Statement
        </h1>
        <button className="bg-blue-600 text-white px-4 py-2 rounded-lg">
          Excel
        </button>
      </div>
      {loading ? (
        // Loading spinner
        <div className="flex justify-center items-center h-full">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      ) : (
        <div className="overflow-x-auto max-h-screen">
          <table className="min-w-full text-white border-collapse text-sm ">
            {" "}
            {/* Adjust text size here */}
            <thead>
              <tr className="bg-gray-800 text-left">
                <th className="p-3">Trans ID</th>
                <th className="p-3">Client Username</th>
                <th className="p-3">Settled Date</th>
                <th className="p-3 w-1/3">Description</th>{" "}
                {/* Adjust width for description */}
                <th className="p-3">Bonus %</th>
                <th className="p-3">Withdraw</th>
                <th className="p-3">Deposit</th>
                <th className="p-3">Net Balance</th>
              </tr>
            </thead>
            <tbody>
              {transactions.length > 0 ? (
                transactions.map((transaction, index) => {
                  const isDebit = transaction.type === "withdraw";
                  const isCredit = transaction.type === "deposit";
                  const balance = transaction.balance || 0;
                  const bonus = transaction.bonus || 0; // Bonus field

                  return (
                    <tr
                      key={index}
                      className="bg-black border-b border-gray-700"
                    >
                      <td className="p-3">{transaction.transactionId}</td>
                      <td className="p-3">{transaction.username}</td>
                      <td className="p-3">
                        {new Date(transaction.createdAt).toLocaleString()}
                      </td>
                      <td className="p-3 w-1/3">{transaction.description}</td>{" "}
                      {/* Wider description */}
                      <td className="p-3">{bonus.toFixed(2)}%</td>{" "}
                      {/* Show Bonus */}
                      <td className="p-3 text-red-500">
                        {isDebit ? transaction.amount.toFixed(2) : "0.00"}
                      </td>
                      <td className="p-3 text-green-500">
                        {isCredit ? transaction.amount.toFixed(2) : "0.00"}
                      </td>
                      <td className="p-3 text-yellow-500 font-bold">
                        {transaction.netBal
                          ? transaction.netBal.toFixed(2)
                          : "0.00"}
                      </td>
                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="8" className="text-center p-4 text-gray-300">
                    No transactions found.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default AcctStmtMaster;
