import React, { useEffect, useState } from 'react';
import { FaSearch } from "react-icons/fa";
import { db } from '../../firebase'; // Import Firestore
import { collection, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ListUsers = () => {
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [currentUser, setCurrentUser] = useState({ username: '', status: 'ACTIVE', password: '' });
  const [isUpdateNewsModalOpen, setIsUpdateNewsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [statusFilter, setStatusFilter] = useState("ALL");
  const [searchType, setSearchType] = useState("UserName"); // Add this state for search type
  const [searchValue, setSearchValue] = useState(""); // Add this state for search input

  const [isAmountModalOpen, setIsAmountModalOpen] = useState(false);
const [currentUserAmount, setCurrentUserAmount] = useState(null);

const [isExposureLimitModalOpen, setIsExposureLimitModalOpen] = useState(false);
const [currentExposureLimit, setCurrentExposureLimit] = useState('');
const [currentUserExposureId, setCurrentUserExposureId] = useState(null);
const [confirmPassword, setConfirmPassword] = useState(''); // Add this to manage confirm password
 // ID of the user for which the exposure limit is being edited
 const [loading, setLoading] = useState(true); // Add loading state


const location = useLocation()


const handleShowAmountModal = async (id, username) => {
  try {
    const userDocRef = doc(db, 'users', id);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      setCurrentUser({ username: username });
      setCurrentUserAmount(userData.amount); // Assuming 'amount' is the field name in your DB
      setIsAmountModalOpen(true); // Open the modal
    }
  } catch (error) {
    console.error('Error fetching user data: ', error);
  }
};


const handleOpenExposureLimitModal = (id, exposureLimit) => {
  setCurrentUserExposureId(id);
  setCurrentExposureLimit(exposureLimit);
  setIsExposureLimitModalOpen(true);
};


  // useEffect(() => {
  //   const fetchUsers = async () => {
  //     const querySnapshot = await getDocs(collection(db, 'users'));
  //     const usersList = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  //     setUsers(usersList);
  //   };
  //   fetchUsers();
  // }, []);


  useEffect(() => {
    const fetchMastersAndUsers = async () => {
      setLoading(true); // Start loading
      try {
        // Fetch the masters created by the logged-in super user
        const loggedInSuper = location.state?.username // Or another field that represents the logged-in user
  
        const mastersRef = collection(db, 'masters');
        const masterQuery = query(mastersRef, where('title', '==', loggedInSuper)); // Fetch all masters created by the logged-in super
  
        const masterSnapshot = await getDocs(masterQuery);
        const mastersData = masterSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        
        // Extract the usernames of those masters
        const masterUsernames = mastersData.map(master => master.username);
  
        // Now fetch users that were created by those masters
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
  
        // Filter users based on the 'createdBy' field
        const filteredUsers = usersSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => masterUsernames.includes(user.createdBy));
  
        // Set the filtered users to the state
        setUsers(filteredUsers);
        setLoading(false); // Data has been fetched, stop loading

      } catch (error) {
        console.error('Error fetching masters and users:', error);
        setLoading(false); // Data has been fetched, stop loading

      }
    };
  
    fetchMastersAndUsers();
  }, [location.state?.username]); // Dependency on logged-in username
  


  
  

  // Filter users based on status and search criteria
  const filteredUsers = users.filter(user => {
    // Status filter
    if (statusFilter !== "ALL" && user.status !== statusFilter) {
      return false;
    }
    // Search filter based on selected search type
    if (searchType === "UserName" && searchValue && !user.username.toLowerCase().includes(searchValue.toLowerCase())) {
      return false;
    }
    if (searchType === "Phone" && searchValue && !user.phoneNumber.includes(searchValue)) {
      return false;
    }
    
    return true;
  });

  const handleOpenUpdateNewsModal = () => {
    setIsUpdateNewsModalOpen(true);
  };

  const handleCloseUpdateNewsModal = () => {
    setIsUpdateNewsModalOpen(false);
  };

  const handleOpenResetModal = (username, id) => {
    setCurrentUser({ username: username, password: '', id: id });
    setIsResetModalOpen(true);
  };

  const handleCloseResetModal = () => {
    setIsResetModalOpen(false);
  };

  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
  };

  const handleLoad = () => {
    window.location.reload();
  };

  const handleSubmitStatus = async () => {
    const userDocRef = doc(db, 'users', currentUser.id);  // currentUser should have the user's Firestore ID
    await updateDoc(userDocRef, { status: currentUser.status });
  
    // Update the UI after Firestore update
    setUsers(users.map(user => user.id === currentUser.id ? { ...user, status: currentUser.status } : user));
  
    setIsStatusModalOpen(false);
  };

  const handleOpenStatusModal = (username, status, id) => {
    setCurrentUser({ username: username, status: status, id: id });
    setIsStatusModalOpen(true);
  };

  const handleSubmitPassword = async () => {
    try {
      const userDocRef = doc(db, 'users', currentUser.id);

      const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/; // Regex for special characters
      if (!currentUser.password || currentUser.password < 6 || !specialCharRegex.test(currentUser.password)) {
        toast.error('Password must be at least 6 characters long and contain at least one special character.');
        return;
      }

      if (currentUser.password !== confirmPassword) {
        toast.error('Passwords do not match.');
        return; // Stop further execution if passwords do not match
      }




      await updateDoc(userDocRef, { password: currentUser.password });

     
      
  
      setUsers(users.map(user => user.id === currentUser.id ? { ...user, password: currentUser.password } : user));
      setIsResetModalOpen(false);
    } catch (error) {
      console.error('Error updating password:', error);
    }
  };

  const handleSearch = () => {
    // This function is triggered when the user clicks on the search button
    // You don't need to do anything here because the filtering is handled automatically
    // based on the `searchValue` and `searchType` states
  };

  const handleSubmitExposureLimit = async () => {
    try {
      const userDocRef = doc(db, 'users', currentUserExposureId);
      
      // Update Firestore
      await updateDoc(userDocRef, { exposureLimit: currentExposureLimit });
  
      // Update the UI
      setUsers(users.map(user => user.id === currentUserExposureId ? { ...user, exposureLimit: currentExposureLimit } : user));
  
      // Close the modal
      setIsExposureLimitModalOpen(false);
    } catch (error) {
      console.error('Error updating exposure limit: ', error);
    }
  };
  

  return (
    <div className="p-4 bg-black h-screen">
 {loading ? (
      // Display a loading spinner while data is being fetched
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-500"></div>
      </div>
    ) :
      (<div>
      <div className="flex justify-between items-center mb-4 flex-wrap">
        <div className="space-x-2 m-auto md:m-0">
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md">Excel</button>
          
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md" onClick={handleLoad}>Load</button>
        </div>
        <div className="flex flex-col items-center m-auto mt-10 md:mt-10">
          <h1 className="text-white text-xl font-semibold">List Of Users</h1>
        </div>
        <div>
          <div className="flex space-x-2 items-center mt-2 md:mt-0">
            <select
              className="bg-black border-[1px] border-gray-500 text-white px-2 py-1 rounded-md"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)} // Update the statusFilter state
            >
              <option value="ALL">ALL</option>
              <option value="ACTIVE">ACTIVE</option>
              <option value="SUSPENDED">SUSPENDED</option>
              <option value="CLOSED">CLOSED</option>
            </select>

            <select
              className="bg-black border-[1px] border-gray-500 text-white px-2 py-1 rounded-md"
              value={searchType}
              onChange={(e) => setSearchType(e.target.value)} // Update search type (UserName, Phone#, Email)
            >
              <option value="UserName">UserName</option>
              <option value="Phone#">Phone</option>
            </select>
          </div>
          <div>
            <input
              type="text"
              className="bg-black border-[1px] mt-2 border-gray-500 text-white px-2 py-1 rounded-md"
              placeholder={`Search By ${searchType}`} // Dynamic placeholder based on search type
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)} // Update search input value
            />
            <button className="text-white pl-2" onClick={handleSearch}>
              <FaSearch />
            </button>
          </div>
        </div>
      </div>

      {/* Responsive Table Container */}
      <div className="w-full overflow-x-auto mt-10">
        <table className="min-w-full bg-black text-white">
          <thead>
            <tr className="w-full border-b border-gray-700">
              <th className="px-4 py-2 text-left">Master Name</th>
              <th className="px-4 py-2 text-left">User's Name</th>
              <th className="px-4 py-2 text-left">Status</th>
              <th className="px-4 py-2 text-left">Password</th>
              <th className="px-4 py-2 text-left">Exposure Limit</th>
              <th className="px-4 py-2 text-left">Registered On</th>
              <th className="px-4 py-2 text-left">PhoneNo</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.map((user, index) => (
              <tr key={index} className="border-b border-gray-700">
                <td className="px-4 py-2">{user.createdBy}</td> {/* Master Name */}
                <div className='pb-3'>
                <td className="px-4 py-2 text-blue-300 font-semibold">{user.username}</td>
                <button 
                 className="border-green-600 border-4 text-white px-2 rounded-md"
                 onClick={() => handleShowAmountModal(user.id, user.username)}
               >
                 Show Bal
               </button>
               </div>
                <td className="px-4 py-2">
                  <button
                    onClick={() => handleOpenStatusModal(user.username, user.status, user.id)}
                    className={`px-4 py-1 rounded-md text-white
                      ${user.status === 'ACTIVE' ? 'border-green-600 border-4' :
                      user.status === 'SUSPENDED' ? 'border-yellow-600 border-4' :
                      'border-red-600 border-4'}`}
                  >
                    {user.status}
                  </button>
                </td>
                <td className="px-4 py-2">
                  <button 
                    onClick={() => handleOpenResetModal(user.username, user.id)} 
                    className="border-red-600 border-4 text-white px-4 py-1 rounded-md"
                  >
                    Reset
                  </button>
                </td>
                <td className="px-4 py-2">
  <button
    onClick={() => handleOpenExposureLimitModal(user.id, user.exposureLimit)}
    className="text-white border-4 border-gray-600 px-2 py-1 rounded-md"
  >
    {user.exposureLimit}
  </button>
</td>
                <td className="px-4 py-2">{new Date(user.createdAt).toLocaleString()}</td>
                <td className="px-4 py-2">{user.phoneNumber}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      </div>)}

       {/* Reset Password Modal */}
       {isResetModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4 text-center">Reset Password</h2>
            <div className="mb-4">
  <label className="block mb-2">Enter New Password</label>
  <input
    type="password"
    value={currentUser.password}
    onChange={(e) => setCurrentUser({ ...currentUser, password: e.target.value })}
    className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
  />
</div>
            <div className="mb-4">
              <label className="block mb-2">Confirm Password</label>
              <input
  type="password"
  value={confirmPassword}
  onChange={(e) => setConfirmPassword(e.target.value)} // Set the confirmPassword state
  className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
/>

            </div>
            <div className="flex justify-end space-x-2">
              <button onClick={handleSubmitPassword} className="bg-green-600 text-white px-4 py-2 rounded-md">Submit</button>
              <button onClick={handleCloseResetModal} className="bg-red-500 text-white px-4 py-2 rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      )}

{isExposureLimitModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
      <h2 className="text-xl font-semibold mb-4 text-center">
        Exposure Limit of {currentUser.username}
      </h2>
      <div className="mb-4">
        <label className="block mb-2">Limit:</label>
        <input
          type="text"
          value={currentExposureLimit}
          onChange={(e) => setCurrentExposureLimit(e.target.value)}
          className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
        />
      </div>
      <div className="flex justify-end space-x-2">
        <button onClick={handleSubmitExposureLimit} className="bg-green-600 text-white px-4 py-2 rounded-md">
          Submit
        </button>
        <button onClick={() => setIsExposureLimitModalOpen(false)} className="bg-red-500 text-white px-4 py-2 rounded-md">
          Cancel
        </button>
      </div>
    </div>
  </div>
)}


      


      {/* Update News Modal */}
      {isUpdateNewsModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4 text-center">Update News</h2>
            <div className="mb-4">
              <label className="block mb-2">Language:</label>
              <select className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1">
                <option value="ENGLISH">ENGLISH</option>
              </select>
            </div>
            <div className="mb-4">
              <label className="block mb-2">News:</label>
              <textarea
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
                rows="4"
              ></textarea>
            </div>
            <div className="flex justify-end space-x-2">
              <button className="bg-green-600 text-white px-4 py-2 rounded-md">Submit</button>
              <button onClick={handleCloseUpdateNewsModal} className="bg-red-500 text-white px-4 py-2 rounded-md">Close</button>
            </div>
          </div>
        </div>
      )}

{isAmountModalOpen && (
  <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
      <h2 className="text-xl font-semibold mb-4 text-center">{currentUser.username}</h2>
      <div className="mb-4">
        <p>Amount: <span className="text-green-500">{currentUserAmount}</span></p>
      </div>
      <div className="flex justify-end">
        <button 
          onClick={() => setIsAmountModalOpen(false)} 
          className="bg-red-500 text-white px-4 py-2 rounded-md"
        >
          Close
        </button>
      </div>
    </div>
  </div>
)}


      {/* Account Status Modal */}
      {isStatusModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4 text-center">Account Status of {currentUser.username}</h2>
            <div className="mb-4">
              <label className="block mb-2">Account Status:</label>
              <select 
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
                value={currentUser.status}
                onChange={(e) => setCurrentUser({ ...currentUser, status: e.target.value })}
              >
                <option value="ACTIVE">ACTIVE</option>
                <option value="SUSPENDED">SUSPENDED</option>
                <option value="CLOSED">CLOSED</option>
              </select>
            </div>
            <div className="flex justify-end space-x-2">
              <button onClick={handleSubmitStatus} className="bg-green-600 text-white px-4 py-2 rounded-md">Submit</button>
              <button onClick={handleCloseStatusModal} className="bg-red-500 text-white px-4 py-2 rounded-md">Cancel</button>
            </div>
          </div>
        </div>
      )}

    </div>
  );
};

export default ListUsers;
