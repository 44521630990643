import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  collection,
  doc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase";

const WithdrawAll = () => {
  const location = useLocation();
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 15); // Subtract 15 days
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [heading, setHeading] = useState("Withdraw States");
  const [withdrawData, setWithdrawData] = useState([]);
  const [status, setStatus] = useState("Action");
  const [lastWithdrawalId, setLastWithdrawalId] = useState(null);
  const notificationSound = new Audio("/assets/notification.wav");

  

  // Set heading dynamically based on location state
  useEffect(() => {
    if (location.state && location.state.heading) {
      setHeading(location.state.heading);
    }
  }, [location.state]);

  // Fetch withdraw data based on date range
  useEffect(() => {
    const getQuery = query(
      collection(db, "transaction/withdraw/user"),
      where("timestamp", ">=", startDate),
      where("timestamp", "<=", endDate),
      orderBy("timestamp", "desc")
    );
    const unsubscribe = onSnapshot(getQuery, (snapshot) => {
      const withdraws = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      if (
        withdraws.length !== withdrawData.length ||
        withdraws.some((w, index) => w.id !== withdrawData[index]?.id)
      ) {
        notificationSound
          .play()
          .catch((error) => console.error("Audio playback failed:", error));
        setWithdrawData(withdraws); // Update state to trigger rerender
      }

      setWithdrawData(withdraws);
    });
    return () => unsubscribe();
  }, [db, startDate, endDate, lastWithdrawalId, notificationSound]);

  // Handle action selection from dropdown
  async function handleAction(e, t) {
    const selectedAction = e.target.value;
    await updateDoc(doc(db, `transaction/withdraw/user/${t.id}`), {
      ...t,
      orderStatus: selectedAction,
    });

    if (selectedAction === "Accept") {
      const usersCollection = collection(db, "users");
      const q = query(usersCollection, where("username", "==", t.holderName));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userData = userDoc.data();
        const currentAmount = userData.amount || 0;
        const newAmount = currentAmount - t.amount;

        if (newAmount < 0) {
          alert("Insufficient funds");
          return;
        }

        await updateDoc(userDoc.ref, { amount: newAmount });
        alert("Amount updated successfully!");
      } else {
        console.log("User not found");
      }
    } else {
      alert("Order status updated");
    }
  }

  const handleLoad = () => {
    // Reload the report data
    window.location.reload();
  };

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <h1 className="text-2xl text-center mt-6 font-semibold mb-8">
        {heading}
      </h1>
      <div className="flex flex-col md:flex-row justify-between w-full md:w-3/4 mx-auto">
        <div className="flex flex-col items-start mb-4 md:mb-0">
          <label className="mb-2 text-lg">Start Date and Time</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4">
            Excel
          </button>
        </div>
        <div className="flex flex-col items-start">
          <label className="mb-2 text-lg">End Date and Time</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button
            className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4"
            onClick={handleLoad}
          >
            Load Report
          </button>
        </div>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 text-white rounded-lg mt-10 pl-4 md:pl-10">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Account Number
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Bank Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Branch Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Holder Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                IFSC Code
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Payment Mode
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Order Status
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Option
              </th>
            </tr>
          </thead>
          <tbody>
            {withdrawData?.map((d, index) => (
              <tr key={index} className="bg-gray-800 border-b border-gray-700">
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.accountNum}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.bankName}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.amount}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.branchName}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.holderName}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.ifscCode}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.payMode}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  {d?.orderStatus}
                </td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  <div className="relative inline-block text-left">
                    <select
                      value={status}
                      onChange={(e) => handleAction(e, d)}
                      className="bg-transparent text-white"
                    >
                      <option disabled className="bg-transparent text-black">
                        Action
                      </option>
                      <option
                        value="pending"
                        className="bg-transparent text-black"
                      >
                        Pending
                      </option>
                      <option
                        value="Accept"
                        className="bg-transparent text-black"
                      >
                        Accept
                      </option>
                      <option
                        value="Reject"
                        className="bg-transparent text-black"
                      >
                        Reject
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WithdrawAll;