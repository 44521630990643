import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { collection, getDocs, query, where, orderBy } from 'firebase/firestore';
import { db } from '../../firebase';

const ClientTotal = () => {
  const location = useLocation();
  const [startDate, setStartDate] = useState(() => {
    const date = new Date();
    date.setDate(date.getDate() - 15); // Set default to 15 days back
    return date;
  });
  const [endDate, setEndDate] = useState(new Date());
  const [heading, setHeading] = useState("DW States"); // Default heading
  const [users, setUsers] = useState([]);
  const [totalDeposits, setTotalDeposits] = useState(0);
  const [totalWithdrawals, setTotalWithdrawals] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [grandTotal, setGrandTotal] = useState(0);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    if (location.state && location.state.heading) {
      setHeading(location.state.heading);
    }
  }, [location.state]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true); // Start loading
      try {
        const loggedInSuper = location.state?.username; // Get the logged-in super's username
  
        // Step 1: Fetch the masters created by the logged-in super
        const mastersRef = collection(db, 'masters');
        const masterQuery = query(mastersRef, where('title', '==', loggedInSuper));
        const masterSnapshot = await getDocs(masterQuery);
        const masterUsernames = masterSnapshot.docs.map(doc => doc.data().username); // Get all master usernames
  
        if (masterUsernames.length === 0) {
          setUsers([]); // No masters, no users
          setLoading(false); // Stop loading
          return;
        }
  
        // Step 2: Fetch users created by those masters
        const usersRef = collection(db, 'users');
        const usersSnapshot = await getDocs(usersRef);
        const filteredUsers = usersSnapshot.docs
          .map(doc => ({ id: doc.id, ...doc.data() }))
          .filter(user => masterUsernames.includes(user.createdBy))
          .map(user => user.username); // Get the usernames of the filtered users
  
        if (filteredUsers.length === 0) {
          setUsers([]); // No users created by those masters, no transactions to show
          setLoading(false); // Stop loading
          return;
        }
  
        // Step 3: Fetch deposit data for the filtered users
        const depositQuery = query(
          collection(db, 'transaction/deposit/user'),
          where('username', 'in', filteredUsers),
          where('orderStatus', '==', 'Accept'),
          where('timestamp', '>=', startDate),
          where('timestamp', '<=', endDate),
          orderBy('timestamp', 'asc')
        );
        const depositSnapshot = await getDocs(depositQuery);
  
        const depositData = {};
        depositSnapshot.forEach((doc) => {
          const data = doc.data();
          const { username, reqAmnt } = data;
  
          if (!depositData[username]) {
            depositData[username] = { deposits: 0, withdrawals: 0 };
          }
          depositData[username].deposits += parseFloat(reqAmnt);
        });
  
        // Step 4: Fetch withdrawal data for the filtered users
        const withdrawQuery = query(
          collection(db, 'transaction/withdraw/user'),
          where('username', 'in', filteredUsers),
          where('status', '==', 'Accept'),
          where('timestamp', '>=', startDate),
          where('timestamp', '<=', endDate),
          orderBy('timestamp', 'asc')
        );
        const withdrawSnapshot = await getDocs(withdrawQuery);
  
        withdrawSnapshot.forEach((doc) => {
          const data = doc.data();
          const { holderName, amount } = data;
  
          if (!depositData[holderName]) {
            depositData[holderName] = { deposits: 0, withdrawals: 0 };
          }
          depositData[holderName].withdrawals += parseFloat(amount);
        });
  
        // Step 5: Calculate totals for deposits and withdrawals
        const userList = Object.keys(depositData).map((username) => {
          const { deposits, withdrawals } = depositData[username];
          return {
            username,
            deposits,
            withdrawals,
            total: deposits - withdrawals,
          };
        });
  
        // Set total values
        const totalDeposits = userList.reduce((sum, user) => sum + user.deposits, 0);
        const totalWithdrawals = userList.reduce((sum, user) => sum + user.withdrawals, 0);
        const grandTotal = totalDeposits - totalWithdrawals;
  
        setUsers(userList);
        setTotalDeposits(totalDeposits);
        setTotalWithdrawals(totalWithdrawals);
        setTotalUsers(userList.length);
        setGrandTotal(grandTotal);
        setLoading(false); // Stop loading after fetching all data
  
      } catch (error) {
        console.error('Error fetching data: ', error);
        setLoading(false); // Stop loading on error
      }
    };
  
    fetchData();
  }, [startDate, endDate, location.state?.username]);
  
  

  const handleLoad = () => {
    // Optionally, reload the report
    window.location.reload();
  };

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <h1 className="text-2xl text-center mt-6 font-semibold mb-8">Total Deposit and Withdraw</h1>

      <div className="flex justify-between w-3/4 mx-auto">
        <div className="flex flex-col items-start">
          <label className="mb-2 text-lg">Start Date</label>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4">Excel</button>
        </div>
        <div className="flex flex-col items-start">
          <label className="mb-2 text-lg">End Date</label>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={15}
            dateFormat="dd/MM/yyyy HH:mm"
            className="px-4 py-2 bg-black text-white border-2 border-gray-500 rounded-md w-full"
            calendarClassName="bg-black text-white"
            timeClassName={() => "bg-black text-white"}
          />
          <button className="bg-blue-600 text-white px-4 py-2 rounded-md mt-4" onClick={handleLoad}>Load Report</button>
        </div>
      </div>

      <div className="p-4 mt-10">
        {/* Table of Users */}
        <table className="min-w-full bg-black text-white">
          <thead>
            <tr className="border-b border-gray-700">
              <th className="px-4 py-2 text-left">User Name</th>
              <th className="px-4 py-2 text-left">Withdrawals</th>
              <th className="px-4 py-2 text-left">Deposits</th>
              <th className="px-4 py-2 text-left">Total</th>
            </tr>
          </thead>
          
            {loading ? (
              <div className="flex justify-center items-center h-[200px] ">
              <div className="animate-spin rounded-full h-28 w-28 border-t-4 border-blue-500"></div>
            </div>
            ):
          (<tbody>
            {users.map((user, index) => (
              <tr key={index} className="border-b border-gray-700">
                <td className="px-4 py-2">{user.username}</td>
                <td className="px-4 py-2 text-red-500">-{user.withdrawals.toFixed(2)}</td>
                <td className="px-4 py-2 text-green-500">{user.deposits.toFixed(2)}</td>
                <td className="px-4 py-2">{user.total.toFixed(2)}</td>
              </tr>
            ))}
          </tbody>)}
        </table>

        {/* Totals */}
        <div className="mt-28 w-full flex justify-end">
          <table className="min-w-full bg-black text-white">
            <tbody>
              <tr className="border-t-2 border-gray-600">
                <td className="px-4 py-2 text-left text-green-500 pr-10">Users {totalUsers}</td>
                <td className="px-4 py-2 text-left text-red-500 pr-10">-{totalWithdrawals.toFixed(2)}</td>
                <td className="px-4 py-2 text-left text-green-500">{totalDeposits.toFixed(2)}</td>
                <td className="px-4 py-2 text-left text-green-500">{grandTotal.toFixed(2)}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default ClientTotal;
