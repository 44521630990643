import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AddUser from "./TopCnt/AddUser";
import AdminLogin from "./AdminLogin";
import ClientDE from "./Client.js/ClientDE";
import ClientWi from "./Client.js/ClientWi";
import Home from "./Home";
import ClientRef from "./otherComp/clientsRef";
import TopBar from "./TopBar";
import AccStmt from "./TopCnt/AccStml";
import AllCurr from "./TopCnt/AllCurr";
import Analysis from "./TopCnt/Analysis";
import Assign from "./TopCnt/Assign";
import ClientReq from "./TopCnt/ClientReq";
import CurrentBets from "./TopCnt/CurrentBets";
import ListUsers from "./TopCnt/ListUsers";
import Login from "./TopCnt/Login";
import MasterForm from "./TopCnt/MasterForm";
import PaymentGateway from "./TopCnt/PaymentGateway";
import PL from "./TopCnt/PL";
import PLClient from "./TopCnt/PLClient";
import { useEffect, useState } from "react";
import ClientTotal from "./Client.js/ClientTotal";
import GodPanel from "./GodAdmin/GodPanel";
import SuperList from "./GodAdmin/SuperList";
import UserList from "./GodAdmin/UserList";
import MasterList from "./GodAdmin/MasterList";
import CombinedTopBar from "./CombinedTopBar";
import ListOfClients from "./Master/clientInfoM/ListOfClients";
import ListOfMasters from "./Super/ListOfMasters";
import AcctStmtMaster from "./Master/AcctStmtMaster";
import AcctStmtSuper from "./Super/AcctStmtSuper";
import ListOfSuper from "./GodAdmin/ListOfSuper";
import AcctStmtGod from "./GodAdmin/AcctStmtGod";
import ListOfMastersForGod from "./GodAdmin/GodMaster/ListOfMastersForGod";
import AcctStmtGodMaster from "./GodAdmin/AcctStmtGodMaster";
import AcctStmtAll from "./GodAdmin/AcctStmtAll";
import ChangePassword from "./ChangePassword";
import ClosedPage from "./otherComp/ClosedPage";
import SuspendedPage from "./otherComp/SuspendedPage";
import ModeOfPayment from "./Master/ModeOfPayment";
import DepositSuper from "./Super/DepositSuper";
import WithdrawSuper from "./Super/WithdrawSuper";
import DM from "./DM";
import WM from "./WM";
import AddWhole from "./GodAdmin/GodTopCnt/AddWhole";
import DepositAll from "./GodAdmin/DWInfo/DepositAll";
import WithdrawAll from "./GodAdmin/DWInfo/WithdrawAll";
import ShowAllDW from "./GodAdmin/DWInfo/ShowAllDW";
import BetsAdjust from './GodAdmin/GodTopCnt/BetsAdjust'

const Main = () => {
    const location = useLocation();
    // const navigate = useNavigate();

    const [role, setRole] = useState(location.state?.role || 'guest');
    const [username, setUsername] = useState(location.state?.username || '');


    useEffect(() => {
      if (location.state?.role) {
        setRole(location.state.role);
        setUsername(location.state.username); // Store the username

      }
    }, [location.state]);

  
    return (
      <div>
        {/* {((location.pathname !== '/') && location.pathname !== '/change-password' && ((location.pathname !== '/suspended') && location.pathname !== '/closed')) && <TopBar role={role} username={username}/>}         */}
        
        {((location.pathname !== '/') && location.pathname !== '/change-password' && ((location.pathname !== '/suspended') && location.pathname !== '/closed')) && <CombinedTopBar role={role} username={username}/>}

        
        <Routes>
          <Route path='/' element={<AdminLogin />} />
          
          {/* <Route path='/home' element={<Home />} /> */}
          <Route path='/masterLists' element={<ListOfMasters />} />

          <Route path='/assign' element={<Assign />} />
          <Route path='/listUsers' element={<ListUsers />} />
          <Route path='/master' element={<MasterForm />} />
          <Route path='/clientReq' element={<ClientReq />} />
          <Route path='/paymentGate' element={<PaymentGateway />} />
          <Route path='/accStmt' element={<AccStmt />} />
          <Route path='/pl' element={<PL />} />
          <Route path='/plc' element={<PLClient />} />
          <Route path='/clientDE' element={<ClientDE />} />
          <Route path='/clientWE' element={<ClientWi />} />
          <Route path='/add' element={<AddUser />} />
          <Route path='/ana' element={<Analysis />} />
          <Route path='/curr' element={<CurrentBets />} />
          <Route path='/allC' element={<AllCurr />} />
          <Route path='/cRef' element={<ClientRef />} />
          <Route path="/login" element={<Login />} />
          <Route path="/totalDW" element={<ClientTotal />} />

          <Route path="/godHome" element={<GodPanel />}/>
          <Route path='/superList' element={<SuperList />} />

          <Route path='/godMasterList' element={<ListOfMastersForGod />} />
          <Route path='/godUserList' element={<UserList />} />

          <Route path='/master/listClient' element={<ListOfClients />} />

          <Route path='/master/accStmt' element={<AcctStmtMaster />} />
          <Route path='/super/accStmt' element={<AcctStmtSuper />} />

          <Route path='/god/listSuper' element={<ListOfSuper />} />
          {/* /god/accStmt */}
          <Route path='/god/accStmt' element={<AcctStmtGod />} />
          <Route path='/god/MasterAccStmt' element={<AcctStmtGodMaster />} />
          
          <Route path='/god/Stmt' element={<AcctStmtAll/>} />

          <Route path='/change-password' element={<ChangePassword/>} />
          <Route path='/suspended' element={<SuspendedPage/>} />
          <Route path='/closed' element={<ClosedPage />} />

          <Route path='/modeOfPay' element={<ModeOfPayment />} />

          <Route path='/WS' element={<WithdrawSuper />} />
          <Route path='/DS' element={<DepositSuper />} />

          
          <Route path='/DM' element={<DM />} />
          <Route path='/WM' element={<WM />} />

          
          <Route path='/addWhole' element={<AddWhole />} />

          <Route path='/god/DE' element={<DepositAll />} />
          <Route path='/god/WE' element={<WithdrawAll />} />
          <Route path='/god/totalDW' element={<ShowAllDW />} />
          <Route path='/god/setbets' element={<BetsAdjust />} />

          

                        
          </Routes>
      </div>
    );
  };

  export default Main;

  