import React from 'react'

const CurrentBets = () => {
  return (
    <div className='h-screen bg-black text-white'>
        <h1 className='text-center font-semibold text-3xl pt-4'>Work</h1>
    </div>
  )
}

export default CurrentBets