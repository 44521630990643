import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { collection, doc, getDocs, onSnapshot, query, updateDoc, where } from 'firebase/firestore';
import { db } from '../firebase';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const WM = () => {
  const location = useLocation();
  const loggedInMasterUsername = location.state?.username || ''; // Assuming logged-in master’s username is passed in route state
  const [heading, setHeading] = useState("Withdraw States");
  const [withdrawData, setWithdrawData] = useState([]);
  const [status, setStatus] = useState("Action");
  const notificationSound = new Audio('/assets/notification.wav');
  const prevDepositDataRef = useRef([]);  // Correctly initialize the ref to store previous deposits

  // Set heading dynamically based on location state
 

  // Fetch withdraw data for users created by the logged-in master
  useEffect(() => {
    const fetchWithdrawalsForMaster = async () => {
      try {
        // First, fetch all users created by the logged-in master
        const usersQuery = query(
          collection(db, 'users'),
          where('createdBy', '==', loggedInMasterUsername)
        );
        const usersSnapshot = await getDocs(usersQuery);
        const userNames = usersSnapshot.docs.map((doc) => doc.data().username);

        if (userNames.length === 0) {
          setWithdrawData([]); // No users created by the master, no withdrawals
          return;
        }

        // Fetch withdrawals for these users
        const withdrawalsQuery = query(
          collection(db, 'transaction/withdraw/user'),
          where('holderName', 'in', userNames) // Only fetch withdrawals for users created by the logged-in master
        );

        const unsubscribe = onSnapshot(withdrawalsQuery, (snapshot) => {
          const newWithdrawData = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Check if the content has changed, not just the length
          const hasNewData = newWithdrawData.some(
            (newData, index) => newData.id !== withdrawData[index]?.id
          );

          const prevDepositData = prevDepositDataRef.current;

          const isNewDeposit = newWithdrawData.some((newDeposit) => {
            return !prevDepositData.some((prevDeposit) => prevDeposit.id === newDeposit.id);
          });

          if (isNewDeposit) {
            notificationSound.play().catch(error => console.error("Audio playback failed:", error));
              // Automatically update the state
          }
          setWithdrawData(newWithdrawData);
          prevDepositDataRef.current = newWithdrawData;

        });

        return () => unsubscribe();
      } catch (error) {
        console.error('Error fetching withdrawals for master:', error);
      }
    };

    fetchWithdrawalsForMaster();
  }, [loggedInMasterUsername, withdrawData]);

  // Handle action selection from dropdown
  async function handleAction(e, t) {
    const selectedAction = e.target.value;

    // Prevent changing to the same status
    if (selectedAction === t.status) {
      toast.error("This status is already set.");
      return;
    }

    // Fetch the user's balance first
    const usersCollection = collection(db, 'users');
    const q = query(usersCollection, where('username', '==', t.holderName));
    const querySnapshot = await getDocs(q);

    if (!querySnapshot.empty) {
      const userDoc = querySnapshot.docs[0];
      const userData = userDoc.data();
      const currentAmount = parseFloat(userData.amount) || 0; // Ensure user balance is a number
      const withdrawalAmount = parseFloat(t.amount) || 0; // Ensure withdrawal amount is a number

      // Fetch the master's balance using the user's "createdBy" field to find the master
      const mastersCollection = collection(db, 'masters');
      const masterQuery = query(mastersCollection, where('username', '==', userData.createdBy)); // Assuming createdBy is the master's username
      const masterSnapshot = await getDocs(masterQuery);

      if (!masterSnapshot.empty) {
        const masterDoc = masterSnapshot.docs[0];
        const masterData = masterDoc.data();
        const masterCurrentBalance = parseFloat(masterData.balance) || 0; // Ensure master balance is a number

        if (selectedAction === "Accept") {
          // Check if the user has enough balance
          const newAmount = currentAmount - withdrawalAmount;
          if (newAmount < 0) {
            toast.error("Insufficient user balance");
            return;
          }

          // Update the user's balance
          await updateDoc(userDoc.ref, { amount: newAmount });

          // Add the withdrawn amount to the master's balance
          const newMasterBalance = masterCurrentBalance + withdrawalAmount;
          await updateDoc(masterDoc.ref, { balance: newMasterBalance });

          // Update the withdrawal status to "Accepted" in the database
          await updateDoc(doc(db, `transaction/withdraw/user/${t.id}`), {
            ...t,
            status: "Accepted",
          });

          // Update local state for immediate UI change
          setWithdrawData((prevData) =>
            prevData.map((item) =>
              item.id === t.id ? { ...item, status: "Accepted" } : item
            )
          );

          toast.success("Withdrawal accepted successfully");

        } else {
          // Only adjust balances if the current status is "Accepted" and changing to another status
          if (t.status === "Accepted") {
            // Increase the user's balance by the withdrawal amount
            const newUserBalance = currentAmount + withdrawalAmount;
            await updateDoc(userDoc.ref, { amount: newUserBalance });

            // Reduce the master's balance by the withdrawal amount
            const newMasterBalance = masterCurrentBalance - withdrawalAmount;
            if (newMasterBalance < 0) {
              toast.error("Master has insufficient balance for this operation");
              return;
            }

            await updateDoc(masterDoc.ref, { balance: newMasterBalance });
          }

          // Update the withdrawal status
          await updateDoc(doc(db, `transaction/withdraw/user/${t.id}`), {
            ...t,
            status: selectedAction,
          });

          // Update local state for immediate UI change
          setWithdrawData((prevData) =>
            prevData.map((item) =>
              item.id === t.id ? { ...item, status: selectedAction } : item
            )
          );

          toast.success("Order status updated");
        }
      } else {
        toast.error("Master not found");
      }
    } else {
      toast.error("User not found");
    }
  }

  
  
  
  

  return (
    <div className="flex flex-col h-screen bg-black text-white">
      <h1 className="text-2xl text-center mt-6 font-semibold mb-8">{heading}</h1>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-gray-800 text-white rounded-lg mt-10 pl-4 md:pl-10">
          <thead>
            <tr className="bg-gray-700">
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Account Number
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Bank Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Branch Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Holder Name
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                IFSC Code
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Payment Mode
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Order Status
              </th>
              <th className="px-4 md:px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase tracking-wider">
                Option
              </th>
            </tr>
          </thead>
          <tbody>
            {withdrawData?.map((d, index) => (
              <tr key={index} className="bg-gray-800 border-b border-gray-700">
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.accountNum}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.bankName}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.amount}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.branchName}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.holderName}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.ifscCode}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.payMode}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">{d?.status}</td>
                <td className="px-4 md:px-6 py-4 whitespace-nowrap">
                  <div className="relative inline-block text-left">
                    <select
                      value={status}
                      onChange={(e) => handleAction(e, d)}
                      className="bg-transparent text-white"
                    >
                      <option disabled className="bg-transparent text-black">
                        Action
                      </option>
                      <option value="pending" className="bg-transparent text-black">
                        Pending
                      </option>
                      <option value="Accept" className="bg-transparent text-black">
                        Accept
                      </option>
                      <option value="Reject" className="bg-transparent text-black">
                        Reject
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WM;
