import React, { useState } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../../../firebase';

const GamesModal = ({ masterId, currentGamesSelect, superPassword, onClose }) => {
  const [gamesSelect, setGamesSelect] = useState(currentGamesSelect);  // Store the current games
  const [enteredPassword, setEnteredPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);

  // Toggle a game's status between 'on' and 'off'
  const toggleGame = (game) => {
    setGamesSelect((prevState) => ({
      ...prevState,
      [game]: prevState[game] === 'on' ? 'off' : 'on',
    }));
  };

  // Toggle password visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Handle form submission and update Firestore
  const handleSubmit = async () => {
    if (enteredPassword !== superPassword) {
      setError('Incorrect god password.');
      return;
    }

    try {
      const masterRef = doc(db, 'masters', masterId);
      await updateDoc(masterRef, { GamesSelect: gamesSelect });  // Update the GamesSelect field in Firestore
      alert('Games updated successfully.');
      onClose();
    } catch (error) {
      console.error('Error updating games:', error);
      alert('Failed to update games. Please try again.');
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Game Selection</h2>

        {/* Display the games with their toggle buttons */}
        {Object.keys(gamesSelect).map((game) => (
          <div key={game} className="flex justify-between items-center mb-2">
            <span className="text-white">{game}</span>
            <button
              onClick={() => toggleGame(game)}
              className={`px-3 py-1 rounded ${
                gamesSelect[game] === 'on' ? 'bg-green-500' : 'bg-red-500'
              } text-white`}
            >
              {gamesSelect[game] === 'on' ? 'On' : 'Off'}
            </button>
          </div>
        ))}

        {/* Password input field */}
        <input
          type={passwordVisible ? 'text' : 'password'}
          placeholder="God Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? 'Hide' : 'Show'} Password
        </button>

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default GamesModal;
