import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import MasterForm from "../TopCnt/MasterForm";
import ResetPasswordModal from "./otherComp/ResetPasswordModal";
import FTDBonusModal from "./otherComp/FTDBonusModal";
import RefillBonusModal from "./otherComp/RefillBonusModal";
import DefaultRegModal from "./otherComp/DefaultRegModal";
import CreditAmtModal from "./otherComp/CreditAmtModal";
import StatusModal from "./otherComp/StatusModal";
import ExposureLimitModal from "./otherComp/ExposureLimitModal";
import { fetchUserData } from "./otherComp/FetchUserData";
import GamesModal from "./otherComp/GamesModal";
import WithdrawModal from "./otherComp/WithdrawModal";
import DepositModal from "./otherComp/DepositModal";
import MasterInfoModal from "./otherComp/MasterInfoModal";
import ActionModal from "./otherComp/ActionModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const ListOfMasters = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedMasterId, setSelectedMasterId] = useState(null);
  const [masters, setMasters] = useState([]); // Change to an array to hold multiple masters
  const [isCreditAmtModalOpen, setIsCreditAmtModalOpen] = useState(false);
  const [isResetModalOpen, setIsResetModalOpen] = useState(false);
  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isExposureLimitModalOpen, setIsExposureLimitModalOpen] =
    useState(false);

  const [isRefillModalOpen, setIsRefillModalOpen] = useState(false);
  const [isFtdModalOpen, setIsFtdModalOpen] = useState(false);
  const [superPassword, setSuperPassword] = useState(null); // State to hold fetched super password

  const [isDefaultRegModalOpen, setIsDefaultRegModalOpen] = useState(false);

  const [userTotals, setUserTotals] = useState({}); // Store total user data (amount, exposure) for each master
  const [isGamesModalOpen, setIsGamesModalOpen] = useState(false);
  const [currentGamesSelect, setCurrentGamesSelect] = useState({});

  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useState(false);
  const [selectedMaster, setSelectedMaster] = useState(null);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  const [isActionModalOpen, setIsActionModalOpen] = useState(false); // Track modal state

  const [generatedPin, setGeneratedPin] = useState(null);

  const [loading, setLoading] = useState(true); // New state for loading

  const generatePinNumber = async (masterId) => {
    const randomPin = Math.floor(1000 + Math.random() * 9000); // Generate 4-digit random number
    setGeneratedPin(randomPin); // Set the generated PIN for display

    try {
      const masterRef = doc(db, "masters", masterId);
      await updateDoc(masterRef, { pinNumber: randomPin }); // Store the PIN in Firestore

      // console.log(`PIN ${randomPin} generated for master ${masterId}`);
    } catch (error) {
      console.error("Error generating PIN:", error);
      toast.error("Failed to generate PIN. Please try again.");
    }
  };

  const closeActionModal = () => {
    setIsActionModalOpen(false);
    setGeneratedPin(null); // Clear the PIN when the modal closes
  };

  const openDepositModal = (master) => {
    setSelectedMaster(master);
    setIsDepositModalOpen(true);
  };

  // Open Withdraw Modal
  const openWithdrawModal = (master) => {
    setSelectedMaster(master);
    setIsWithdrawModalOpen(true);
  };

  // Close the modals
  const closeModals = () => {
    setIsDepositModalOpen(false);
    setIsWithdrawModalOpen(false);
    setSelectedMaster(null);
  };

  const handleActionClick = (master) => {
    setSelectedMaster(master);
    setIsActionModalOpen(true); // Open the modal
  };

  const openDefaultRegModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsDefaultRegModalOpen(true);
  };

  // Close the Default Reg Modal
  const closeDefaultRegModal = () => {
    setIsDefaultRegModalOpen(false);
    setSelectedMasterId(null);
  };

  const openCreditAmtModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsCreditAmtModalOpen(true);
  };

  // Close the Credit Amount Modal
  const closeCreditAmtModal = () => {
    setIsCreditAmtModalOpen(false);
    setSelectedMasterId(null);
  };

  const openStatusModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsStatusModalOpen(true);
  };

  // Close the Status Modal
  const closeStatusModal = () => {
    setIsStatusModalOpen(false);
    setSelectedMasterId(null);
  };

  const openExposureLimitModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsExposureLimitModalOpen(true);
  };

  // Close the Exposure Limit Modal
  const closeExposureLimitModal = () => {
    setIsExposureLimitModalOpen(false);
    setSelectedMasterId(null);
  };

  const openGamesModal = async (masterId) => {
    try {
      const masterRef = doc(db, "masters", masterId); // Create a reference to the specific document
      const masterDoc = await getDoc(masterRef); // Use getDoc for fetching the document

      if (masterDoc.exists()) {
        const data = masterDoc.data(); // Access the document data
        setCurrentGamesSelect(data.GamesSelect); // Load current GamesSelect field
        setSelectedMasterId(masterId);
        setIsGamesModalOpen(true); // Open the modal
      } else {
        console.error("Master not found");
      }
    } catch (error) {
      console.error("Error fetching master data:", error);
    }
  };

  // Close the Games Modal
  const closeGamesModal = () => {
    setIsGamesModalOpen(false);
    setSelectedMasterId(null);
  };

  useEffect(() => {
    const fetchAllUserTotals = async () => {
      setLoading(true); // Start loading
      const totals = {};
      for (let master of masters) {
        const data = await fetchUserData(master.username); // Fetch total amount and exposure for users created by this master
        totals[master.username] = data; // Store the totals for this master
      }
      setUserTotals(totals);
      setLoading(false);
    };

    if (masters.length > 0) {
      fetchAllUserTotals();
    }
  }, [masters]);

  const handleDefaultRegSubmit = async ({ toggleState }) => {
    try {
      const masterRef = doc(db, "masters", selectedMasterId); // Get the reference to the document

      // Update the "defaultReg" field (or another field as needed) in Firestore based on toggle state
      await updateDoc(masterRef, { defaultReg: toggleState ? "On" : "Off" });

      console.log("Updated Default Reg successfully");
      closeDefaultRegModal();
    } catch (error) {
      console.error("Error updating Default Reg:", error);
      toast.error("Failed to update Default Reg. Please try again.");
    }
  };

  const openResetPasswordModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsResetModalOpen(true);
  };

  const closeResetPasswordModal = () => {
    setIsResetModalOpen(false);
    setSelectedMasterId(null);
  };
  const navigate = useNavigate();
  const location = useLocation();

  const username = location.state?.username;

  const fetchMasters = async () => {
    setLoading(true); // Start loading when fetching data
    const q = query(collection(db, "masters"), where("title", "==", username));
    const querySnapshot = await getDocs(q);
    const mastersList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setMasters(mastersList);
    setLoading(false); // Stop loading when data is fetched
  };

  useEffect(() => {
    fetchMasters();
  }, []);

  const handleInfoClick = (masterId) => {
    setSelectedMasterId(masterId);
    setIsInfoModalOpen(true);
  };

  useEffect(() => {
    const mastersRef = collection(db, "masters");

    // Listen for real-time updates where 'title' matches the logged-in super's username
    const q = query(mastersRef, where("title", "==", username));

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const mastersList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setMasters(mastersList); // Automatically update the masters state in real-time
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, [username]); // Ensure the listener is set for the correct logged-in user

  const openRefillModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsRefillModalOpen(true);
  };

  const closeRefillModal = () => {
    setIsRefillModalOpen(false);
    setSelectedMasterId(null);
  };

  const openFtdModal = (masterId) => {
    setSelectedMasterId(masterId);
    setIsFtdModalOpen(true);
  };

  const closeFtdModal = () => {
    setIsFtdModalOpen(false);
    setSelectedMasterId(null);
  };

  const handleOpenAddModal = () => {
    console.log(username);
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
  };

  const handleMasterCreation = async (newMaster) => {
    try {
      // Query Firestore for existing masters with the same phone or email
      const mastersRef = collection(db, "masters");
      const q = query(mastersRef, where("username", "==", newMaster.username));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        // If a document with the same phone or email is found, show an alert and return
        toast.info("A master with this username already exists.");
        return;
      }

      // If no document is found, proceed to add the new master
      const docRef = await addDoc(collection(db, "masters"), newMaster);
      console.log("Document written with ID: ", docRef.id);
      setMasters([...masters, { id: docRef.id, ...newMaster }]); // Add the new master to the list
      setIsAddModalOpen(false);
      toast.success("Master created successfully");
    } catch (e) {
      console.error("Error adding document: ", e);
      toast.error("Failed to create master. Please try again.");
    }
  };

  const fetchSuperPassword = async (username) => {
    try {
      // Query the Firestore collection "super" where the username matches the logged-in user
      const superRef = collection(db, "super");
      const q = query(superRef, where("username", "==", username)); // Fetch by username
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const superDoc = querySnapshot.docs[0]; // Get the first matching document
        return superDoc.data().password; // Return the password field
      } else {
        console.log("No such document!");
      }
    } catch (error) {
      console.error("Error fetching super password:", error);
    }
  };

  // Call this function to set the super password in state
  useEffect(() => {
    const getSuperPassword = async () => {
      if (username) {
        const fetchedPassword = await fetchSuperPassword(username);
        setSuperPassword(fetchedPassword); // Set the password in state
      }
    };

    getSuperPassword();
  }, [username]);

  return (
    <div className="bg-black min-h-screen p-4">
      <h1 className="text-2xl text-center text-white mb-6 font-semibold">
        List Of Master
      </h1>

      {loading ? (
        // Show spinner when loading is true
        <div className="flex justify-center items-center min-h-screen">
          <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-blue-500"></div>
        </div>
      ) : (
        <div>
          <div>
            <div className="flex space-x-3 mb-4">
              {/* <button className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
                Excel
              </button> */}
              <button
                onClick={handleOpenAddModal}
                className="text-white bg-blue-600 px-10 py-3 rounded-lg hover:bg-black hover:border-2 hover:border-white"
              >
                Add
              </button>
              {/* <button className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
                Clients Reference
              </button>
              <button className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
                Update News
              </button>
              <button className="bg-blue-600 text-white p-2 rounded hover:bg-blue-700">
                Load
              </button> */}
            </div>
          </div>

          {masters.length > 0 ? (
            <div className="overflow-x-auto">
              <table className="min-w-full text-white border-separate border-spacing-0">
                <thead className="bg-gray-800">
                  <tr>
                    <th className="p-3 text-left">Master Name</th>
                    <th className="p-3 text-left">Transfer</th>
                    <th className="p-3 text-left">Password</th>
                    <th className="p-3 text-left">Refill %</th>
                    <th className="p-3 text-left">FTD %</th>
                    <th className="p-3 text-left">Default Reg</th>
                    <th className="p-3 text-left">Games Select</th>
                    <th className="p-3 text-left">C.Bal</th>
                    <th className="p-3 text-left">C.Exposure</th>
                    <th className="p-3 text-left">C.Net Bal</th>
                    <th className="p-3 text-left">M.Bal</th>
                    {/* <th className="p-3 text-left">M.Net Bal</th> */}
                    <th className="p-3 text-left">Credit Amt</th>
                    <th className="p-3 text-left">Total Bal</th>
                    <th className="p-3 text-left">Status</th>
                    <th className="p-3 text-left">Exp Limit</th>
                    <th className="p-3 text-left">Info</th>
                    <th className="p-3 text-left">Action</th>
                  </tr>
                </thead>

                <tbody className="">
                  {masters.map((master) => (
                    <tr className="bg-black border-b border-gray-700">
                      <td className="p-1">{master.username}</td>{" "}
                      {/* Decreased padding */}
                      <td className="p-1 flex space-x-1">
                        <button
                          className="border-4 border-green-700 px-1 py-1 rounded text-white mt-2"
                          onClick={() => openDepositModal(master)}
                        >
                          D
                        </button>
                        <button
                          className="border-4 border-gray-500 px-1 py-1 rounded text-white mt-2"
                          onClick={() => openWithdrawModal(master)}
                        >
                          W
                        </button>
                      </td>
                      <td className="p-1">
                        <button
                          className="border-4 border-red-500 px-1 py-1 rounded text-white"
                          onClick={() => openResetPasswordModal(master.id)}
                        >
                          Reset
                        </button>
                      </td>
                      <td className="p-1">
                        <button
                          className="border-4 border-green-500 px-2 py-1 rounded"
                          onClick={() => openRefillModal(master.id)}
                        >
                          {master.refillBonus}
                        </button>
                      </td>
                      <td className="p-1">
                        <button
                          className="border-4 border-green-500 px-2 py-1 rounded"
                          onClick={() => openFtdModal(master.id)}
                        >
                          {master.ftdBonus}
                        </button>
                      </td>
                      {/* Adjusting Navigation Cell */}
                      <td className="p-1 ">
                        <button
                          className="block p-1 border-4 border-yellow-500 rounded"
                          onClick={() => openDefaultRegModal(master.id)}
                        >
                          <p className="p-1"> {master.defaultReg}</p>
                        </button>
                      </td>
                      {/* ------------------------------------------------------------------------------------------------------- */}
                      <td className="p-1">
                        <button
                          className="block p-1 border-4 border-yellow-500 rounded"
                          onClick={() => openGamesModal(master.id)}
                        >
                          Games Select
                        </button>
                      </td>
                      {/* -------------------------------------------------------------------------------------------------------------- */}
                      <td className="p-1">
                        {userTotals[master.username]?.totalAmount}{" "}
                        {/* Show aggregated total amount */}
                      </td>
                      {/* Display C.Exposure (Total Exposure) */}
                      <td className="p-1">
                        {userTotals[master.username]?.totalExposure}{" "}
                        {/* Show aggregated total exposure */}
                      </td>
                      <td className="p-1">
                        <span className="block p-1 text-gray-300 text-lg">
                          {userTotals[master.username]?.totalAmount -
                            userTotals[master.username]?.totalExposure}
                        </span>{" "}
                        {/* c.bal-c.exp */}
                      </td>
                      <td className="p-1">
                        <span className="block p-1">{master.balance - master.creditAmt}</span>
                      </td>
                      {/* <td className="p-1">
                        <span className="block p-1 text-gray-300 text-lg">
                          {parseFloat(master.balance - master.creditAmt) -
                            userTotals[master.username]?.totalExposure
                            }
                        </span>{" "} */}
                        {/* m.bal-c.exposure */}
                      {/* </td> */}
                      <td className="p-1">
                        <button
                          className="block p-1  border-4 border-yellow-500 rounded "
                          onClick={() => openCreditAmtModal(master.id)}
                        >
                          {master.creditAmt}
                        </button>
                      </td>
                      {/* {(parseFloat(master.balance) - userTotals[master.username]?.totalExposure)+parseFloat(master.creditAmt)} */}
                      <td className="p-1 text-gray-300 text-lg font-semibold">
                        {parseFloat(master.balance) 
                          //  - userTotals[master.username]?.totalExposure
                          // + parseFloat(master.creditAmt)
                          }
                      </td>{" "}
                      {/* m.netbal-credit */}
                      {/* Adjusting Status Cell */}
                      <td className="p-1">
                        <button
                          className="block p-1  border-4 border-purple-700 rounded"
                          onClick={() => openStatusModal(master.id)}
                        >
                          {master.status}
                        </button>
                      </td>
                      <td className="p-1">
                        <button
                          className="border-4 border-gray-500 px-2 py-1 rounded"
                          onClick={() => openExposureLimitModal(master.id)}
                        >
                          {master.limit}
                        </button>
                      </td>
                      <button
                        onClick={() => handleInfoClick(master.id)}
                        className="bg-gray-600 px-2 py-1 rounded-md mt-1"
                      >
                        Info
                      </button>
                      <td className="p-2">
                        <button
                          onClick={() => handleActionClick(master)} // Open the Action modal
                          className="bg-gray-600 px-2 py-1 rounded-md"
                        >
                          ⚙
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p className="text-white text-center mt-10">No masters found.</p>
          )}
        </div>
      )}
      {isAddModalOpen && (
        <MasterForm
          superUsername={username}
          onSubmit={handleMasterCreation}
          onClose={handleCloseAddModal}
        />
      )}

      {isResetModalOpen && (
        <ResetPasswordModal
          masterId={selectedMasterId}
          onClose={closeResetPasswordModal}
          superPassword={superPassword} // Pass the logged-in super's password
        />
      )}

      {/* Modals for updating Refill and FTD Bonuses */}
      {isRefillModalOpen && (
        <RefillBonusModal
          masterId={selectedMasterId}
          superPassword={superPassword}
          onClose={closeRefillModal}
        />
      )}

      {isFtdModalOpen && (
        <FTDBonusModal
          masterId={selectedMasterId}
          superPassword={superPassword}
          onClose={closeFtdModal}
        />
      )}

      {isDefaultRegModalOpen && (
        <DefaultRegModal
          masterId={selectedMasterId}
          superPassword={superPassword} // Pass the fetched super password for validation
          onSubmit={handleDefaultRegSubmit} // Handle form submission
          onClose={closeDefaultRegModal} // Close the modal
        />
      )}

      {isStatusModalOpen && (
        <StatusModal
          masterId={selectedMasterId}
          superPassword={superPassword} // Pass the fetched super password for validation
          onClose={closeStatusModal} // Close the modal
        />
      )}

      {isCreditAmtModalOpen && (
        <CreditAmtModal
          masterId={selectedMasterId}
          superPassword={superPassword} // Pass the fetched super password for validation
          onClose={closeCreditAmtModal} // Close the modal
          superUsername={username}
        />
      )}

      {isExposureLimitModalOpen && (
        <ExposureLimitModal
          masterId={selectedMasterId}
          superPassword={superPassword} // Pass the fetched super password for validation
          onClose={closeExposureLimitModal} // Close the modal
        />
      )}

      {isGamesModalOpen && (
        <GamesModal
          masterId={selectedMasterId}
          currentGamesSelect={currentGamesSelect} // Pass current GamesSelect field values
          superPassword={superPassword} // Pass the fetched super password for validation
          onClose={closeGamesModal} // Close the modal
        />
      )}

      {isDepositModalOpen && (
        <DepositModal
          masterName={selectedMaster.username}
          masterId={selectedMaster.id}
          superPassword={superPassword} // Pass the fetched super password for validation
          onClose={closeModals} // Close the modal
          superUsername={username} // Pass the super username to save in Firestore
        />
      )}

      {isWithdrawModalOpen && (
        <WithdrawModal
          masterName={selectedMaster.username}
          masterId={selectedMaster.id}
          superPassword={superPassword} // Pass the fetched super password for validation
          onClose={closeModals} // Close the modal
          superUsername={username}
        />
      )}

      {isInfoModalOpen && (
        <MasterInfoModal
          masterId={selectedMasterId}
          onClose={() => setIsInfoModalOpen(false)}
        />
      )}
      {isActionModalOpen && (
        <ActionModal
          master={selectedMaster} // Pass selected master to the modal
          onClose={closeActionModal} // Handle modal close
          onGeneratePin={generatePinNumber} // Pass the generatePinNumber function to ActionModal
        />
      )}

      {/* {generatedPin && (
        <div className="fixed top-0 left-0 right-0 bg-yellow-500 text-black text-center p-2">
          Generated PIN: {generatedPin}
        </div>
      )} */}

{generatedPin && (
  <div className="fixed top-0 left-0 right-0 bg-yellow-500 text-black text-center p-2 z-50 sm:text-sm md:text-base lg:text-lg">
    <div className="container mx-auto px-4">
      Generated PIN: {generatedPin}
    </div>
  </div>
)}

    </div>
  );
};

export default ListOfMasters;
