import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";

const ExposureLimitModal = ({ masterId, superPassword, onClose }) => {
  const [limit, setLimit] = useState(""); // Input field for Exposure Limit
  const [enteredPassword, setEnteredPassword] = useState(""); // Super Password input
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [error, setError] = useState(null);

  // Toggle password visibility
  const handlePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  // Handle form submission and update Firestore
  const handleLimitUpdate = async () => {
    if (enteredPassword !== superPassword) {
      setError("Incorrect super password.");
      return;
    }

    try {
      const masterRef = doc(db, "masters", masterId);
      await updateDoc(masterRef, { limit }); // Update limit in Firestore
      alert("Exposure Limit updated successfully.");
      onClose();
    } catch (error) {
      console.error("Error updating exposure limit:", error);
      alert("Failed to update exposure limit. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-900 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Exposure Limit of User</h2>

        {/* Limit Input Field */}
        <label className="text-white mb-2 block">Limit:</label>
        <input
          type="number"
          placeholder="Enter Exposure Limit"
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password input field */}
        <input
          type={passwordVisible ? "text" : "password"}
          placeholder="Super Password"
          value={enteredPassword}
          onChange={(e) => setEnteredPassword(e.target.value)}
          className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500 mb-4"
        />

        {/* Password visibility toggle */}
        <button
          onClick={handlePasswordVisibility}
          className="bg-blue-600 text-white px-4 py-2 rounded-lg mb-4 w-full"
        >
          {passwordVisible ? "Hide" : "Show"} Password
        </button>

        {/* Error message */}
        {error && <p className="text-red-500 mb-4">{error}</p>}

        {/* Submit and Cancel buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleLimitUpdate}
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default ExposureLimitModal;
