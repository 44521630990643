import React, { useState } from "react";
import { doc, updateDoc } from "firebase/firestore"; // Import Firestore methods
import { db } from "../../../firebase"; // Adjust the path based on your project structure

const DefaultRegModal = ({ masterId, superPassword, onSubmit, onClose }) => {
  const [toggleState, setToggleState] = useState(false); // Toggle state (on/off)
  const [enteredPassword, setEnteredPassword] = useState(""); // Input for the password
  const [error, setError] = useState(null);

  // Toggle handler for on/off switch
  const handleToggle = () => {
    setToggleState(!toggleState); // Toggle between true/false
  };

  // Function to handle form submission and Firestore update
  const handleSubmit = async () => {
    if (enteredPassword === superPassword) {
      try {
        // Reference the master's document in Firestore
        const masterRef = doc(db, "masters", masterId); // Ensure `masterId` is passed as a prop

        // Update the "defaultReg" field in Firestore based on the toggle state
        await updateDoc(masterRef, { defaultReg: toggleState ? "On" : "Off" });

        // Call the onSubmit prop (if any additional actions are needed)
        onSubmit({ toggleState });

        alert("Default Registration Status updated successfully.");
        onClose(); // Close the modal after successful submission
      } catch (error) {
        console.error("Error updating Default Registration Status:", error);
        alert(
          "Failed to update Default Registration Status. Please try again."
        );
      }
    } else {
      setError("Incorrect password. Please try again.");
    }
  };

  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg w-80">
        <h2 className="text-white text-xl mb-6">Default Reg Status</h2>
        {/* Toggle for On/Off */}
        <div className="flex justify-between mb-4">
          <span className="text-white">Status: </span>
          <button
            className={`px-4 py-2 rounded-lg ${
              toggleState ? "bg-green-500" : "bg-red-500"
            } text-white`}
            onClick={handleToggle}
          >
            {toggleState ? "On" : "Off"}
          </button>
        </div>
        {/* Password Input */}
        <div className="mb-6">
          <input
            type="password"
            placeholder="Super Password"
            className="w-full p-2 bg-gray-700 text-white rounded border border-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={enteredPassword}
            onChange={(e) => setEnteredPassword(e.target.value)}
          />
        </div>
        {error && <p className="text-red-500 mb-4">{error}</p>}{" "}
        {/* Display error message if password is incorrect */}
        {/* Submit and Cancel Buttons */}
        <div className="flex justify-between">
          <button
            onClick={handleSubmit}
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-lg"
          >
            Submit
          </button>
          <button
            onClick={onClose}
            className="bg-red-500 hover:bg-red-600 text-white px-4 py-2 rounded-lg"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

export default DefaultRegModal;
