import React, { useState } from 'react';
import { FaCog } from 'react-icons/fa';

const Assign = () => {
  const [admins, setAdmins] = useState([
    { username: 'ravihabhai', status: 'ACTIVE' },
    { username: 'superdemoppsub', status: 'ACTIVE' }
  ]);

  const [newAdmin, setNewAdmin] = useState({ username: '', password: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isStatusModalOpen, setIsStatusModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setIsResetPasswordModalOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const [isChangePinModalOpen, setIsChangePinModalOpen] = useState(false);
  const [currentPin, setCurrentPin] = useState('');
  const [newPin, setNewPin] = useState('');

  const [selectedStatus, setSelectedStatus] = useState('ACTIVE');

  const handleOpenChangePinModal = (username) => {
    setSelectedUser(username);
    setIsChangePinModalOpen(true);
  };

  const handleCloseChangePinModal = () => {
    setIsChangePinModalOpen(false);
    setCurrentPin('');
    setNewPin('');
  };

  const handleOpenStatusModal = (username, status) => {
    setSelectedUser(username);
    setSelectedStatus(status);
    setIsStatusModalOpen(true);
  };

  const handleCloseStatusModal = () => {
    setIsStatusModalOpen(false);
  };

  const handleOpenResetPasswordModal = (username) => {
    setSelectedUser(username);
    setIsResetPasswordModalOpen(true);
  };

  const handleCloseResetPasswordModal = () => {
    setIsResetPasswordModalOpen(false);
  };

  const handleAddAdmin = () => {
    setAdmins([...admins, { ...newAdmin, status: 'ACTIVE' }]);
    setNewAdmin({ username: '', password: '' });
    setIsModalOpen(false);
  };

  const handleStatusChange = () => {
    setAdmins(admins.map(admin =>
      admin.username === selectedUser ? { ...admin, status: selectedStatus } : admin
    ));
    handleCloseStatusModal();
  };

  return (
    <div className="flex flex-col items-center bg-black text-white min-h-screen pt-10">
      <h1 className="text-2xl font-semibold mb-4">List of Admins</h1>
      <button
        className="bg-green-600 text-white px-4 py-2 rounded-md mb-4"
        onClick={() => setIsModalOpen(true)}
      >
        Add
      </button>

      {/* Responsive Table Container */}
      <div className="w-full overflow-x-auto">
        <table className="min-w-full bg-black text-white">
          <thead>
            <tr>
              <th className="px-4 py-2 text-left">User Name</th>
              <th className="px-4 py-2 text-left">Account State</th>
              <th className="px-4 py-2 text-left">Change Password</th>
              <th className="px-4 py-2 text-left">User Reset Pin</th>
              <th className="px-4 py-2 text-left">Edit Roles</th>
            </tr>
          </thead>
          <tbody>
  {admins.map((admin, index) => (
    <tr key={index} className="border-b border-gray-700">
      <td className="px-4 py-2">{admin.username}</td>
      <td className="px-4 py-2">
        <span
          className={`border-4 text-white px-4 py-1 rounded-md cursor-pointer ${
            admin.status === 'ACTIVE'
              ? 'border-green-600'
              : admin.status === 'SUSPENDED'
              ? 'border-yellow-600'
              : 'border-red-600'
          }`}
          onClick={() => handleOpenStatusModal(admin.username, admin.status)}
        >
          {admin.status}
        </span>
      </td>
      <td className="px-4 py-2">
        <button
          className="border-red-600 border-4 text-white px-4 py-1 rounded-md"
          onClick={() => handleOpenResetPasswordModal(admin.username)}
        >
          Reset
        </button>
      </td>
      <td className="px-4 py-2">
        <button
          className="border-green-600 border-4 text-white px-4 py-1 rounded-md"
          onClick={() => handleOpenChangePinModal(admin.username)}
        >
          Change Pin
        </button>
      </td>
      <td className="px-4 py-2">
        <FaCog className="text-blue-500 text-lg cursor-pointer" />
      </td>
    </tr>
  ))}
</tbody>
        </table>
      </div>

      {isStatusModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4 text-center">Account Status of {selectedUser}</h2>
            <div className="mb-4">
              <label className="block mb-2">Account Status:</label>
              <select
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              >
                <option value="ACTIVE">ACTIVE</option>
                <option value="SUSPENDED">SUSPENDED</option>
                <option value="CLOSED">CLOSED</option>
              </select>
            </div>
            <div className="flex justify-end space-x-2">
              <button
                className="bg-green-600 text-white px-4 py-2 rounded-md"
                onClick={handleStatusChange}
              >
                Submit
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={handleCloseStatusModal}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isChangePinModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4 text-center">Change Pin for {selectedUser}</h2>
            <div className="mb-4">
              <label className="block mb-2">Enter Current Pin:</label>
              <input
                type="password"
                value={currentPin}
                onChange={(e) => setCurrentPin(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Enter New Pin:</label>
              <input
                type="password"
                value={newPin}
                onChange={(e) => setNewPin(e.target.value)}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                className="bg-green-600 text-white px-4 py-2 rounded-md"
                onClick={handleCloseChangePinModal}
              >
                Submit
              </button>
              <button
                onClick={handleCloseChangePinModal}
                className="bg-red-500 text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {isResetPasswordModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4 text-center">Reset Password for {selectedUser}</h2>
            <div className="mb-4">
              <label className="block mb-2">Enter New Password:</label>
              <input type="password" className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1" />
            </div>
            <div className="mb-4">
              <label className="block mb-2">Confirm Password:</label>
              <input type="password" className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1" />
            </div>
            <div className="flex justify-end space-x-2">
              <button className="bg-green-600 text-white px-4 py-2 rounded-md" onClick={handleCloseResetPasswordModal}>Submit</button>
              <button className="bg-red-500 text-white px-4 py-2 rounded-md" onClick={handleCloseResetPasswordModal}>Cancel</button>
            </div>
          </div>
        </div>
      )}

      {/* Modal */}
      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg w-[90%] max-w-[400px]">
            <h2 className="text-xl font-semibold mb-4">Create User</h2>
            <div className="mb-4">
              <label className="block mb-2">Username:</label>
              <div className="flex">
                <input
                  type="text"
                  value={newAdmin.username}
                  onChange={(e) => setNewAdmin({ ...newAdmin, username: e.target.value })}
                  className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
                />
                <button className="border-green-600 border-4 text-white px-4 py-1 rounded-md ml-2">
                  Check
                </button>
              </div>
            </div>
            <div className="mb-4">
              <label className="block mb-2">Password:</label>
              <input
                type="password"
                value={newAdmin.password}
                onChange={(e) => setNewAdmin({ ...newAdmin, password: e.target.value })}
                className="w-full bg-black text-white border-2 border-gray-500 rounded-md px-2 py-1"
              />
            </div>
            <div className="flex justify-end space-x-2">
              <button
                onClick={handleAddAdmin}
                className="border-green-600 border-4 text-white px-4 py-2 rounded-md"
              >
                Create
              </button>
              <button
                onClick={() => setIsModalOpen(false)}
                className="border-red-600 border-4 text-white px-4 py-2 rounded-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Assign;
